<div class="padding-10" fxLayout="column" fxLayoutGap="20px">
  <h1 class="section-header">Settings</h1>
  <ng-container *ngIf="!clinicDeactivated">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutAlign.xs="center center">
      <h2 class="title-header">Change Email</h2>
      <mat-card class="full-width-form-item">
        <div fxLayout="column">
          <p class="content-text">The email address below is the one that you
            use
            to log in to your online account. This
            might be different to the
            contact details stored on {{clinicName}}'s system. If you change
            your
            email address here, it will not update
            your contact
            details that are stored with {{isGroomRoom ? 'the ' + clinicName +
              ' salon' : clinicName}} or that are visible in the
            "My details" page. After changing your email address you will be
            logged out.</p>
          <app-change-email (emailSave)="saveEmail($event)" [email]="email"
                            [loading]="emailLoading$ | async"
                            fxFlex=""></app-change-email>
        </div>
      </mat-card>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutAlign.xs="center center">
      <h2 class="title-header">Change Password</h2>
      <mat-card class="full-width-form-item">
        <app-change-password (passwordSaved)="savePassword($event)"
                             [loading]="passwordLoading$ | async"
                             class="full-width-form-item"></app-change-password>
      </mat-card>
    </div>
    <ng-container *ngIf="isVets4Pets; else standardBlock">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
        <h2 class="title-header">Marketing Preferences</h2>
        <app-combined-marketing style="width:100%"></app-combined-marketing>
      </div>
    </ng-container>
    <ng-template #standardBlock>
      <ng-container *ngIf="!isMarketingDisabled">
        <ng-container *ngIf="marketingQuestions?.length >0">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
               fxLayoutAlign.xs="center center">
            <h2 class="title-header">Marketing Preferences</h2>
            <mat-card class="full-width-form-item">
              <app-client-marketing
                (preferencesSaved)="updateMarketingPreferences($event)"
                [loading]="preferencesLoading$ | async"
                [preferences]="marketingPreferences"
                [questions]="marketingQuestions" class="full-width-form-item"
                [marketingQuestionsIntro]="marketingPreferencesIntro"></app-client-marketing>
            </mat-card>
          </div>
        </ng-container>
        <ng-container *ngIf="!isMarketingChannelsDisabled">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
               fxLayoutAlign.xs="center center">
            <h2 class="title-header">Marketing Channels</h2>
            <mat-card class="full-width-form-item">
              <div fxLayout="column" fxLayoutAlign="start start"
                   fxLayoutGap="20px">
                <app-client-marketing-channels
                  (channelsSave)="updateMarketingChannels($event)"
                  [channels]="marketingChannels"
                  [communicationChannelIntro]="marketingChannelsIntro"
                  [loading]="contactsLoading$ | async"
                  class="full-width-form-item"></app-client-marketing-channels>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isGroomRoom">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start"
         fxLayoutAlign.xs="center center">
      <h2 class="title-header">Change Salon</h2>
      <mat-card class="full-width-form-item">
        <app-client-transfer></app-client-transfer>
      </mat-card>
    </div>
  </ng-container>
</div>
