import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuickBookCardComponent} from './quick-book-card/quick-book-card.component';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {DateFnsModule} from 'ngx-date-fns';
import {BackgroundUrlPipe} from './backgroundPipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PatientSelectorComponent} from './patient-selector/patient-selector.component';
import {MatIconModule} from '@angular/material/icon';
import {ResourceSelectorComponent} from './resource-selector/resource-selector.component';
import {ClientMarketingComponent} from './client-marketing/client-marketing.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientMarketingChannelsComponent} from './client-marketing-channels/client-marketing-channels.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TermsComponent} from './terms/terms.component';
import {MatRadioModule} from '@angular/material/radio';
import {CombinedMarketingComponent} from './combined-marketing/combined-marketing.component';
import {ClientSummaryCardComponent} from './client-summary-card/client-summary-card.component';
import {RouterModule} from '@angular/router';
import {PetsCarouselComponent} from './pets-carousel/pets-carousel.component';
import {SafeUrlResourcePipe} from './safeUrlResource';
import {AddressLookupComponent} from '../address-lookup/address-lookup.component';
import {AutocompleteComponent} from '../google-address-autocomplete/google-address-autocomplete.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {ApptTypeSelectorComponent} from '../../clinic-selector/appt-type-selector/appt-type-selector.component';
import {NewCalendarComponent} from '../new-calendar/new-calendar.component';
import {GroomWelfareMessageComponent} from './groom-welfare-message/groom-welfare-message.component';
import {EmergencyDatePipePipe} from './emergencyDatePipe';


@NgModule({
  declarations: [
    QuickBookCardComponent, BackgroundUrlPipe, PatientSelectorComponent, ResourceSelectorComponent,
    ClientMarketingComponent, ClientMarketingChannelsComponent, TermsComponent, CombinedMarketingComponent,
    ClientSummaryCardComponent, SafeUrlResourcePipe, AddressLookupComponent, AutocompleteComponent,
    PetsCarouselComponent, ApptTypeSelectorComponent, GroomWelfareMessageComponent,EmergencyDatePipePipe,
    NewCalendarComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    DateFnsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    RouterModule,
    MatAutocompleteModule,
    MatInputModule
  ], exports: [
    SafeUrlResourcePipe,
    EmergencyDatePipePipe,
    QuickBookCardComponent,
    PatientSelectorComponent,
    BackgroundUrlPipe,
    ResourceSelectorComponent,
    ClientMarketingComponent,
    ClientMarketingChannelsComponent,
    CombinedMarketingComponent,
    ClientSummaryCardComponent,
    PetsCarouselComponent,
    AddressLookupComponent,
    AutocompleteComponent,
    ApptTypeSelectorComponent,
    NewCalendarComponent,
    GroomWelfareMessageComponent
  ]
})
export class CommonComponentsModule {
}
