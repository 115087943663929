<form [formGroup]="passwordForm">
  <div fxLayout="column">
    <mat-form-field appearance="outline" class="full-width-xs">
      <mat-label>Current Password</mat-label>
      <input formControlName="oldPassword" matInput
             placeholder="Current Password" required type="password">
      <mat-error *ngFor="let validation of passwordValidationMessages.password">
        <mat-error
          *ngIf="passwordForm.get('oldPassword').hasError(validation.type) && (passwordForm.get('oldPassword').dirty || passwordForm.get('oldPassword').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>{{PASSWORD}}</mat-label>
        <input [formControlName]="PASSWORD" matInput placeholder="Password"
               required type="password">
        <mat-error
          *ngFor="let validation of passwordValidationMessages.password">
          <mat-error
            *ngIf="passwordForm.get(PASSWORD).hasError(validation.type) && (passwordForm.get(PASSWORD).dirty || passwordForm.get(PASSWORD).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Confirm Password</mat-label>
        <input [errorStateMatcher]="matcher" formControlName="confirmPassword"
               matInput placeholder="Confirm Password" required type="password">
        <mat-error
          *ngIf="passwordForm.hasError('mismatch') && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)"
          class="error-message">Passwords must match
        </mat-error>
      </mat-form-field>
      <div fxLayoutAlign="center center">
        <ng-container *ngIf="loading; else buttonBlock">
          <div fxLayout="row" fxLayoutGap="20px">
            <mat-progress-spinner *ngIf="loading" color="accent" diameter="40"
                                  mode="indeterminate"></mat-progress-spinner>
            <p>Saving</p></div>
        </ng-container>
        <ng-template #buttonBlock>
          <button (click)="savePassword()" [disabled]="!passwordForm.valid"
                  color="primary" mat-raised-button>Save
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</form>
