<div fxLayout="row" style="margin-bottom:
        10px; padding:0; width:100%" xmlns="http://www.w3.org/1999/html">
  <div [ngClass.gt-sm]="'empty-timeline-gap'"></div>
  <mat-card style="padding:0; width:100%">
    <mat-card-content>
      <div fxLayout="row" fxLayout.xs="column"
           fxLayoutAlign.lt-sm="center center">
        <div class="card-header-text" fxLayout="column" fxLayout.lt-sm="row"
             fxLayoutAlign="space-between start"
             fxLayoutAlign.lt-sm="space-between center">
          <ng-container
            *ngIf="appointmentHistoryItem.appointmentItem.confirmed">
            <span
              class="date-section">{{appointmentHistoryItem.date | dfnsFormat : 'HH:mm'}}</span>
          </ng-container>
          <span [ngClass]="appointmentHistoryItem.appointmentItem.confirmed ?
         'time-section' : 'date-section'">{{appointmentHistoryItem.date |
            dfnsFormat:'EEE do'}}</span>
          <span
            class="time-section">{{appointmentHistoryItem.date | dfnsFormat: 'MMM'}}</span>
        </div>
        <div *ngIf="appointmentHistoryItem.appointmentItem.appointments"
             fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center"
             style="min-width:100px">
          <ng-container
            *ngFor="let appt of appointmentHistoryItem.appointmentItem.appointments">
            <div *ngIf="appt.patient && appt.patient.photo"
                 [ngStyle]="{'background' : appt.patient.photo?.url() | backImg}"
                 class="pet-photo-multiple  background-image" fxLayout="column"
                 style="margin:5px; align-self: center;"></div>
            <div *ngIf="appt.patient &&!appt.patient.photo"
                 class="pet-photo-multiple background-image" fxLayout="row"
                 fxLayoutAlign="center center"
                 style="margin:5px; align-self: center;">
              <mat-icon style="margin:0">
                pets
              </mat-icon>
            </div>
          </ng-container>
        </div>

        <div fxLayout="column" style="padding:10px;
        align-self: center;width:100%">
          <div fxLayout="row" fxLayoutAlign="start center">
            <p class="card-item-title">Who</p>
            <p>{{appointmentHistoryItem.patientDetails}}</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <p class="card-item-title">Where</p>
            <p>{{appointmentHistoryItem.resourceName}}</p>
          </div>
          <div *ngIf="appointmentHistoryItem.clipboardName" fxLayout="row"
               fxLayoutAlign="start center">
            <p class="card-item-title">With</p>
            <p>{{appointmentHistoryItem.clipboardName}}</p>
          </div>
          <ng-container *ngIf="appointmentHistoryItem.hasNotes">
            <div fxLayout="row" fxLayoutAlign="start center">
              <p class="card-item-title">Notes</p>
              <ng-container *ngFor="let appointment of
            appointmentHistoryItem.appointmentItem.appointments">
                <div fxLayout="column">
                  <p><span
                    *ngIf="appointmentHistoryItem.appointmentItem.multiAppt">
                       {{appointment.patientName}} - </span>
                    {{appointment.notes}}</p>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <!--              <p class="no-margin">-->
        <!--                <small>Appointment made {{appointmentHistoryItem.createdDateString}}</small>-->
        <!--              </p>-->
        <div fxLayout="column" fxLayout.xs="row wrap"
             fxLayoutAlign="center center" style="align-self: center">
          <ng-container *ngIf="loading$ | async; else actionBlock">
            <div style="width:150px;" fxLayoutAlign="center center">
              <mat-progress-spinner diameter="40" color="primary"
                                    mode="indeterminate"></mat-progress-spinner>
            </div>
          </ng-container>
          <ng-template #actionBlock>
            <ng-container *ngFor="let actionItem of actionItems">
              <button
                *ngIf="!isGroomRoom && (actionItem.name!=='Directions' || appointmentHistoryItem.mapLink)"
                color="primary"
                (click)="appointmentAction(appointmentHistoryItem, actionItem)"
                mat-stroked-button
                style="width:150px;">{{actionItem.name}}</button>
              <button
                (click)="appointmentAction(appointmentHistoryItem, actionItem)"
                *ngIf="isGroomRoom && (actionItem.name!=='Directions' || appointmentHistoryItem.mapLink)"
                color="primary" mat-raised-button
                style="width:150px; margin:5px">{{actionItem.name}}</button>
            </ng-container>
            <ng-container
              *ngIf="appointmentHistoryItem.appointmentItem.checkedIn">
              <p class="mat-subheading-1 accent-text-color">
                <b>Checked In</b></p>
            </ng-container>
            <ng-container *ngIf="!appointmentHistoryItem.appointmentItem.checkedIn &&
              appointmentHistoryItem.appointmentItem.checkInFrom &&
                        (appointmentHistoryItem.appointmentItem.checkInFrom | dfnsIsPast)">
              <button color="primary"
                      (click)="checkinAppointment(appointmentHistoryItem)"
                      mat-stroked-button style="width:150px;">Check-In
              </button>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
