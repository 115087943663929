<form [formGroup]="contactForm">
  <div fxLayout="column" fxLayoutGap="10px">
    <ng-container *ngIf="reminderConsentEnabled && reminderConsentIntro">
      <p class="content-text">{{reminderConsentIntro}}</p></ng-container>
    <ng-container *ngIf="!passedUser?.email">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
           fxLayoutGap.gt-xs="20px">
        <mat-form-field appearance="outline" class="full-width-xs"
                        fxFlex="grow">
          <mat-label>{{EMAIL}}</mat-label>
          <input [formControlName]="EMAIL" matInput required type="email">
          <mat-error
            *ngFor="let validation of contactValidationMessages.number">
            <mat-error
              *ngIf="contactForm.get(EMAIL).hasError(validation.type) && (contactForm.get(EMAIL).dirty || contactForm.get(EMAIL).touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="reminderConsentEnabled">
          <mat-checkbox [formControlName]="EMAIL_OPT_IN" class="full-width-xs">
            Receive reminders by email
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!passedUser?.mobile">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
           fxLayoutGap.gt-xs="20px">
        <mat-form-field appearance="outline" class="full-width-xs"
                        fxFlex="grow">
          <mat-label>{{MOBILE}}</mat-label>
          <input [formControlName]="MOBILE" [required]="mobileRequired" matInput
                 type="tel">
          <mat-error
            *ngFor="let validation of contactValidationMessages.number">
            <mat-error
              *ngIf="contactForm.get(MOBILE).hasError(validation.type) && (contactForm.get(MOBILE).dirty || contactForm.get(MOBILE).touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="reminderConsentEnabled">
          <mat-checkbox [formControlName]="MOBILE_OPT_IN" class="full-width-xs">
            Receive reminders by SMS
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
    <mat-form-field appearance="outline" fxFlex="grow">
      <mat-label>{{HOME}}</mat-label>
      <input [formControlName]="HOME" [required]="homeRequired" matInput
             type="tel">
      <mat-error *ngFor="let validation of contactValidationMessages.number">
        <mat-error
          *ngIf="contactForm.get(HOME).hasError(validation.type) && (contactForm.get(HOME).dirty || contactForm.get(HOME).touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
  </div>
</form>
<div fxLayout="row" fxLayoutAlign="space-between center">
  <button (click)="onCancel()" mat-stroked-button>Back</button>
  <button (click)="onSave()" color="primary" mat-raised-button>Next</button>
</div>
