import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-sex-selector',
  template: `
    <div class="card-option-container" fxLayout="row wrap" fxLayoutAlign="start center">
      <div (click)="setSelectedSex(sex);" *ngFor="let sex of sexes" [class.selected-card]="sex === selectedSex"
           class="selection-card" fxFlex="28%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <mat-icon *ngIf="sex === selectedSex" style="margin:5px">check_circle
          </mat-icon>
          <div *ngIf="sex !== selectedSex" class="empty-circle"></div>
        </div>
        <p [ngStyle]="{'color':sex === selectedSex ? '#FFFFFF' : ''}" class="card-header-text">{{sex}}</p>
      </div>
    </div>
  `,
  styleUrls: ['../../components/common-components/patient-selector/patient-selector.scss']
})
export class SexSelectorComponent implements OnInit {
  sexes: string[] = ['Male', 'Female', 'Unknown'];
  @Input() selectedSex: string;
  @Output() selectedSexChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setSelectedSex(selectedSex: string) {
    this.selectedSexChange.emit(selectedSex);
  }

}
