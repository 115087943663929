import {Component, Input, OnInit} from '@angular/core';
import {PatientCarePlan, VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-care-plans',
  template: `
    <mat-card class="detail-card" fxLayout="column" fxLayoutAlign="start start">
      <ng-container *ngIf="carePlans?.length>0 ; else empty">
        <div *ngFor="let carePlan of carePlans" fxFlexFill fxLayout="row wrap" fxLayoutAlign="start center"
             fxLayoutGap.xs="20px">
          <p class="item-title item-start">{{carePlan.status}}</p>
          <p class="title-content">{{carePlan.name}}</p></div>
      </ng-container>
      <ng-template #empty><p class="title-content">No care plans found for {{patient.name}}</p>
      </ng-template>
    </mat-card>
  `,
  styleUrls: ['../patient-detail/patient-detail.component.scss']
})
export class CarePlansComponent implements OnInit {

  @Input() carePlans: PatientCarePlan[];
  @Input() patient: VbPatient;

  constructor() {
  }

  ngOnInit(): void {
  }

}
