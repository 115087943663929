import {Component, OnInit} from '@angular/core';
import {BookingService} from '../services/booking.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.scss']
})

export class BookingHistoryComponent implements OnInit {
  isGroomRoom = environment.GROOM;
  isRequestClinic: boolean;

  constructor(public bookingService: BookingService) {
    this.isRequestClinic = bookingService.isRequestClinic;
  }

  ngOnInit() {
    this.bookingService.editingAppointment$.next(null);
  }
}
