import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from '../auth/logged-in.guard';
import {PatientsComponent} from './patients/patients.component';
import {PatientDetailComponent} from './patient-detail/patient-detail.component';
import {PatientHistoryComponent} from './patient-history/patient-history.component';
import {AddPatientPageComponent} from './add-patient-page/add-patient-page.component';
import {AddPatientGuard} from './add-patient-guard.guard';
import {EditPatientGuard} from './edit-patient-guard.guard';
import {LoggedInResolver} from '../auth/logged-in-resolver';
import {PatientBreadcrumbsResolver} from './patient-breadcrumbs-resolver';


const routes: Routes = [
  {
    path: 'pets', canActivate: [LoggedInGuard],
    data: {breadcrumbs: 'Pets'},
    resolve: {clientPatientDetails: LoggedInResolver},
    children: [
      {path: '', component: PatientsComponent},
      {
        path: 'new', component: AddPatientPageComponent, canActivate: [LoggedInGuard, AddPatientGuard],
        data: {breadcrumbs: 'Add Pet'},
        resolve: {clientPatientDetails: LoggedInResolver},
      },
      {
        path: ':patientNumber', canActivate: [LoggedInGuard],
        data: {breadcrumbs: PatientBreadcrumbsResolver},
        resolve: {clientPatientDetails: LoggedInResolver},
        children: [
          {path: '', component: PatientDetailComponent},
          {
            path: 'history', component: PatientHistoryComponent, canActivate: [LoggedInGuard],
            data: {breadcrumbs: 'History'},
            resolve: {clientPatientDetails: LoggedInResolver},
          },
          {
            path: 'edit',
            data: {breadcrumbs: 'Edit'},
            component: AddPatientPageComponent,
            canActivate: [LoggedInGuard, EditPatientGuard],
            resolve: {clientPatientDetails: LoggedInResolver},
          },
        ]
      }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientsRoutingModule {
}
