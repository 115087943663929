import {Injectable, OnDestroy} from '@angular/core';
import {SessionService} from '../services/session-service.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import * as Parse from 'parse';
import {environment} from '../../environments/environment';
import {Auth} from '@aws-amplify/auth';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {BookingService} from '../services/booking.service';
import {PetDeactivationReason} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {
  AddPatientParams, BreedItem, DeactivatePatientParams,
  EditPatientParams, GetPatientExtraDetailsParams, GetPatientHistoryParams,
  PatientExtraDetailsResponse, SpeciesItem, UpdatePatientImageParams, UpdateV4PPatientImageBioParams,
  VbExtendedPatient, VbPatient
} from '@appyvet/vetbooker-definitions/dist/patient';
import {Visit} from '@appyvet/vetbooker-definitions/dist/patient_history';

@Injectable({
  providedIn: 'root'
})
export class PatientService implements OnDestroy {

  patients$ = new BehaviorSubject<VbExtendedPatient[]>(null);
  species$ = new BehaviorSubject<SpeciesItem[]>([]);
  breeds$ = new BehaviorSubject<BreedItem[]>([]);
  colors$ = new BehaviorSubject<string[]>([]);
  alphanumericRegexp: string;
  species: SpeciesItem[];
  canEditPatient$ = new BehaviorSubject<boolean>(null);
  private patients: VbExtendedPatient[];
  isGroomRoom: boolean;
  canAddPatient$ = new BehaviorSubject<boolean>(null);
  canViewHistory: boolean;
  vaccineHistoryEnabled: boolean;
  showLastInsured: boolean;
  showLastVac: boolean;
  showMicrochip: boolean;
  showColors: boolean;
  showReminders: boolean;
  canDeactivatePatient: boolean;
  hideReminders: boolean;
  petDeactivationReasons: PetDeactivationReason[];
  private sessionSub: Subscription;
  private authSub: Subscription;
  deactivatedPatientsEvent$: Subject<boolean>;
  clinicName: string;
  private extraInfo$ = new BehaviorSubject<PatientExtraDetailsResponse[]>(null);

  constructor(private sessionService: SessionService, private authService: AuthService, private router: Router,
              private bookingService: BookingService) {
    this.authSub = this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.resetService();
      } else {
        this.loadSessionData();
      }
    });
  }

  loadSessionData() {
    this.getPatientExtraDetails();
    this.sessionSub = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clinicName = clientPatientDetails.themeSettings?.customName;
        this.alphanumericRegexp = clientPatientDetails.country.alphanumericRegex;
        this.canEditPatient$.next(clientPatientDetails.editSettings.editPatient);
        this.canAddPatient$.next(clientPatientDetails.editSettings.addPatient);
        this.canViewHistory = clientPatientDetails.historyEnabled;
        this.vaccineHistoryEnabled = clientPatientDetails.vaccinationHistoryEnabled;
        this.canDeactivatePatient = clientPatientDetails.canDeactivatePatient;
        this.showLastInsured = clientPatientDetails.editSettings.showLastInsured;
        this.showLastVac = clientPatientDetails.editSettings.showLastVac;
        this.patients = clientPatientDetails.clientData.patients;
        this.isGroomRoom = clientPatientDetails.isGroomRoom;
        this.species$.next(clientPatientDetails.speciesList);
        this.breeds$.next(clientPatientDetails.breeds);
        this.colors$.next(clientPatientDetails.colors);
        this.petDeactivationReasons = clientPatientDetails.petDeactivationReasons;
        this.showColors = clientPatientDetails.editSettings.showColors;
        this.showMicrochip = clientPatientDetails.editSettings.showMicrochip;
        this.hideReminders = clientPatientDetails.patientViewSettings.hideReminders;
        this.extraInfo$.subscribe(extraInfo => {
          if (extraInfo) {
            const updatedPatients = this.patients.map(patient => {
              patient.extraInfo = extraInfo.find(
                extraInfoItem => extraInfoItem.patientNumber === patient.patientNumber);
              return patient;
            });
            this.patients$.next(updatedPatients);
          }
        });
      }
    });
  }

  async addPatient(patient: AddPatientParams) {
    try {
      const savedPatient: VbExtendedPatient = await Parse.Cloud.run('addPatient', patient,
        {sessionToken: this.authService.getSessionToken()});
      this.patients.push(savedPatient);
      this.patients$.next(this.patients);
      return true;
    } catch (e) {
      throw e;
    }
  }

  async editPatient(patient: EditPatientParams) {
    try {
      const savedPatient: VbPatient = await Parse.Cloud.run('savePatientDetails', patient,
        {sessionToken: this.authService.getSessionToken()});
      this.patients.forEach((existingPatient, i) => {
        if (existingPatient.patientNumber === savedPatient.patientNumber) {
          this.patients[i] = Object.assign({extraInfo: patient.previousData.extraInfo}, savedPatient);
        }
      });
      this.patients$.next(this.patients);
      return true;
    } catch (e) {
      throw e;
    }
  }

  findPatient(patientNumber: string): VbExtendedPatient {
    return this.patients.find(patient => patient.patientNumber === patientNumber);
  }

  async uploadV4PPhoto(image: string, patientId: string): Promise<string> {
    const token = await this.authService.getCognitoToken();
    // Library adds an image prefix, but V4P middleware wants just the base 64 string, so remove that unless an empty
    // image (i.e. a delete)
    let photo;
    if (image) {
      photo = image.split(',')[1];
    } else {
      photo = '';
    }
    try {
      const params: UpdateV4PPatientImageBioParams = {photo, patientNumber: patientId, token};
      const result = await Parse.Cloud.run('updateV4PPatientPhotoBio', params,
        {sessionToken: this.authService.getSessionToken()});
      if (result) {
        return photo;
      }
    } catch (e) {
      throw e;
    }
  }

  async updateBio(patientId: string, bio: string): Promise<string> {
    const token = await this.authService.getCognitoToken();
    try {
      const params: UpdateV4PPatientImageBioParams = {bio, patientNumber: patientId, token};
      const result = await Parse.Cloud.run('updateV4PPatientPhotoBio', params,
        {sessionToken: this.authService.getSessionToken()});
      if (result) {
        return bio;
      }
    } catch (e) {
      throw e;
    }
  }

  uploadPhoto$(image: string, patientId: string): Observable<Parse.File> {
    const uploadPhotoRequest$ = new Subject<Parse.File>();
    const name = patientId + '-photo.jpg';
    const parseFile = new Parse.File(name, {base64: image});
    const uploadSettings: UpdatePatientImageParams = {
      patientNumber: patientId,
      photo: parseFile
    };
    parseFile.save().then(() => {
      Parse.Cloud.run('updatePatientImage', uploadSettings, {sessionToken: this.authService.getSessionToken()})
        .then(() => {
          uploadPhotoRequest$.next(parseFile);
        }, error => {
          console.log(error);
          uploadPhotoRequest$.error(error);
        });
    });
    return uploadPhotoRequest$;
  }

  async deletePhoto(patientId: string) {
    if (!environment.VETS_4_PETS) {
      const uploadSettings: UpdatePatientImageParams = {
        patientNumber: patientId,
        photo: null
      };
      try {
        await Parse.Cloud.run('updatePatientImage', uploadSettings, {sessionToken: this.authService.getSessionToken()});
        return true;
      } catch (error) {
        throw error;
      }
    } else {
      // 20/9/20 - V4P currently doesn't allow removing photo. Don't remove server side, but allow replacement
      return true;
      // return this.uploadV4PPhoto(null, patientId);
    }
  }

  resetService() {
    this.patients$.next(null);
  }

  async getPatientExtraDetails(patientNumber?: string) {
    const patientParams: GetPatientExtraDetailsParams = {};
    if (patientNumber) {
      patientParams.patientNumber = patientNumber;
    }
    if (environment.VETS_4_PETS) {
      const credentials = await Auth.currentSession();
      patientParams.token = credentials.getIdToken().getJwtToken();
    }
    try {
      const extraInfo: PatientExtraDetailsResponse[] = await Parse.Cloud.run('getAllPatientsExtraDetails',
        patientParams,
        {sessionToken: this.authService.getSessionToken()});
      this.extraInfo$.next(extraInfo);
    } catch (e) {
      throw e;
    }
  }

  getPatient(patientNumber: string) {
    return this.patients.find(patient => patient.patientNumber === patientNumber);
  }

  resetPatients() {
    this.patients.forEach(patient => {
      patient.isSelected = false;
    });
    this.patients$.next(this.patients);
  }

  getHistory$(params: GetPatientHistoryParams): Observable<Visit[]> {
    const getHistoryRequest = new Subject<Visit[]>();
    Parse.Cloud.run('getPatientItemsHistory', params, {sessionToken: this.authService.getSessionToken()})
      .then(results => {
        getHistoryRequest.next(results);
      }, e => getHistoryRequest.error(e));
    return getHistoryRequest;
  }

  async deactivatePet(patient: VbExtendedPatient, reason: PetDeactivationReason) {
    this.deactivatedPatientsEvent$ = new Subject<boolean>();
    const params: DeactivatePatientParams = {patient, reason: reason.id, reasonText: reason.description};
    Parse.Cloud.run('deactivatePatient', params, {sessionToken: this.authService.getSessionToken()}).then(result => {
      if (result) {
        this.deactivatedPatientsEvent$.next(true);
        const updatedPatients = this.patients.filter(
          patientToCheck => patient.patientNumber !== patientToCheck.patientNumber);
        this.bookingService.deleteAppointmentsLocallyById(result);
        this.patients$.next(updatedPatients);
        this.router.navigate(['/pets']);
        return true;
      }
    }, error => {
      this.deactivatedPatientsEvent$.error(error);
    });
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.sessionSub?.unsubscribe();
  }
}
