import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as Sentry from "@sentry/angular";

import {AppService} from './app.service';
import {AppComponent} from './app.component';
import {LoggedInGuard} from './auth/logged-in.guard';
import {GuestGuard} from './auth/guest.guard';
import {LoginComponent} from './login/login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDividerModule} from '@angular/material/divider';
import {LandingComponent} from './landing/landing.component';
import {BookerComponent} from './booker/booker.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {HistorySummaryCardComponent} from './landing/history-summary-card/history-summary-card.component';
import {MatCardModule} from '@angular/material/card';
import {NextAvailableCardComponent} from './landing/next-available-card/next-available-card.component';
import {BookingHistoryComponent} from './booking-history/booking-history.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {AlertDialogComponent} from './components/shared/alert-dialog/alert-dialog.component';
import {Angulartics2Module} from 'angulartics2';
import {CalendarComponent} from './components/calendar/calendar.component';
import {HttpClientModule} from '@angular/common/http';
import {ConfirmBookingDialogComponent} from './booker/confirm-booking-dialog/confirm-booking-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {GroomBookingTermsDialogComponent} from './booker/groom-booking-terms-dialog/groom-booking-terms-dialog.component';
import {GroomBookingTypeGuideComponent} from './booker/groom-booking-type-guide/groom-booking-type-guide.component';
import {SessionService} from './services/session-service.service';
import {MatGridListModule} from '@angular/material/grid-list';
import {MaterialCssVarsModule} from 'angular-material-css-vars';
import {ConfirmationDialogComponent} from './components/shared/confirmation-dialog/confirmation-dialog.component';
import {CancelBookingDialogComponent} from './booker/cancel-booking-dialog/cancel-booking-dialog.component';
import {NoMatchDialogComponent} from './login/no-match-dialog/no-match-dialog.component';
import {LoginModule} from './login/login.module';
import {PatientReminderSlotsComponent} from './landing/patient-reminder-slots/patient-reminder-slots.component';
import {DateFnsModule} from 'ngx-date-fns';
import {ClientDetailsModule} from './client-details/client-details.module';
import {ErrorDialogComponent} from './components/shared/error-dialog/error-dialog.component';
import {PatientsModule} from './patients/patients.module';
import {QuickBookCardComponent} from './components/common-components/quick-book-card/quick-book-card.component';
import {CommonComponentsModule} from './components/common-components/common-components.module';
import {ProductOrderModule} from './product-order/product-order.module';
import {DropTargetOptions, MineTypeEnum, NgxUploadModule} from '@wkoza/ngx-upload';
import {ErrorComponent} from './error/error.component';
import {RegistrationModule} from './registration/registration.module';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {SettingsModule} from './settings/settings.module';
import {ClinicSelectorModule} from './clinic-selector/clinic-selector.module';
import {ImageEditingDialogComponent} from './components/shared/image-editing-dialog/image-editing-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {OldEmailLinkComponent} from './old-email-link/old-email-link.component';
import {CookieService} from 'ngx-cookie-service';
import {AppStoreDialogComponent} from './components/shared/app-store-dialog/app-store-dialog.component';
import {FaqComponent} from './faq/faq.component';
import {LoggedInResolver} from './auth/logged-in-resolver';
import {TermsDialogComponent} from './components/shared/terms-dialog/terms-dialog.component';
import {BnNgIdleService} from 'bn-ng-idle';
import {ClinicCodeGuard} from './auth/clinic-code.guard';
import {CustomDateAdapter} from './components/CustomDateAdapter';
import {EmergencyAppointmentDialogComponent} from './components/shared/emergency-appointment-dialog/emergency-appointment-dialog.component';
import {AppointmentCardComponent} from './components/appointment-card/appointment-card.component';
import {LogoutComponent} from './logout/logout.component';
import {ClosedComponent} from './closed/closed.component';
import {ClosedDialogComponent} from './components/shared/closed-dialog/closed-dialog.component';
import {BreadcrumbsModule} from '@exalif/ngx-breadcrumbs';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import { WelfareGuidanceComponent } from './booker/welfare-guidance/welfare-guidance.component';
import {Router} from "@angular/router";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function app_init(appService: AppService) {
  return () => appService.initializeApp();
}

export const ngxDropTargetOptions: DropTargetOptions = {
  color: 'dropZoneColor',
  colorDrag: 'dropZoneColorDrag',
  colorDrop: 'dropZoneColorDrop',
  multiple: false,
  accept: [MineTypeEnum.Image_Jpeg, MineTypeEnum.Image_Png]
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    LandingComponent,
    BookerComponent,
    PageNotFoundComponent,
    HistorySummaryCardComponent,
    NextAvailableCardComponent,
    BookingHistoryComponent,
    AlertDialogComponent,
    AppStoreDialogComponent,
    ErrorDialogComponent,
    CalendarComponent,
    ConfirmBookingDialogComponent,
    GroomBookingTermsDialogComponent,
    GroomBookingTypeGuideComponent,
    CancelBookingDialogComponent,
    NoMatchDialogComponent,
    PatientReminderSlotsComponent,
    ErrorComponent,
    ImageEditingDialogComponent,
    OldEmailLinkComponent,
    FaqComponent,
    TermsDialogComponent,
    EmergencyAppointmentDialogComponent,
    AppointmentCardComponent,
    LogoutComponent,
    ClosedComponent,
    ClosedDialogComponent,
    BreadcrumbsComponent,
    WelfareGuidanceComponent,
  ],
  imports: [
    RegistrationModule,
    CommonComponentsModule,
    ProductOrderModule,
    ClientDetailsModule,
    LoginModule,
    PatientsModule,
    SettingsModule,
    ClinicSelectorModule,
    DateFnsModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatToolbarModule,
    Angulartics2Module.forRoot(),
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    MaterialCssVarsModule.forRoot(),
    MatDividerModule,
    MatNativeDateModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUploadModule.forRoot(ngxDropTargetOptions),
    ImageCropperModule,
    BreadcrumbsModule.forRoot()
  ],
  entryComponents: [
    AlertDialogComponent,
    AppStoreDialogComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    BnNgIdleService, CookieService, GuestGuard, ClinicCodeGuard, LoggedInGuard, AppService, SessionService,
    LoggedInResolver, DeviceDetectorService,
    {
      provide: APP_INITIALIZER, useFactory: app_init, deps: [AppService, Sentry.TraceService], multi: true
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true}
    }
  ],
  exports: [
    QuickBookCardComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
