import {Component, OnInit} from '@angular/core';
import {Breadcrumb, BreadcrumbsService} from '@exalif/ngx-breadcrumbs';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public crumbs$: Observable<Breadcrumb[]> = this.breadcrumbsService.getCrumbs();

  constructor(public breadcrumbsService: BreadcrumbsService) {
  }

  ngOnInit(): void {
  }

}
