<div fxLayout="row" fxLayoutAlign="start center" style="min-width:450px">
  <ng-container *ngIf="crumbs$ | async as breadcrumbs">
    <div *ngFor="let crumb of breadcrumbs; let last = last" class="breadcrumb">
      <div *ngIf="!last">
        <a [routerLink]="[crumb.path]" class="breadcrumb_button" href=""
           mat-button>{{ crumb.text }}</a>
        <mat-icon style="vertical-align: middle;" class="accent-text-color">
          chevron_right
        </mat-icon>
      </div>
      <a *ngIf="last" class="breadcrumb_button" mat-button>{{ crumb.text }}</a>
    </div>
  </ng-container>
</div>
