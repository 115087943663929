<div class="login-background" fxFlexFill fxLayout="column"
     fxLayoutAlign="center center">
  <mat-card class="centered-card">
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <!--        <h2>Temporary Closure</h2>-->
        <!--        <p class="content-text">This site has been temporarily closed, with-->
        <!--          online account access temporarily disabled.</p>-->
        <ng-container *ngIf="closedMessage">
          <div [innerHTML]="closedMessage"></div>
          <!--          <p class="content-text">{{closedMessage}}</p>-->
        </ng-container>
        <ng-container *ngIf="isLoggedIn; else returnToSelectorBlock">
          <p>If you want to log out, please use the icon on the toolbar or
            the button below</p>
          <button (click)="logOut()" color="primary" mat-raised-button>Log out
          </button>
        </ng-container>
        <ng-template #returnToSelectorBlock>
          <ng-container *ngIf="showSelectorButton">
            <button (click)="returnToSelector()" color="primary"
                    mat-raised-button>Select
              new {{individualTypeName}}
            </button>
          </ng-container>
        </ng-template>
      </div>
    </mat-card-content>
    <p></p>
  </mat-card>
</div>
