<div class="padding-10" fxLayout="column">
  <h1 class="section-header">Product Request</h1>
  <mat-card>
    <ng-container *ngIf="!(success$ | async); else successBlock">
      <form [formGroup]="productForm" class="content-text">
        <p class="content-text padding-10">This is to confirm that you want to
          request collection of <span
            *ngIf="passedMedication; else productBlock">{{passedMedication.itemName}}</span>
          <ng-template #productBlock>a product</ng-template>
          <span *ngIf="passedPatient"> for {{passedPatient.name}}</span>
        </p>
        <div *ngIf="prescriptionCollectionText">
          <p class="content-text padding-10"><b class="accent-text-color"
                                                style="margin-right: 5px">Please
            note:</b>{{prescriptionCollectionText}}</p>
        </div>
        <h2 class="title-header">Product</h2>
        <div *ngIf="!passedMedication; else productNameBlock">
          <p class="content-text padding-10">Please be as detailed as
            possible</p>
          <mat-form-field appearance="outline" class="product-form-item">
            <mat-label>Name of Product</mat-label>
            <input formControlName="itemName" matInput maxlength="100" required>
            <mat-error *ngFor="let validation of
                orderValidationMessages.name">
              <mat-error
                *ngIf="productForm.get('itemName').hasError(validation.type) && (productForm.get('itemName').dirty || productForm.get('itemName').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <ng-template #productNameBlock><p
          class="content-text padding-10">{{passedMedication.itemName}}</p>
        </ng-template>
        <ng-container *ngIf="!passedMedication">
          <app-product-type-selector [(selectedType)]="selectedType"
                                     [types]="productTypes"></app-product-type-selector>
        </ng-container>
        <ng-container *ngIf="selectedType || passedMedication">
          <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field appearance="outline" class="product-form-item">
              <mat-label>Number of items</mat-label>
              <input formControlName="number" matInput required type="number">
              <mat-error *ngFor="let validation of
                orderValidationMessages.number">
              <mat-error
                *ngIf="productForm.get('number').hasError(validation.type) && (productForm.get('number').dirty || productForm.get('number').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
            </mat-form-field>
            <ng-container *ngIf="selectedType">
              <mat-form-field appearance="outline" class="product-form-item"
                              style="margin: 0 10px">
                <mat-label>{{selectedType.label}}</mat-label>
                <input formControlName="strength" matInput maxlength="100"
                       type="text">
              </mat-form-field>
            </ng-container>
          </div>
        </ng-container>
        <h2 class="title-header">Pet</h2>
        <ng-container *ngIf="!passedPatient; else selectedPatientBlock">
          <app-patient-selector (patientSelected)="selectPatient($event)"
                                [patients]="patients"></app-patient-selector>
        </ng-container>
        <ng-template #selectedPatientBlock>
          <p class="content-text padding-10">{{passedPatient.name}}</p>
        </ng-template>
        <ng-container *ngIf="resources.length>1">
          <h2 class="title-header">Where</h2>
          <app-resource-selector [(selectedResource)]="selectedResource"
                                 [resources]="resources"></app-resource-selector>
        </ng-container>
        <mat-form-field appearance="outline" class="product-form-item">
          <mat-label>Notes</mat-label>
          <textarea formControlName="notes" matInput></textarea>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="end center"
             fxLayoutAlign.xs="center center">
          <ng-container *ngIf="!(loading$ | async); else loadingBlock">
            <button (click)="order()" color="primary" mat-raised-button
                    [disabled]="!productForm.valid">
              Order
            </button>
          </ng-container>
          <ng-template #loadingBlock>
            <p class="mat-body-2">Ordering Product</p>
            <mat-progress-spinner [diameter]="35" color="primary"
                                  mode="indeterminate"></mat-progress-spinner>
          </ng-template>
        </div>
      </form>
    </ng-container>
    <ng-template #successBlock>
      <p class="content-text">Product successfully ordered. The clinic will be
        in touch as per their usual policies.</p>
      <div class="full-width-form-item" fxLayout="row"
           fxLayoutAlign="center center">
        <button (click)="resetOrder()" mat-stroked-button>Order another
          product
        </button>
      </div>
    </ng-template>
  </mat-card>
</div>
