import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  isGroomRoom: boolean = environment.GROOM;
  errorCode: string;
  error: string;
  hideLogout: boolean;

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.errorCode = params.errorCode;
      this.error = params.error;
      if (this.errorCode === '411') {
        this.hideLogout = true;
      }
    });
  }

  logOut() {
    this.authService.logout(true);
  }
}
