import {Injectable} from '@angular/core';
import {SessionService} from '../services/session-service.service';
import {BehaviorSubject, Subject} from 'rxjs';
import * as Parse from 'parse';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {Auth} from '@aws-amplify/auth';
import {
  MarketingContact,
  MarketingQuestion, MarketingResponse,
  UpdateMarketingParams
} from '@appyvet/vetbooker-definitions/dist/marketing';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  marketingContacts = new BehaviorSubject<MarketingContact[]>([]);
  marketingPreferences = new BehaviorSubject<MarketingResponse[]>([]);
  marketingQuestions = new BehaviorSubject<MarketingQuestion[]>([]);
  error$ = new BehaviorSubject<string>(null);
  updateEmail$ = new BehaviorSubject<boolean>(false);
  updatePassword$ = new Subject<boolean>();
  marketingChannelsIntro: string;
  clinicName: string;
  email: string;
  settingsService: string;
  marketingPreferencesIntro: string;
  isMarketingChannelsDisabled: boolean;
  isMarketingDisabled: boolean;
  isGroomRoom: boolean;
  clinicDeactivated: boolean;

  constructor(private sessionService: SessionService, private authService: AuthService) {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if(clientPatientDetails) {
        this.clinicDeactivated = clientPatientDetails.clinicDeactivated;
        this.marketingContacts.next(clientPatientDetails.clientData.marketing?.contacts);
        this.marketingPreferences.next(clientPatientDetails.clientData.marketing?.preferences);
        this.marketingQuestions.next(clientPatientDetails.clientData.marketing?.questions);
        this.isGroomRoom = clientPatientDetails.isGroomRoom;
        if (clientPatientDetails.marketingSettings) {
          this.isMarketingDisabled = clientPatientDetails.marketingSettings.disableMarketing;
          this.isMarketingChannelsDisabled = clientPatientDetails.marketingSettings.disableMarketingContacts;
          this.marketingPreferencesIntro = clientPatientDetails.marketingSettings.marketingPreferencesIntro;
          this.marketingChannelsIntro = clientPatientDetails.marketingSettings.communicationChannelIntro;
        } else {
          this.isMarketingDisabled = true;
        }
        this.clinicName = clientPatientDetails.themeSettings.customName || 'your clinic';
      }
    });
    this.getUser();
  }

  async getUser() {
    const user = Parse.User.current();
    if (user) {
      this.email = user.getEmail();
    }
  }

  updateClientMarketingInfo(questions: MarketingQuestion[]) {
    const updateMarketingQuestions$ = new BehaviorSubject<boolean>(false);
    Parse.Cloud.run('updateClientMarketingInfo', {preferences: questions},
      {sessionToken: this.authService.getSessionToken()}).then(() => {
      updateMarketingQuestions$.next(true);
    }, ((error) => {
      updateMarketingQuestions$.error(error.message);
    }));
    return updateMarketingQuestions$;
  }

  updateClientMarketingChannels(contacts: MarketingContact[]) {
    const updateMarketingQuestions$ = new BehaviorSubject<boolean>(false);
    Parse.Cloud.run('updateClientMarketingInfo', {contacts},
      {sessionToken: this.authService.getSessionToken()}).then(() => {
      updateMarketingQuestions$.next(true);
    }, ((error) => {
      updateMarketingQuestions$.error(error.message);
    }));
    return updateMarketingQuestions$;
  }

  async updateEmail(newEmail: string) {
    this.updateEmail$.next(true);
    if (environment.VETS_4_PETS) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          email: newEmail,
        });
        this.updateEmail$.next(false);
      } catch (e) {
        this.updateEmail$.error(e);
        console.log('error updating email ', e);
      }
    } else {
      Parse.Cloud.run('changeEmail', {
        email: newEmail
      }, {sessionToken: this.authService.getSessionToken()}).then(() => {
        this.updateEmail$.next(true);
        this.email = newEmail;
      }, error => {
        this.updateEmail$.error(error.message);
      });
    }
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    this.updatePassword$ = new Subject<boolean>();
    if (environment.VETS_4_PETS) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        this.updatePassword$.next(true);
      } catch (e) {
        this.updatePassword$.error(e.message);
      }
    } else {
      Parse.Cloud.run('updatePassword', {oldPassword, newPassword}, {sessionToken: this.authService.getSessionToken()})
        .then(() => {
          this.updatePassword$.next(true);
          this.authService.logout(true);
        }, error => {
          this.updatePassword$.error(error.message);
        });
    }
  }

  async updateCombinedMarketingDetails(contacts: MarketingContact[],
                                       preferences: MarketingResponse[]) {
    const marketingUpdateParams: UpdateMarketingParams = {contacts, preferences};
    try {
      await Parse.Cloud.run('updateClientMarketingInfo', marketingUpdateParams,
        {sessionToken: this.authService.getSessionToken()});
      this.authService.isNewRegistration$.next(false);
      this.sessionService.updateClientMarketingDetails(contacts, preferences);
    } catch (e) {
      throw e;
    }
  }
}
