import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {EXIT_ACTION, LOGIN_ACTION, TRANSFER_ACTION} from '../../../constants';

@Component({
  selector: 'app-clinic-code-mismatch-dialog',
  templateUrl: './clinic-code-mismatch-dialog.component.html',
  styleUrls: ['./clinic-code-mismatch-dialog.component.scss']
})
export class ClinicCodeMismatchDialogComponent implements OnInit {
  isVets4Pets = environment.VETS_4_PETS;
  EXIT = EXIT_ACTION;
  LOGIN = LOGIN_ACTION;
  TRANSFER = TRANSFER_ACTION;
  typeName = environment.GROOM ? 'Salon' : 'Clinic';

  constructor(public dialogRef: MatDialogRef<ClinicCodeMismatchDialogComponent>) {
  }

  ngOnInit(): void {
  }

}
