import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {SessionService} from '../services/session-service.service';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {BookingService} from '../services/booking.service';
import {VetBookerError} from '../interfaces/vberror';
import {ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';

@Injectable({
  providedIn: 'root'
})
export class LoggedInResolver implements Resolve<ClientPatientDetails> {

  constructor(private sessionService: SessionService, private authService: AuthService, private router: Router,
              private bookingService: BookingService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<ClientPatientDetails> {
    return this.sessionService.clientPatientDetails$
      .pipe(catchError((error: VetBookerError) => {
        if (error.code === 499) {
          this.authService.closedMessage$.next(error.message);
          this.router.navigateByUrl('closed');
        } else if (error.code === 209) {
          this.authService.logout(true);
        }
        throw EMPTY;
      }), map(result => {
          if (!result) {
            this.sessionService.getClientPatientDetails();
          } else {
            if (environment.VETS_4_PETS) {
              if (!result.termsAndConditions || !result.termsAndConditions.latest) {
                if (state.url.indexOf('terms') === -1) {
                  this.router.navigate(['/terms']);
                }
              } else if (state.url.indexOf('terms') !== -1) {
                // If terms agreed, then navigate away
                this.router.navigate(['/landing']);
              }
            }
            if (environment.GROOM) {
              // If the salon is deactivated, only allow salon transfer portal through settings
              if (result.clinicDeactivated && state.url.indexOf('settings') === -1) {
                this.router.navigate(['/settings'], {queryParams: {deactivated: true}});
              }
            }
          }
          return result;
        }
      ), first(result => result !== null));
  }
}
