import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit, OnDestroy {
  closedMessage: string;
  private closedSub: Subscription;
  isLoggedIn: boolean;
  private loggedInSub: Subscription;
  showSelectorButton = environment.GROOM || environment.VETS_4_PETS;
  individualTypeName = environment.GROOM ? 'salon' : 'clinic';

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.closedSub = this.authService.closedMessage$.subscribe(message => {
      this.closedMessage = message;
    });
    this.loggedInSub = this.authService.isLoggedIn$.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
    if (this.authService.getClinicCode()) {
      this.authService.resetClinicCode(false);
    } else {
      this.router.navigateByUrl('selector');
    }
  }

  logOut() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.closedSub?.unsubscribe();
    this.loggedInSub?.unsubscribe();
  }

  returnToSelector() {
    this.authService.resetClinicCode(true);
  }
}
