import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-patient-selector',
  template: `
    <div class="card-option-container" fxFill fxLayout="row wrap"
         fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start center">
      <ng-container *ngFor="let patient of patients">
        <div (click)="selectPatient(patient);"
             [class.selected-card]="patient.isSelected" class="selection-card" fxFlex="45%" fxFlex.xs="100%"
             fxLayout="row"
             fxLayoutAlign="start center">
          <div style="min-width: 30px; padding:0 10px">
            <mat-icon *ngIf="patient.isSelected" style="margin:5px">check_circle
            </mat-icon>
            <div *ngIf="!patient.isSelected" class="empty-circle"></div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%">
            <div><p [ngStyle]="{'color':patient.isSelected ? '#FFFFFF' : ''}"
                    class="card-header-text">{{patient.name}}</p>
            </div>
            <ng-container *ngIf="patient.photo">
              <div [ngStyle]="getPhoto(patient)"
                   class="pet-photo background-image" style="margin:10px 20px 10px 0"></div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./patient-selector.scss']
})
export class PatientSelectorComponent implements OnInit {
  @Input() patients: VbExtendedPatient[];
  @Output() patientSelected = new EventEmitter<VbExtendedPatient>();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectPatient(patient: VbExtendedPatient) {
    this.patientSelected.emit(patient);
  }

  getPhoto(patient) {
    if (patient.photo) {
      return {'background': 'url(' + patient.photo.url() + ')'};
    }
  }
}
