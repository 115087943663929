<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h2 class="big-header">Terms and Conditions</h2>

  <p class="content-text">You must agree to the following terms and conditions
    in order to proceed into the site:</p>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-checkbox [(ngModel)]="termsAgreed"></mat-checkbox>
    <p class="no-margin">I agree to the <span class="terms-link"
                                              (click)="showTerms()">terms and
            conditions</span></p></div>
  <button mat-raised-button color="primary" (click)="agreeToTerms()"
          [disabled]="!termsAgreed">Save
  </button>
</div>
