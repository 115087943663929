<mat-card class="detail-card">
  <ng-container *ngIf="parasiteHistory?.items &&
  parasiteHistory.items.length>0; else emptyBlock">
    <ng-container *ngFor="let tx of parasiteHistory.items; last as last;
    first as first">
      <div fxHide.gt-xs *ngIf="!first" style="height:10px"></div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
           fxLayoutAlign.xs="space-between center">
        <p class="item-title item-start">{{tx.itemDate}}</p>
        <p class="content-text">{{tx.itemName}}</p>
      </div>
      <mat-divider fxHide.gt-xs *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-container>
  <ng-template #emptyBlock>
    <p class="content-text">No previous flea or worming treatment on file</p>
  </ng-template>
</mat-card>
