import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PassedUser} from '@appyvet/vetbooker-definitions/dist/register_user';

export interface ContactFormItem {
  form: FormGroup;
  firstLoad?: boolean;
}

export const MOBILE = 'Mobile Phone Number';
export const HOME = 'Home Phone Number';
export const EMAIL = 'Email Address';
export const EMAIL_OPT_IN = 'Email Reminder Consent';
export const MOBILE_OPT_IN = 'SMS Reminder Consent';

@Component({
  selector: 'app-registration-contact-entries',
  templateUrl: './registration-contact-entries.component.html',
  styleUrls: ['./registration-contact-entries.component.scss']
})
export class RegistrationContactEntriesComponent implements OnInit {
  contactForm: FormGroup;
  @Input() passedUser: PassedUser;
  @Input() homeRegex: string;
  @Input() mobileRegex: string;
  @Input() mobileRequired: boolean;
  @Input() homeRequired: boolean;
  @Output() save = new EventEmitter<ContactFormItem>();
  @Output() cancel = new EventEmitter<boolean>();
  contactValidationMessages = {
    number: [
      {type: 'required', message: 'You must enter contact information'},
      {type: 'pattern', message: 'Oops, this doesn\'t look like a valid number'},
      {type: 'minLength', message: 'Oops, your email must be more than 10 characters'},
      {type: 'maxLength', message: 'Oops, your email must be less than 100  characters'},
      {type: 'email', message: 'Oops, this doesn\'t look like a valid email address'},
    ],
  };
  @Input() reminderConsentIntro: string;
  @Input() reminderConsentEnabled: boolean;
  EMAIL = EMAIL;
  HOME = HOME;
  MOBILE = MOBILE;
  EMAIL_OPT_IN = EMAIL_OPT_IN;
  MOBILE_OPT_IN = MOBILE_OPT_IN;

  constructor() {
  }

  ngOnInit(): void {
    this.contactForm = new FormGroup(
      {
        'Email Address': new FormControl(this.passedUser?.email || this.passedUser?.username || '',
          [Validators.required, Validators.email, Validators.minLength(10), Validators.maxLength(100)]),
        'Mobile Phone Number': new FormControl(this.passedUser?.mobile || '',
          [Validators.required, Validators.pattern(this.mobileRegex)]),
        'Home Phone Number': new FormControl('', [Validators.pattern(this.homeRegex)]),
        'Email Reminder Consent': new FormControl(false,),
        'SMS Reminder Consent': new FormControl(false,),
      }
    );
    if (this.mobileRequired) {
      this.contactForm.get(MOBILE).setValidators([Validators.required, Validators.pattern(this.mobileRegex)]);
    }
    if (this.homeRequired) {
      this.contactForm.get(HOME).setValidators([Validators.required, Validators.pattern(this.homeRegex)]);
    }
    this.save.emit({form: this.contactForm, firstLoad: true});
  }

  onSave() {
    this.save.emit({form: this.contactForm});
  }

  onCancel() {
    this.cancel.emit(true);
  }
}
