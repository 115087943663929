<div fxLayout="column">
  <form [formGroup]="additionalInfoForm">
    <div fxLayout="column">
      <ng-container *ngIf="clinics?.length>1">
        <mat-form-field appearance="outline">
          <mat-label>{{CLINIC}}</mat-label>
          <mat-select [formControlName]="CLINIC" required>
            <mat-option *ngFor="let clinic of clinics"
                        [value]="clinic">{{clinic.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!passedPassword">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>{{PASSWORD}}</mat-label>
            <input [formControlName]="PASSWORD" matInput placeholder="Password"
                   required type="password">
            <mat-error
              *ngFor="let validation of additionalValidationMessages.password">
              <mat-error
                *ngIf="additionalInfoForm.get(PASSWORD).hasError(validation.type) && (additionalInfoForm.get(PASSWORD).dirty || additionalInfoForm.get(PASSWORD).touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Confirm Password</mat-label>
            <input [errorStateMatcher]="matcher"
                   formControlName="confirmPassword" matInput
                   placeholder="Confirm Password" required type="password">
            <mat-error
              *ngIf="additionalInfoForm.hasError('mismatch') && (additionalInfoForm.get('confirmPassword').dirty || additionalInfoForm.get('confirmPassword').touched)"
              class="error-message">Passwords must match
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="showNotes">
        <p class="content-text">Please include any relevant notes below. This
          may include details of your previous vet
          if available</p>
        <mat-form-field>
          <mat-label>{{NOTES}}</mat-label>
          <textarea #notes [formControlName]="NOTES" cdkAutosizeMaxRows="10"
                    cdkAutosizeMinRows="2" cdkTextareaAutosize matInput
                    maxLength="1500"></textarea>
          <mat-hint align="end">{{notes.value?.length || 0}}/1500</mat-hint>
          <mat-error
            *ngFor="let validation of additionalValidationMessages.notes">
            <mat-error
              *ngIf="additionalInfoForm.get(NOTES).hasError(validation.type) && (additionalInfoForm.get(NOTES).dirty || additionalInfoForm.get(NOTES).touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="showHowFound">
        <mat-form-field>
          <mat-label>{{HOW_FOUND}}</mat-label>
          <input #howFound [formControlName]="HOW_FOUND"
                 [required]="requireHowFound" matInput>
          <mat-hint align="end">{{howFound.value?.length || 0}}/50</mat-hint>
          <mat-error
            *ngFor="let validation of additionalValidationMessages.howFound">
            <mat-error
              *ngIf="additionalInfoForm.get(HOW_FOUND).hasError(validation.type) && (additionalInfoForm.get(HOW_FOUND).dirty || additionalInfoForm.get(HOW_FOUND).touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="additionalTermsUrl">
        <div class="margin-v-20">
          <mat-checkbox
            [class.warning]="additionalInfoForm.get('additionalTerms').hasError('required')"
            color="primary" formControlName="additionalTerms" required>I accept
            the <a [href]="additionalTermsUrl" target="_blank">clinic's
              policies</a>*
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </form>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="onBack()" mat-stroked-button type="button">Back</button>
    <button (click)="onNext()" [disabled]="!additionalInfoForm.valid ||
    !canSave" color="primary" mat-raised-button type="button">
      Register
    </button>
  </div>
</div>
