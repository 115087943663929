<mat-toolbar class="padding-10" color="primary" fxLayout="column">
  <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
    <h3 class="mat-h3">Booking Agreement</h3>

    <button [mat-dialog-close]="true" aria-label="close" mat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-dialog-content style="margin:0; padding: 10px">
  <h3 class="mat-h3">By booking an appointment you agree to the following:</h3>
  <h3 class="mat-h3"><b>General</b></h3>
  <p class="mat-body-1">
    The Groom Room is a trading name for Pets at Home Limited (“Pets at Home”) and is a company registered
    in
    England and Wales under company number 01822577. Our VAT number is 616 4317 54. Our registered office is
    Epsom Avenue, Stanley Green Trading Estate, Handforth, Cheshire, SK9 3RN.<br>

    • You are required to pay a £15 deposit to secure your appointment when booking. This can be carried
    over to
    future bookings should you wish. The deposit secures against any missed or cancelled appointments and
    will
    not be refunded unless you give at least 48 hours’ notice of cancellation of your booking.
    <br>
    • If you arrive more than 15 minutes late, we cannot guarantee your appointment will remain available.
    <br>
    • Any pets not collected at the agreed collection time will incur an additional charge of £5 per 30
    minutes
    they are in holding after the collection time stated on the grooming contract.
    <br>
    • Please ensure that your pet has been given the opportunity to be relieved before they enter the
    grooming
    salon. This will ensure that they get the maximum enjoyment out of their groom.
    <br>
    • Please do not feed your pet within 30 minutes prior to their appointment.
    <br>
    • All customers are responsible for keeping their pet up to date with their vaccinations.
    <br>
    • We recommend puppies wait 2 weeks after their 2nd vaccination before booking a puppy groom.
    <br>
    • You must inform the groomer of any medical conditions and may be asked for a letter from your vet
    stating
    your pet is healthy to be groomed before the groom is carried out.
    <br>
    • Whilst every care is taken of the pets being groomed, any pet left within the Groom Room is left
    entirely
    at the owner’s risk.
    <br>
    • We will not groom any dog that has been microchipped within the last 24 hours.
    <br>
    • The grooming team have the right to refuse to groom any pet without reason.
    <br>
    • The grooming team have the right to refuse any customer without reason.
    <br>
    • The grooming team have the right to cancel a groom if it would affect the pet’s welfare to continue or
    there is a risk of injury to the grooming team.
    <br>
    • Pets at Home and The Groom Room will not be held responsible for any loss of personal items during the
    appointment.

    <br> • We will not groom any dog that is, or is crossed with, a Pit Bull Terrier, Dogo Argentino, Perro
    De
    Presa
    Canario, Dogo Canario, Japanese Tosa, Fila Brasileiro, Czechoslovakian Wolfdog, Saarloos Wolfhound/
    Wolfdog
    or any wolf hybrid. We will not groom any dog that must be registered under the Dangerous Dogs Act 1991,
    the
    Dangerous Dogs (Amendment) Act 1997 or any further amendments to this Act.<br></p>

  <h3 class="mat-h3"><b>Matted dogs</b></h3>

  <p class="mat-body-1">
    • We will endeavour to groom your pet to your requirements, however if your pet’s coat is excessively
    matted, the matted coat will be clipped (shaved) out. Depending on the severity of the matted coat,
    which
    will be discussed during your pet’s consultation, there may be an additional charge in addition to the
    grooming price, with targeted de-matting (such as under the legs and behind the ears) at £5 or general
    de-matting over the body at £10 for up to a maximum of 15 minutes. After this 15 minute point, we will
    recommend a clip off for the dog’s welfare as per our Clipping Policy, therefore it may not be possible
    to
    achieve the style you desire. As groomers, our primary concern is to relieve any distress or discomfort
    your
    pet may be experiencing as a result of their severely or excessively matted coat. Should your pet come
    in
    heavily matted, due to the time it takes and special care needed to remove this, you will be required to
    sign a Clipping Policy, which will be explained by your groomer and carries a £10 additional charge to
    the
    grooming price. You will be made aware of any additional costs during your consultation.<br></p>

  <h3 class="mat-h3"><b>Two Colleague Groom</b></h3>

  <p class="mat-body-1">
    There can be a number of reasons why it may be necessary for your dog to require two groomers to carry
    out a
    groom. If the behaviour of the dog warrants two groomers to carry out the groom, this will be charged
    per 30
    minutes of time taken at £10. If it is to ensure the welfare of the dog (e.g. an older dog) and the
    groom
    requires two groomers, as with the above, this will be charged per 30 minutes of time taken at £10.<br></p>

  <h3 class="mat-h3"><b>Extra Care</b></h3>

  <p class="mat-body-1">
    • Pets which are pregnant or in season will not be accepted for a groom.
    <br>
    • If your pet is found to have fleas, we will charge a fee of £12 in addition to the cost of the groom,
    where your pet will be bathed in a flea shampoo and the salon disinfected.

    <br>• If your pet is found to have a tick, we will charge a fee of £3 to remove in addition to the cost of
    the
    groom. However we advise if your pet has a tick to see your vet, as they are known carriers of disease
    and
    may require further treatment.

    <br> • Some salons may use a drying cabinet. It is the owner’s responsibility to inform the groomer if their
    pet
    has any conditions (medical or not) that may exclude them from using the cabinet. Any pet being placed
    in
    the cabinet is done so entirely at the owner’s risk.

    <br>• Any pets that are deemed at the discretion of the grooming team to be in need of immediate veterinary
    attention will receive this and you agree that you will be liable for any resulting additional costs.

    <br> • Any dogs that are aggressive towards a member of the grooming team or another pet may need to be
    muzzled.
    It is the owner’s responsibility to inform the groomer if they do not wish their pet to be muzzled.
    Refusal
    may result in the groom not taking place.

    <br> • We strongly advise you insure your pet. Whilst we take every reasonable precaution to prevent it, we
    will
    not accept any responsibility for any loss, injury, death or illness suffered by your pet whilst in our
    care, except to the extent that we are unable to limit or extend our liability.

    <br> • If your pet appears unwell at the time of the appointment, they will not be accepted for grooming due
    to
    the risk of cross infection to other pets.

    <br> • Any complaints with regards to the Grooming salon must be directed, in first instance, to the Groom
    Room
    Salon Manager and/or to our Customer Services department on 0800 328 4204.

    <br> • Pets at Home reserves the right to amend these terms and conditions at any time.
  </p>
</mat-dialog-content>
