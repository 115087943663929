<div [ngClass]="{'map-container' : !fullWidth}" fxLayout="column">
  <google-map (zoomChanged)="zoomChanged()" [center]="center"
              [height]="mapHeight" [options]="options" [zoom]="zoom"
              class="google-map" width="100%">
    <div class="map-overlays" [style.top]="(0 - mapHeightPixels)+'px'">
      <div class="map-search-form">
        <form [formGroup]="mapSearch">
          <div fxLayout="row">
            <mat-form-field appearance="none" class="full-width-form-item"
                            style="margin-bottom: -2em;margin-top: -0.8em;">
              <input #searchText [matAutocomplete]="auto"
                     formControlName="search" matInput
                     placeholder="Location search...">
              <div fxLayout="row" matSuffix style="margin-top:0.8em">
                <button (click)="searchMap()" [attr.aria-label]="'search'"
                        class="search-buttons" mat-icon-button>
                  <mat-icon>search</mat-icon>
                </button>
                <button (click)="searchText.value = ''" *ngIf="searchText.value"
                        [attr.aria-label]="'clear'" class="search-buttons"
                        mat-icon-button>
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                              panelWidth="250px">
              <mat-option (onSelectionChange)="autoCompleteSelected(locality)"
                          *ngFor="let locality of mapsAutocomplete$ | async"
                          [value]="locality.name">{{locality.name}}
                , {{locality.admin_1}}</mat-option>
            </mat-autocomplete>
          </div>
        </form>
      </div>
      <div class="map-search-container">
        <div class="divider-line"></div>
        <button (click)="gotoCurrentLocation()" mat-button
                style="width:100%;padding:0">
          <div fxLayout="row" fxLayoutAlign="space-between center"
               style="padding:10px 10px 5px">
            <p class="search-bottom-text" style="text-transform: initial">Around
              you</p>
            <mat-icon color="accent" style="padding-top:10px">gps_fixed
            </mat-icon>
          </div>
        </button>
      </div>
      <div *ngIf="selectedClinic" class="result-container">
        <div fxLayout="row" fxLayoutAlign="space-between center"
             style="padding:10px">
          <button (click)="selectedClinic=null" mat-icon-button
                  style="color:white; background-color: white">
            <mat-icon color="accent">arrow_back</mat-icon>
          </button>
          <p class="clinic-title">{{selectedClinic.practiceName}}</p>
          <button (click)="getDirections()" mat-icon-button
                  style="color:white; background-color: white">
            <mat-icon color="accent">directions</mat-icon>
          </button>
        </div>
        <div class="selected-clinic-container">
          <div (click)="gotoWeb()" fxLayout="row" fxLayoutAlign="start center"
               fxLayoutGap="20px">
            <mat-icon color="accent">launch</mat-icon>
            <p class="no-margin">Visit page</p></div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon color="accent">calendar</mat-icon>

            <div fxLayout="column">
              <p>Mon: <span>{{selectedClinic.openingMon}}<br></span></p>
              <p>Tue: <span>{{selectedClinic.openingTue}}<br></span></p>
              <p>Wed: <span>{{selectedClinic.openingWed}}<br></span></p>
              <p>Thu: <span>{{selectedClinic.openingThu}}<br></span></p>
              <p>Fri: <span>{{selectedClinic.openingFri}}<br></span></p>
              <p>Sat: <span>{{selectedClinic.openingSat}}<br></span></p>
              <p>Sun: <span>{{selectedClinic.openingSun}}<br></span></p>
            </div>
          </div>
          <div (click)="getDirections()" fxLayout="row"
               fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon color="accent">place</mat-icon>
            <div fxLayout="column">
              <p style="margin:0px">{{selectedClinic.address1}}<br>
                <span
                  *ngIf="selectedClinic.address2">{{selectedClinic.address2}}
                  <br></span>
                <span
                  *ngIf="selectedClinic.address3">{{selectedClinic.address3}}
                  <br></span>
                <span
                  *ngIf="selectedClinic.address4">{{selectedClinic.address4}}
                  <br></span>
                {{selectedClinic.postcode}}</p>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon color="accent">local_parking</mat-icon>
            <div fxLayout="column">
              <p>{{selectedClinic.parking}}</p>
            </div>
          </div>
          <div (click)="telClinic()" fxLayout="row" fxLayoutAlign="start center"
               fxLayoutGap="20px">
            <mat-icon color="accent">phone</mat-icon>
            <p>{{selectedClinic.phoneNumber}}</p></div>
          <button (click)="selectClinic(selectedClinic)" color="accent"
                  mat-stroked-button style="width:100%;font-size:18px">Select
            this {{isGroomRoom ? 'salon' : 'clinic'}}
          </button>
        </div>
      </div>
    </div>
    <map-marker #markerElem (mapClick)="openInfo(markerElem, marker)"
                *ngFor="let marker of markers" [options]="marker.options"
                [position]="marker.position"
                [title]="marker.title"></map-marker>
  </google-map>
</div>
