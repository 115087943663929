import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as Parse from 'parse';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '../auth/auth.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {environment} from '../../environments/environment';
import {ParseErrorHandler} from '../parse-error-handler';
import {
  ClientPatientDetails,
  TermsAndConditions
} from '@appyvet/vetbooker-definitions/definitions/interfaces/client_patient_details';
import {MarketingContact, MarketingResponse} from '@appyvet/vetbooker-definitions/definitions/interfaces/marketing';
import {MultiAppointmentItem} from '@appyvet/vetbooker-definitions/dist/bookings';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  p;
  error = new BehaviorSubject<string>('');
  clientError$ = this.error.asObservable();
  termsAndConditions$ = new Subject<boolean>();
  isLoading = new BehaviorSubject<boolean>(false);
  private clientPatientDetails = new BehaviorSubject<ClientPatientDetails>(null);
  clientPatientDetails$ = this.clientPatientDetails.asObservable();

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService,
              private authService: AuthService) {
    authService.isLoggedIn$.subscribe(result => {
      if (!result) {
        this.clearSessionData();
      }
    });
  }

  clearSessionData() {
    this.error.next(null);
    this.clientPatientDetails.next(null);
  }

  // getLocalData(): Promise<Object> {
  //   return this.http.get('http://localhost:4201/assets/mock_vb_response.json').toPromise();
  // }
  addAppointmentLocally(result: MultiAppointmentItem) {

  }

  deleteAppointmentLocally(originalAppointment: MultiAppointmentItem) {

  }

  agreeNewTerms() {
    fromPromise(Parse.Cloud.run('agreeTerms', {
      device: 'web'
    }, {sessionToken: this.authService.getSessionToken()})).subscribe((result: TermsAndConditions) => {
      if (result) {
        const updatedClientPatientDetails: ClientPatientDetails = Object.assign({},
          this.clientPatientDetails.getValue());
        updatedClientPatientDetails.termsAndConditions = result;
        this.clientPatientDetails.next(updatedClientPatientDetails);
        this.termsAndConditions$.next(true);
        this.router.navigate(['/landing']);
      }
    });
  }

  getClientPatientDetails() {
    this.isLoading.next(true);
    this.error.next('');
    Parse.Cloud.run('getClientPatientDetails', {
      device: 'web'
    }, {sessionToken: this.authService.getSessionToken()}).then((parseResult: ClientPatientDetails) => {
      this.isLoading.next(false);
      this.clientPatientDetails.next(parseResult);
      this.authService.setClinicCode(parseResult.clinicCode);
      if (environment.VETS_4_PETS) {
        this.termsAndConditions$.next(parseResult.termsAndConditions.latest);
      } else {
        this.termsAndConditions$.next(true);
      }
    }, error => {
      const parsedError = ParseErrorHandler.handleParseError(error);
      this.isLoading.next(false);
      if (parsedError.code === 410) {
        this.authService.logout(false, true);
        this.router.navigate(['/login'],
          {queryParams: {errorCode: '410', clinicCode: this.authService.getClinicCode()}});
      } else {
        this.clientPatientDetails.error(parsedError);
        if (parsedError.code !== 499) {
          this.error.next(error.message || 'Error trying to retrieve client details, please try again');
        }
      }
    });
  }

  updateClientMarketingDetails(contacts?: MarketingContact[], preferences?: MarketingResponse[]) {
    const updatedClientPatientDetails = Object.assign({}, this.clientPatientDetails.getValue());
    if (contacts) {
      updatedClientPatientDetails.clientData.marketing.contacts = contacts;
    }
    if (preferences) {
      updatedClientPatientDetails.clientData.marketing.preferences = preferences;
    }
    this.clientPatientDetails.next(updatedClientPatientDetails);
  }
}
