import {Component, OnInit} from '@angular/core';
import {SignupService} from '../signup/signup.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  constructor(private signupService: SignupService) {
  }

  ngOnInit(): void {
  }

  async signInWithGoogle() {
    const socialResult =
      await this.signupService.socialSignIn('Google');
  }

  async signInWithApple() {
    const appleResult =
      await this.signupService.socialSignIn('SignInWithApple');
  }
}
