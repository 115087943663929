import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {RegistrationService} from './registration.service';
import {EMPTY, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {VetBookerError} from '../interfaces/vberror';
import {RegistrationSettings} from '@appyvet/vetbooker-definitions/dist/registrations';

@Injectable({
  providedIn: 'root',
})
export class RegistrationResolver implements Resolve<RegistrationSettings> {
  constructor(private registrationService: RegistrationService, private router: Router) {

  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<RegistrationSettings> | Observable<never> {
    let clinicCode = route.queryParamMap.get('clinicCode');
    if (!clinicCode) {
      this.router.navigateByUrl('error', {
        state: {
          from: 'register',
          error: 'No clinic code',
          errorCode: 411
        }
      });
      return of(null);
    }
    return this.registrationService.getRegistrationSettings(clinicCode).pipe(catchError((e: VetBookerError) => {
      if (e.code === 411) {
        this.router.navigate(['error'], {
          queryParams: {
            from: 'register',
            error: 'Clinic no longer registered for this service',
            errorCode: 411
          }
        });
      }
      throw e;
    }), take(1), mergeMap(settings => {
      if (settings) {
        return of(settings);
      } else {
        this.router.navigateByUrl('error', {
          state: {
            from: 'register',
            error: 'No clinic code',
            errorCode: 411
          }
        });
        return EMPTY;
      }
    }));
  }
}
