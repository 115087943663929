import {Component, Input, OnInit} from '@angular/core';
import {PatientInsurancePolicy, VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-insurance-policies',
  template: `
    <mat-card class="detail-card" fxLayout="column" fxLayoutAlign="start start">
      <ng-container *ngIf="insurancePolicies?.length>0 ; else empty">
        <div *ngFor="let policy of insurancePolicies" fxFlexFill fxLayout="row" fxLayout="start center"
             fxLayoutAlign.xs="space-between center">
          <p class="item-title item-start">{{policy.providerName}}</p>
          <p class="title-content">{{policy.policyNumber}}</p></div>
      </ng-container>
      <ng-template #empty><p class="title-content">No insurance policies found for {{patient.name}}</p>
      </ng-template>
    </mat-card>
  `,
  styleUrls: ['../patient-detail/patient-detail.component.scss']
})
export class InsurancePoliciesComponent implements OnInit {
  @Input() insurancePolicies: PatientInsurancePolicy[];
  @Input() patient: VbPatient;

  constructor() {
  }

  ngOnInit(): void {
  }

}
