<mat-card class="detail-card">
  <form [formGroup]="bioForm">
    <ng-container *ngIf="loading; else contentBlock">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-progress-spinner mode="indeterminate" diameter="30"
                              color="primary"></mat-progress-spinner>
        <p>Saving bio</p></div>
    </ng-container>
    <ng-template #contentBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center"
           fxLayout.xs="column">
        <ng-container *ngIf="editBio; else displayContainer">
          <mat-form-field style="width:100%" appearance="outline">
            <mat-label>Pet Bio</mat-label>
            <textarea matInput formControlName="bio" #input maxLength="250"
                      placeholder="Write a short bio here for your pet..."></textarea>
            <mat-hint align="end">{{input.value?.length || 0}}/250</mat-hint>
          </mat-form-field>
        </ng-container>
        <ng-template #displayContainer>
          <div class="detail-row" fxFlex.xs="100%" fxLayout="row"
               fxLayout.xs="column" fxLayoutAlign="start center">
            <p class="item-title item-start" [fxHide.xs]="bio">Bio:</p>
            <p class="content-text">{{bio || 'None'}}</p>
          </div>
        </ng-template>
        <ng-container *ngIf="editBio; else editBtnContainer">
          <div fxLayout="column" fxLayout.xs="row" class="full-width-xs"
               fxLayoutAlign.xs="space-between center">
            <button (click)="onSaveBio()" color="primary" mat-stroked-button
                    fxFlexOrder.xs="2">
              Save
            </button>
            <button (click)="editBio = false" color="warn" mat-stroked-button
                    fxFlexOrder.xs="1">
              Cancel
            </button>
          </div>
        </ng-container>
        <ng-template #editBtnContainer>
          <button (click)="onEdit()" color="primary" mat-stroked-button>
            Edit
          </button>
        </ng-template>
      </div>
    </ng-template>
  </form>
</mat-card>
