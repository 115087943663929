import {Breadcrumb, BreadcrumbsResolver} from '@exalif/ngx-breadcrumbs';
import {Injectable} from '@angular/core';
import {PatientService} from './patient.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class PatientBreadcrumbsResolver extends BreadcrumbsResolver {

  constructor(private patientService: PatientService) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Breadcrumb[] {
    const patientNumber = route.params.patientNumber;

    const patient = this.patientService.getPatient(patientNumber);
    return [{
      text: patient.name,
      path: super.getFullPath(route.parent) + '/' + patient.patientNumber
    }];
  }
}
