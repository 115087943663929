import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-closed-dialog',
  templateUrl: './closed-dialog.component.html',
  styleUrls: ['./closed-dialog.component.scss']
})
export class ClosedDialogComponent implements OnInit {
  typeName = environment.GROOM ? 'Salon' : 'Clinic';
  individualTypeName = environment.GROOM ? 'salon' : 'clinic';
  isGroomRoom = environment.GROOM;

  constructor() {
  }

  ngOnInit(): void {
  }

}
