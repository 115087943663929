import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-old-email-link',
  templateUrl: './old-email-link.component.html',
  styleUrls: ['./old-email-link.component.scss']
})
export class OldEmailLinkComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

//http://localhost:4200/login/#/bookingHistory?appointmentId=6cYnAx7SMW
}
