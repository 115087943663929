import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SessionService} from '../../services/session-service.service';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import ComponentRestrictions = google.maps.places.ComponentRestrictions;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import PlaceResult = google.maps.places.PlaceResult;
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-google-autocomplete',
  template: `
    <mat-form-field style="width:100%;margin-bottom: -1.25em" appearance="outline">
      <mat-label>{{label}}</mat-label>
      <input matInput type="text" [matAutocomplete]="auto" [formControl]="addressInput" #search
             (keyup.enter)="getPlaceAutocomplete(search.value, true)">
      <button mat-icon-button matSuffix color="primary" (click)="getPlaceAutocomplete(search.value, true)">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <ng-container *ngIf="!hideManualAddress">
      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="manualEntry.emit(true)" style="font-size: 12px">Enter address
          manually
        </button>
      </div>
    </ng-container>
    <div #addressResults></div>
    <mat-autocomplete #auto="matAutocomplete" style="width:100%">
      <ng-container *ngIf="places | async as retrievedPlaces">
        <mat-option *ngFor="let place of retrievedPlaces" [value]="place.description"
                    (onSelectionChange)="invokeEvent(place)">{{place.description}}
        </mat-option>
        <mat-option *ngIf="retrievedPlaces.length === 0">No results found</mat-option>
      </ng-container>
      <div style="height:30px" fxLayout="row" fxLayoutAlign="end center"><img
        src="../../../assets/powered_by_google_on_white.png" alt="powered_by_google"></div>
    </mat-autocomplete>
  `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Output() setAddress: EventEmitter<PlaceResult> = new EventEmitter();
  @Output() manualEntry: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('addressResults') addressResults: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @Input() countryCode: string | string[];
  @Input() hideManualAddress: boolean;
  addressInput = new FormControl('');
  places = new BehaviorSubject<google.maps.places.AutocompletePrediction[]>([]);
  private autocompleteService: google.maps.places.AutocompleteService;
  private types: string[];
  private componentRestrictions: ComponentRestrictions;
  private placesService: google.maps.places.PlacesService;
  @Input() searchTypes: string;
  label: string;
  private MIN_CHARS_FOR_AUTOCOMPLETE = environment.GROOM || environment.VETS_4_PETS ? 4 : 2;
  private DEBOUNCE_TIME = environment.GROOM || environment.VETS_4_PETS ? 800 : 400;


  constructor(private sessionService: SessionService, private ngZone: NgZone) {
  }

  ngOnInit() {
    if (!this.searchTypes) {
      this.searchTypes = 'address';
      this.label = 'Start typing address...';
      this.types = ['geocode'];
    } else if (this.searchTypes === '(regions)') {
      this.label = 'Enter your Town/Postcode';
      this.types = [this.searchTypes];
    }
    this.autocompleteService = new google.maps.places.AutocompleteService();
    if (this.countryCode === 'gb') {
      //If GB, add guernsey, jersey and isle of man
      this.countryCode = ['gb', 'gg', 'je', 'im'];
    }
    this.componentRestrictions = {country: this.countryCode};
    this.addressInput.valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(value => {
      if (value && value.length > this.MIN_CHARS_FOR_AUTOCOMPLETE) {
        this.getPlaceAutocomplete(value);
      }
    });
  }

  invokeEvent(place: AutocompletePrediction) {
    this.placesService.getDetails({placeId: place.place_id}, (result =>
      this.ngZone.run(() => {
        this.setAddress.emit(result);
      })));
  }

  getPlaceAutocomplete(value: string, invoke?: boolean) {
    this.autocompleteService.getPlacePredictions({
      componentRestrictions: this.componentRestrictions,
      input: value,
      types: this.types
    }, (result, status) => this.ngZone.run(() => {
      if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        this.places.next([]);
      } else {
        if (invoke) {
          this.invokeEvent(result[0]);
          this.autocomplete.closePanel();
        } else {
          this.places.next(result);
        }
      }
    }));
  }

  ngAfterViewInit(): void {
    this.placesService = new google.maps.places.PlacesService(this.addressResults.nativeElement);
  }

}
