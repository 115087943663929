<div class="content-text" fxLayout="column">
  <ng-container *ngIf="!isNotClientDetailsPage">
    <p class="message-text accent-text-color">{{addressChangeText}}</p>
  </ng-container>
  <ng-container *ngIf="!showAddressForm">
    <app-google-autocomplete (setAddress)="addressSelected($event)"
                             (manualEntry)="showAddressForm = true"
                             [countryCode]="countryCode"></app-google-autocomplete>
  </ng-container>
  <div *ngIf="showAddressForm">
    <form [formGroup]="addressForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>{{ADDRESS_1}}</mat-label>
        <input [formControlName]="ADDRESS_1" matInput required>
        <mat-error
          *ngFor="let validation of addressValidationMessages.address1">
          <mat-error
            *ngIf="addressForm.get(ADDRESS_1).hasError(validation.type) && (addressForm.get(ADDRESS_1).dirty || addressForm.get(ADDRESS_1).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ADDRESS_2}}</mat-label>
        <input [formControlName]="ADDRESS_2" [required]="requirements.address2"
               matInput>
        <mat-error
          *ngFor="let validation of addressValidationMessages.address1">
          <mat-error
            *ngIf="addressForm.get(ADDRESS_2).hasError(validation.type) && (addressForm.get(ADDRESS_2).dirty || addressForm.get(ADDRESS_2).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{TOWN}}</mat-label>
        <input [formControlName]="TOWN" [required]="requirements.town" matInput>
        <mat-error *ngFor="let validation of addressValidationMessages.town">
          <mat-error
            *ngIf="addressForm.get(TOWN).hasError(validation.type) && (addressForm.get(TOWN).dirty || addressForm.get(TOWN).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{COUNTY}}</mat-label>
        <input [formControlName]="COUNTY" [required]="requirements.county"
               matInput>
        <mat-error *ngFor="let validation of addressValidationMessages.county">
          <mat-error
            *ngIf="addressForm.get(COUNTY).hasError(validation.type) && (addressForm.get(COUNTY).dirty || addressForm.get(COUNTY).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{POSTCODE}}</mat-label>
        <input [formControlName]="POSTCODE" [required]="requirements.postcode"
               matInput>
        <mat-error
          *ngFor="let validation of addressValidationMessages.postcode">
          <mat-error
            *ngIf="addressForm.get(POSTCODE).hasError(validation.type) && (addressForm.get(POSTCODE).dirty || addressForm.get(POSTCODE).touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="cancelUpdate()" mat-stroked-button type="button">Back
    </button>
    <button (click)="saveAddress()" *ngIf="showAddressForm"
            [disabled]="!addressForm.valid" color="primary"
            mat-raised-button>{{isNotClientDetailsPage ? 'Next' : 'Save'}}
    </button>
  </div>
</div>
