import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../../settings/settings.service';
import {SessionService} from '../../../services/session-service.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {MarketingContact, MarketingQuestion, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';

@Component({
  selector: 'app-combined-marketing',
  templateUrl: './combined-marketing.component.html',
  styleUrls: ['./combined-marketing.component.scss']
})
export class CombinedMarketingComponent implements OnInit, OnDestroy {

  private sessionSubscription: Subscription;
  marketingPreferences: MarketingResponse[];
  marketingQuestions: MarketingQuestion[];
  marketingChannels: MarketingContact[];
  loading$ = new BehaviorSubject<boolean>(false);
  canSave: boolean;


  constructor(private sessionService: SessionService, private settingsService: SettingsService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.sessionSubscription = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.marketingPreferences = clientPatientDetails.clientData.marketing.preferences;
        this.marketingQuestions = clientPatientDetails.clientData.marketing.questions;
        this.marketingChannels = clientPatientDetails.clientData.marketing.contacts;
      }
    });
  }

  async onSave() {
    this.loading$.next(true);
    try {
      // Vets 4 pets have hardcoded question 2 to be do not contact. In this scenario, must deactivate all contact
      // channels
      if (environment.VETS_4_PETS) {
        this.marketingPreferences.forEach(preference => {
          if (preference.questionId === '2' && preference.allowed) {
            this.marketingChannels.forEach(channel => {
              channel.allowed = false;
            });
          }
        });
      }
      await this.settingsService.updateCombinedMarketingDetails(this.marketingChannels, this.marketingPreferences);
      this.snackBar.open('Successfully saved marketing preferences', null, {duration: 5000});
    } catch (e) {
      this.snackBar.open('Error saving marketing preferences', null, {duration: 5000});
    }
    this.loading$.next(false);
  }

  ngOnDestroy(): void {
    this.sessionSubscription?.unsubscribe();
  }

  checkIfCanSave() {
    let hasAllowedChannel = false;
    this.marketingChannels.forEach(channel => {
      if (channel.allowed) {
        hasAllowedChannel = true;
      }
    });
    this.canSave = hasAllowedChannel;
  }

  updatePreferences($event: MarketingResponse[]) {
    this.marketingPreferences = $event;
    // Must ensure a channel is saved if preference 1 agreed
    this.marketingPreferences.forEach(preference => {
      if (preference.questionId === '1' && preference.allowed) {
        this.checkIfCanSave();
      }
      // Can always save if marketing consent disabled
      if (preference.questionId === '2' && preference.allowed) {
        this.canSave = true;
      }
    });
  }

  updateChannels($event: MarketingContact[]) {
    this.marketingChannels = $event;
    this.checkIfCanSave();
  }
}
