import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SpeciesItem} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-species-selector',
  template: `
    <div class="card-option-container" fxFill fxLayout="row wrap" fxLayoutAlign="start center">
      <div (click)="setSelectedSpecies(specie);" *ngFor="let specie of species"
           [class.selected-card]="specie.name === selectedSpecies?.name" class="selection-card" fxFlex="28%"
           fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <mat-icon *ngIf="specie.name === selectedSpecies?.name" style="margin:5px">check_circle
          </mat-icon>
          <div *ngIf="specie.name !== selectedSpecies?.name" class="empty-circle"></div>
        </div>
        <p [ngStyle]="{'color':specie.name === selectedSpecies?.name ? '#FFFFFF' : ''}"
           class="card-header-text">{{specie.name}}</p>
      </div>
    </div>
  `,
  styleUrls: ['../../components/common-components/patient-selector/patient-selector.scss']
})
export class SpeciesSelectorComponent implements OnInit {
  @Input() species: SpeciesItem[];
  @Input() selectedSpecies: SpeciesItem;
  @Output() selectedSpeciesChange = new EventEmitter<SpeciesItem>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setSelectedSpecies(species: SpeciesItem) {
    this.selectedSpeciesChange.emit(species)
  }
}
