import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {PatientService} from '../patient.service';
import {format, parseJSON} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {SessionService} from '../../services/session-service.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {FeeItem, Visit} from '@appyvet/vetbooker-definitions/dist/patient_history';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss']
})
export class PatientHistoryComponent implements OnInit {
  patient: VbPatient;
  history: Visit[];
  currency: string;
  historyPrescriptionsActive: boolean;
  page: number = 1;
  private currentSkip: number = 0;
  private timezone: string;

  constructor(private route: ActivatedRoute, private patientService: PatientService,
              private sessionService: SessionService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe((clientPatientDetails) => {
      this.timezone = clientPatientDetails.timezone;
      this.currency = clientPatientDetails.country.currencyDisplay;
      this.historyPrescriptionsActive = clientPatientDetails.prescriptions.historyPrescriptionsActive;
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.patient = this.patientService.getPatient(params.get('patientNumber'));
      this.patientService.getHistory$({
        patientNumber: this.patient.patientNumber,
        skip: this.currentSkip
      }).subscribe((history: Visit[]) => {
        this.history = history;
      });
    });
  }

  getDate(date: string) {
    return format(utcToZonedTime(date, this.timezone), 'dd/MM/yyyy HH:mm');
  }

  getPrice(price: string) {
    if (price) {
      const splitPrice = price.split('-');
      if (splitPrice.length > 1) {
        return '-' + this.currency + splitPrice[1];
      } else {
        return this.currency + splitPrice[0];
      }
    } else {
      return 'Free';

    }
  }

  showLabelDialog(item: FeeItem) {
    const config = new MatDialogConfig();
    config.data = {message: item.itemLabel, confirmationText: 'OK', title: item.itemName};
    this.dialog.open(AlertDialogComponent, config);
  }

  repeatPrescription(item: FeeItem) {

  }

  skip(skipAmount: number) {
    this.page += Math.sign(skipAmount);
    this.currentSkip += skipAmount;
    this.patientService.getHistory$({
      patientNumber: this.patient.patientNumber,
      skip: this.currentSkip
    }).subscribe((history: Visit[]) => {
      this.history = history;
    });
  }
}
