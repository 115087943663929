import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SignupService} from '../signup/signup.service';
import {Angulartics2} from 'angulartics2';
import {VetBookerError} from '../../interfaces/vberror';
import {ParseErrorHandler} from '../../parse-error-handler';

@Component({
  selector: 'app-email-link-verification',
  templateUrl: './email-link-verification.component.html',
  styleUrls: ['./email-link-verification.component.scss']
})
export class EmailLinkVerificationComponent implements OnInit {
  loading$ = new BehaviorSubject<boolean>(true);
  error$ = this.signupService.error$;

  constructor(private activatedRoute: ActivatedRoute, private signupService: SignupService,
              private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.loading$.next(true);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.clientNumber) {
      } else if (params.verificationCode) {
        this.signupService.setEmail(params.email);
        this.signupService.verifyEmailWithCode(params.verificationCode);
        this.angulartics2.eventTrack.next({
          action: 'Register From Email Link',
          properties: {
            category: 'New User Registration',
            label: params.clinicName || 'VetBooker'
          }
        });
      }
    });
  }

}
