import {Injectable, Injector} from '@angular/core';
import * as Parse from 'parse';
import {environment} from '../environments/environment';
import Amplify from '@aws-amplify/auth';
import {Breadcrumb, BreadcrumbsConfig} from '@exalif/ngx-breadcrumbs';
import {Environment} from "@angular/compiler-cli/src/ngtsc/typecheck/src/environment";
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/angular";


@Injectable()
export class AppService {
  private serverUrl: string;
  private isProd: boolean;

  constructor(private injector: Injector, private breadcrumbsConfig: BreadcrumbsConfig) {
  }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (environment.sentryApiCode) {
        Sentry.init({
          dsn: environment.sentryApiCode,
          integrations: [
            new Integrations.BrowserTracing({
              tracingOrigins: ["localhost", "https://yourserver.io/api"],
              routingInstrumentation: Sentry.routingInstrumentation,
            }),
          ],

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,
        });
      }
      Parse.initialize(environment.PARSE_APP_ID);
      const baseUrl = window.location.hostname;
      if (environment.production) {
        // Code below needed to check difference between staging and live GROB/VOB environments as the build is
        // identical so cannot use environment variables in the build scripts
        if (baseUrl.indexOf('vets4pets.com') !== -1) {
          this.isProd = true;
          this.serverUrl = 'https://app-prod.prod.v4papp.aws.petsathome.co.uk/parse/';
        } else if (baseUrl.indexOf('thegroomroom.co.uk') !== -1) {
          this.isProd = true;
          this.serverUrl = 'https://thegroomroom.co.uk/parse/';
        } else if (environment.BACKEND_URL) {
          this.serverUrl = environment.BACKEND_URL;
        } else {
          this.serverUrl = window.location.protocol + '//' + baseUrl + ':' + window.location.port + '/parse/';
        }
      } else {
        const port = environment.VETS_4_PETS ? '1339' : environment.GROOM ? '1338' : '1337';
        this.serverUrl = 'http://localhost:' + port + '/parse/';
      }
      (Parse as any).serverURL = this.serverUrl;
      if (environment.VETS_4_PETS) {
        let redirectUrl = window.location.protocol + '//' + baseUrl;
        if (!environment.production) {
          redirectUrl += ':' + window.location.port;
        }
        const oauth = {
          domain: 'pets-v4p-nonprod.auth.eu-west-1.amazoncognito.com',
          scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: redirectUrl + '/login',
          redirectSignOut: redirectUrl + '/logout',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is
                               // code
        };
        Amplify.configure({
          oauth,
          // identityPoolId: 'eu-west-1:6179218jbdov9k2r4ii65qre6',
          region: 'eu-west-1',
          userPoolId: 'eu-west-1_5OfiHOsBv',
          userPoolWebClientId: 'bganolnk47p5k2e2e16d0sm4d',
          mandatorySignIn: false,
          authenticationFlowType: 'USER_PASSWORD_AUTH',
        });
      }
      this.breadcrumbsConfig.postProcess = (breadcrumbs): Breadcrumb[] => {
        // Ensure that the first breadcrumb always points to home
        let processedBreadcrumbs = breadcrumbs;
        if (breadcrumbs.length && breadcrumbs[0].text !== 'Home') {
          processedBreadcrumbs = [
            {
              text: 'Home',
              path: ''
            }
          ].concat(breadcrumbs);
        }
        return processedBreadcrumbs;
      };
      resolve();
    });

  }
}
