<mat-card>
  <ng-container *ngIf="loading; else contentBlock">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
      <mat-progress-spinner mode="indeterminate" color="primary"
                            diameter="30"></mat-progress-spinner>
      <p class="content-text">Saving contact</p></div>
  </ng-container>
  <ng-template #contentBlock>
    <form [formGroup]="addContactForm">
      <div fxLayout="column" style="font-size: 18px">
        <mat-form-field appearance="outline">
          <mat-label>Contact name</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngFor="let validation of contactValidationMessages.name">
            <mat-error
              *ngIf="addContactForm.get('name').hasError(validation.type) && (addContactForm.get('name').dirty || addContactForm.get('name').touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-radio-group (change)="contactTypeChanged($event.value)"
                         formControlName="type" fxLayout="row"
                         fxLayoutAlign="space-between"
                         style="margin-bottom: 20px">
          <mat-radio-button value="1" *ngIf="!isGroomRoom">Email
          </mat-radio-button>
          <mat-radio-button value="0">Phone</mat-radio-button>
          <mat-radio-button value="3">Mobile</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline">
          <mat-label>Contact entry</mat-label>
          <input formControlName="number" matInput>
          <mat-error
            *ngFor="let validation of contactValidationMessages.number">
            <mat-error
              *ngIf="addContactForm.get('number').hasError(validation.type) && (addContactForm.get('number').dirty || addContactForm.get('number').touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="reminderConsentEnabled">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px"
               style="margin-bottom: 20px">
            <mat-checkbox formControlName="reminder"></mat-checkbox>
            <p class="content-text"
               style="text-align:left">{{reminderConsentText}}</p></div>
        </ng-container>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="cancelAddingContact()" mat-stroked-button
                type="button">
          cancel
        </button>
        <button (click)="addNewContact()" [disabled]="!addContactForm.valid"
                color="primary" mat-stroked-button type="button">
          save
        </button>
      </div>
    </form>
  </ng-template>
</mat-card>
