import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {SessionService} from '../services/session-service.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private sessionService: SessionService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    const routeFragment = route.fragment;
    const params: any = {};
    // Try and pickup a fragment in case redirected here, such as an old registration link
    let url = '/login';
    if (routeFragment) {
      // Take the route fragment, and redirect accordingly.l
      const splitFragment = routeFragment.split('?');
      url = splitFragment[0];
      // Params section might be something like appointmentId=123123&test=true
      const paramsSection = splitFragment[1];
      if (paramsSection) {
        const paramsList = paramsSection.split('&');
        paramsList.forEach(param => {
          const splitParameter = param.split('=');
          const paramName = splitParameter[0];
          params[paramName] = splitParameter[1];
        });
      }
    }
    if (route.queryParams.sessionToken) {
      this.authService.setCookiesAndGoToLanding(route.queryParams.sessionToken, null);
    }
    const result = await this.authService.isLoggedIn();
    if (result) {
      // To accommodate for old links
      if (url.toLowerCase().indexOf('bookinghistory') !== -1) {
        this.router.navigate(['history'], {queryParams: params});
      }
      return true;
    } else {
      if (url.toLowerCase().indexOf('userlogin') !== -1 || url.toLowerCase().indexOf('bookinghistory') !== -1) {
        if (params.resetPassword) {
          this.router.navigate(['/resetPassword'], {queryParams: params});
        } else if (params.verificationCode || params.clientNumber) {
          this.router.navigate(['/emailLink'], {queryParams: params});
        } else {
          this.router.navigate(['/login'], {queryParams: params});
        }
      } else {
        this.router.navigate([url], {queryParams: params});
        return false;
      }
    }
  }
}
