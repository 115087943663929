import {Component, Input, OnInit} from '@angular/core';
import {ParasiteHistory} from '@appyvet/vetbooker-definitions/dist/patient_history';

@Component({
  selector: 'app-flea-worm-tx',
  templateUrl: './flea-worm-tx.component.html',
  styleUrls: ['./flea-worm-tx.component.scss']
})
export class FleaWormTxComponent implements OnInit {
  @Input() parasiteHistory: ParasiteHistory;

  constructor() {
  }

  ngOnInit(): void {
  }

}
