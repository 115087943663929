<div class="padded-container content-text" fxFill fxLayout="column"
     fxLayoutAlign="start center">
  <h1 class="big-header">Register</h1>
  <ng-container *ngIf="showSocialSignIn">
    <app-social-login></app-social-login>
    <div fxLayout="row" class="full-width-form-item"
         fxLayoutAlign="center center" style="margin:10px 0">
      <div class="half-divider"></div>
      <p style="margin:0 10px">or</p>
      <div class="half-divider"></div>
    </div>
  </ng-container>
  <p class="content-text">Enter your details below and we'll register an online
    account for you.</p>
  <form (ngSubmit)="startRegistration()" [formGroup]="matchingForm">
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput placeholder="Email" required
             type="email" autocomplete="email">
      <mat-error *ngFor="let validation of accountValidationMessages.email">
        <mat-error
          *ngIf="matchingForm.get('email').hasError(validation.type) && (matchingForm.get('email').dirty || matchingForm.get('email').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="clinicDetails$ | async as clinicDetails">
      <mat-form-field *ngIf="clinicDetails.smsRegistrationAllowed"
                      appearance="outline" class="full-width-form-item">
        <mat-label>Mobile</mat-label>
        <input formControlName="mobile" matInput placeholder="Mobile" required
               type="tel">
        <mat-error *ngFor="let validation of accountValidationMessages.mobile">
          <mat-error
            *ngIf="matchingForm.get('mobile').hasError(validation.type) && (matchingForm.get('mobile').dirty || matchingForm.get('mobile').touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <ng-container *ngIf="clinicDetails.requirePostcode">
        <mat-form-field appearance="outline" class="full-width-form-item">
          <mat-label>Postcode</mat-label>
          <input formControlName="postcode" matInput placeholder="Postcode"
                 required>
          <mat-error
            *ngFor="let validation of accountValidationMessages.postcode">
            <mat-error
              *ngIf="matchingForm.get('postcode').hasError(validation.type) && (matchingForm.get('postcode').dirty || matchingForm.get('postcode').touched)"
              class="error-message">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
      </ng-container>
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput placeholder="Password"
               required type="password">
        <mat-error
          *ngFor="let validation of accountValidationMessages.password">
          <mat-error
            *ngIf="matchingForm.get('password').hasError(validation.type) && (matchingForm.get('password').dirty || matchingForm.get('password').touched)"
            class="error-message">{{validation.message}}</mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width-form-item">
        <mat-label>Confirm Password</mat-label>
        <input [errorStateMatcher]="matcher" formControlName="confirmPassword"
               matInput placeholder="Confirm Password" type="password">
        <mat-error
          *ngIf="matchingForm.hasError('mismatch') && (matchingForm.get('confirmPassword').dirty || matchingForm.get('confirmPassword').touched)"
          class="error-message">Passwords must match
        </mat-error>
      </mat-form-field>
      <div style="margin-bottom:20px" fxLayout="row" fxLayoutGap="10px"
           fxLayoutAlign="start center">
        <mat-checkbox (change)="consent = !consent"
                      [checked]="consent"></mat-checkbox>
        <p class="no-margin">I agree to the
          <span class="terms-link" (click)="showTerms()">terms and
            conditions</span></p>
      </div>
      <ng-template #submitBlock>
        <button [disabled]="!matchingForm.valid || !consent"
                class="full-width-form-item" color="primary" mat-raised-button
                type="submit">Continue
        </button>
      </ng-template>
      <div *ngIf="loading$ | async; else submitBlock" fxLayout="column"
           fxLayoutAlign="center center">
        <mat-progress-spinner [diameter]="50" color="primary"
                              mode="indeterminate"></mat-progress-spinner>
        <p class="mat-body-2">Looking for client details</p>
      </div>
      <div *ngIf="!clinicDetails.requirePostcode" style="height:77px"></div>
      <div *ngIf="!clinicDetails.smsRegistrationAllowed"
           style="height:77px"></div>
    </ng-container>
  </form>
</div>
