<mat-toolbar color="primary" fxLayout="row"
             fxLayoutAlign="space-between center">
  <h3 class="mat-h3">{{isGroomRoom ? 'Remove' : 'Deactivate'}} {{patient.name}}
    ? </h3>
  <button [mat-dialog-close]="false" aria-label="close" mat-button
          type="button">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center" class="padding-20">
    <ng-container *ngIf="isGroomRoom; else vetsBlock">
      <p class="mat-body-1">We understand that sometimes it may be necessary
        to remove your dog from your online booking account. This is to
        confirm that you would like us to remove {{patient.name}} from your
        customer record.

        To confirm that you want us to proceed, please click the button below
        and we’ll take care of any outstanding appointments {{patient.name}}
        may have at the
        {{ isGroomRoom ? 'salon. If you do have a deposit held with us, please' +
          ' contact us at your convenience to arrange for this to be refunded to' +
          ' you.' : 'clinic.'}} </p>
      <div fxLayout="row" fxLayoutAlign="space-between center"
           class="padding-10" fxFlexFill>
        <button mat-stroked-button [mat-dialog-close]="false">Cancel
        </button>
        <button mat-raised-button color="primary"
                [mat-dialog-close]="{id:null, description: null}">
          Remove Pet
        </button>
      </div>
    </ng-container>
    <ng-template #vetsBlock>
      <p class="mat-body-1">Please tell us why you want to remove
        your pet:</p>
      <mat-form-field>
        <mat-label>Select a reason</mat-label>
        <mat-select #selectedReason>
          <mat-option *ngFor="let reason of reasons" [value]="reason">
            {{reason.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" mat-dialog-actions
           fxLayoutAlign="space-between center">
        <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="warn"
                [mat-dialog-close]="selectedReason.value">Deactivate
        </button>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>
