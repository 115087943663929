<div class="padding-10" fxLayout="column" fxLayoutAlign="center center">
  <ng-container
    *ngIf="!(authService.loginPageLoading$ |async); else loadingBlock">
    <div *ngIf="!embedded" [style.background]="getBackgroundStyle()"
         class="main-logo" fxLayout fxLayoutAlign="center center"></div>
    <ng-container *ngIf="!hideName">
      <h2 class="clinic-title" [style.color]="isWhiteBackground?
             'rgb(var(--palette-primary-500))':
             'white'">{{clinicDetails.clinicName}}</h2></ng-container>
    <ng-container *ngIf="isGroomRoom || isVets4Pets">
      <button (click)="changeClinic()" class="forgotten-button"
              style="font-size: 12px; color:white; margin: 0" mat-button
              type="button">Not the right {{typeName.toLowerCase()}}?
        Click here to change
      </button>
    </ng-container>
    <div [class.right-panel-active]="rightPanelActive$ | async"
         class="container" id="container">
      <div [class.full-form-entry]="goFullWidth$ | async"
           [class.sign-up-container]="!(goFullWidth$ | async)"
           [style.top]="signupTop" class="form-container  content-text">
        <router-outlet name="signup"></router-outlet>
      </div>
      <div [class.remove-form]="(goFullWidth$ | async)"
           class="form-container sign-in-container">
        <!--      <router-outlet name="signin"></router-outlet>-->

        <div [class.hidden-form]="resetPasswordForm"
             class="padded-container content-text" fxFill fxLayout="column"
             fxLayoutAlign="start center">
          <h1 class="big-header">Log In</h1>
          <form [formGroup]="loginForm" style="height:100%" fxLayout="column"
                fxLayoutAlign="center center">
            <p class="content-text">Use your existing account details to sign
              in. If you are not yet registered, click sign up.</p>
            <mat-form-field appearance="outline" class="full-width-form-item">
              <mat-label>Email</mat-label>
              <input formControlName="email" id="email" matInput
                     placeholder="Email" type="email">
              <mat-error
                *ngFor="let validation of accountValidationMessages.email">
                <mat-error
                  *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty || loginForm.get('email').touched)"
                  class="error-message">{{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width-form-item">
              <mat-label>Password</mat-label>
              <input formControlName="password" id="password" matInput
                     placeholder="Password" type="password">
              <mat-error
                *ngFor="let validation of accountValidationMessages.password">
                <mat-error
                  *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                  class="error-message">{{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
            <button (click)="onSubmit()" [disabled]="!loginForm.valid"
                    class="full-width-form-item" color="primary"
                    mat-raised-button type="submit">Continue
            </button>
            <button (click)="resetPasswordForm=true"
                    class="full-width-form-item forgotten-button" mat-button
                    type="button">Forgot your password?
            </button>
            <ng-container *ngIf="showSocialSignIn">
              <app-social-login></app-social-login>
            </ng-container>
          </form>
        </div>
      </div>
      <div [class.hidden-form]="!resetPasswordForm"
           class="form-container sign-in-container">
        <div class="padded-container" fxFill fxLayout="column"
             fxLayoutAlign="start center">
          <h1 class="big-header">Reset password</h1>
          <p class="content-text">Enter your email. If it is already
            registered you should receive a link to follow
            within 5 minutes.</p>
          <form [formGroup]="resetForm">
            <mat-form-field appearance="outline" class="full-width-form-item">
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput placeholder="Email"
                     type="email">
              <mat-error
                *ngFor="let validation of accountValidationMessages.email">
                <mat-error
                  *ngIf="resetForm.get('email').hasError(validation.type) && (resetForm.get('email').dirty || resetForm.get('email').touched)"
                  class="error-message">{{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
            <p *ngIf="passwordReset$ | async" class="mat-body-2">Successfully
              requested new password</p>
            <div *ngIf="!(loading$ | async)" class="full-width-form-item"
                 fxLayout="row" fxLayoutAlign="space-between center">
              <button (click)="resetPasswordForm=false" mat-button>Back
              </button>
              <button (click)="resetPassword()"
                      *ngIf="!(passwordReset$ | async)"
                      [disabled]="!resetForm.valid" color="primary"
                      mat-raised-button type="submit">Remind Me
              </button>
            </div>
            <div *ngIf="loading$ | async" fxLayout="column"
                 fxLayoutAlign="center center">
              <mat-progress-spinner [diameter]="50" color="primary"
                                    mode="indeterminate"></mat-progress-spinner>
              <p class="mat-body-2">Attempting password reset</p>
            </div>
          </form>
        </div>
      </div>
      <div [class.hidden-form]="goFullWidth$| async" class="overlay-container">
        <div class="overlay" [style.background]="isWhiteBackground?
             'rgb(var(--palette-primary-500))':
             'rgb(var(--palette-accent-500))'">
          <div class="overlay-panel overlay-left" fxLayout="column"
               fxLayoutAlign="start center">
            <div fxFlex.gt-xs="50">
              <h1 class="big-header selected">{{isGroomRoom ? 'Welcome Back' :
                'Sign in'}}</h1>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p class="padding-10 content-text">{{isGroomRoom ?
                ' To sign into your Groom Room online Booking account, please click the log in button' :
                'If you\'ve already registered an online account before, click here to sign in'}}
              </p>
              <button (click)="signInPressed()" class="ghost" id="signIn"
                      mat-stroked-button>Log In
              </button>
            </div>
          </div>
          <div class="overlay-panel overlay-right" fxLayout="column"
               fxLayoutAlign="space-between center">
            <div fxHide.xs><h1
              class="big-header selected">{{isGroomRoom ? 'New Customer? Register' : 'Register'}}</h1>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <ng-container *ngIf="isGroomRoom; else standardBlock"><p
                class="content-padding content-text" fxFlexOrder.xs="2">Please
                enter your details below and we'll start getting your Groom Room
                online booking account setup</p></ng-container>
              <ng-template #standardBlock><p
                class="content-padding content-text" fxFlexOrder.xs="2">Do you
                need a new account? You may have been to
                the {{typeName.toLowerCase()}}
                before,
                but never registered an online account. Sign up today! </p>
              </ng-template>
              <button (click)="signUpPressed()" class="ghost" fxFlexOrder.xs="1"
                      [disabled]="clinicDeactivated" id="signUp"
                      mat-stroked-button>Sign Up
              </button>
            </div>
            <div style="height:10px"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingBlock>
    <mat-card>
      <div fxLayout="column" fxLayoutAlign="center center"
           style="min-width: 280px;min-height:300px">
        <mat-progress-spinner color="primary"
                              mode="indeterminate"></mat-progress-spinner>
        <p class="content-text">Loading</p></div>
    </mat-card>
  </ng-template>
</div>
