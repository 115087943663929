import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as Parse from 'parse';
import {CookieService} from 'ngx-cookie-service';
import {TransferClientToDifferentParams, TransferMatchResult} from '@appyvet/vetbooker-definitions/dist/transfer';
import {VbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {VbClient} from '@appyvet/vetbooker-definitions/dist/client';

@Injectable({
  providedIn: 'root'
})
export class ClientTransferService {

  transferCheckResult$ = new Subject<TransferMatchResult>();
  transferCompleteResult$ = new Subject<string>();

  constructor(private cookieService: CookieService) {
  }

  checkForMatch(clinicCode: string) {
    this.transferCheckResult$ = new Subject<TransferMatchResult>();
    Parse.Cloud.run('transferCheckForMatch', {
      clinicCode,
    }, {sessionToken: this.cookieService.get('sessionToken')}).then(result => {
      this.transferCheckResult$.next(result);
    }, error => {
      this.transferCheckResult$.error(error);
    });
  }

  completeTransfer(clinicCode: string, client: VbClient, contacts: VbContact[], patients: VbPatient[]) {
    this.transferCompleteResult$ = new Subject<string>();
    const transferData: TransferClientToDifferentParams = {
      clinicCode,
      client,
      contacts,
      patients,
    };
    Parse.Cloud.run('transferClientToDifferentClinic', transferData,
      {sessionToken: this.cookieService.get('sessionToken')}).then(result => {
      this.transferCompleteResult$.next(result);
    }, error => {
      this.transferCompleteResult$.error(error);
    });
  }
}
