import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as Parse from 'parse';
import {ParseErrorHandler} from '../parse-error-handler';
import {AuthService} from '../auth/auth.service';
import {VetBookerError} from '../interfaces/vberror';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {GetClinicRequest} from '@appyvet/vetbooker-definitions/dist/register_user';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  clinicCodeError$ = new BehaviorSubject<VetBookerError>(null);
  clinicDetails$: Subject<LoginClinicDetails>;
  clinicCode: string;


  constructor(private authService: AuthService) {
  }

  getClinicDetails(clinicCode: string, isRxCode: boolean): Observable<LoginClinicDetails> {
    this.clinicDetails$ = new Subject<LoginClinicDetails>();
    this.clinicCode = clinicCode;
    this.isLoading$.next(true);
    this.error$.next('');
    let params: GetClinicRequest;
    if (isRxCode) {
      params = {
        rxClinicCode: clinicCode,
        clinicCode: null
      };
    } else {
      params = {
        clinicCode
      };
    }
    // Specifically send no session token here in case an old session token from old site that's now invalid.
    Parse.Cloud.run('getClinicDetails', params, {sessionToken: null}).then(parseResult => {
      this.isLoading$.next(false);
      const clinicDetails: LoginClinicDetails = parseResult as LoginClinicDetails;
      this.clinicDetails$.next(clinicDetails);
      if (clinicDetails.rxId) {
        this.authService.rxId = clinicDetails.rxId;
      }
      this.authService.setClinicCode(clinicDetails.clinicCode);
      this.authService.setClinicName(clinicDetails.clinicName);
    }, error => {
      this.isLoading$.next(false);
      const parsedError: VetBookerError = ParseErrorHandler.handleParseError(error);
      this.clinicCodeError$.next(parsedError);
      this.clinicDetails$.error(parsedError);
      if (error.code) {
        this.error$.next(error.message);
      } else {
        this.error$.next('Error trying to retrieve client details, please try again');
      }
    });
    return this.clinicDetails$;
  }
}
