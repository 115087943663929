import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {BookingService} from '../../services/booking.service';
import {environment} from '../../../environments/environment';
import {AppointmentHistoryItem} from '@appyvet/vetbooker-definitions/dist/appointments';
import {CancellationReason} from '@appyvet/vetbooker-definitions/dist/cancellation_reason';
import {CancellationAppointmentItem} from '@appyvet/vetbooker-definitions/dist/bookings';

@Component({
  selector: 'app-cancel-booking-dialog',
  templateUrl: './cancel-booking-dialog.component.html',
  styleUrls: ['./cancel-booking-dialog.component.scss']
})
export class CancelBookingDialogComponent {
  appointment: AppointmentHistoryItem;
  title: string;
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  isEdit: boolean;
  bookingForm = new FormGroup({
    cancellationReason: new FormControl()
  });
  showCancellationReason: boolean;
  editText: string;
  cancellationReasons: CancellationReason[];
  isGroomRoom = environment.GROOM;
  isVets4Pets = environment.VETS_4_PETS;


  constructor(
    public dialogRef: MatDialogRef<CancelBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private bookingService: BookingService) {
    this.appointment = data.appointment;
    this.title = data.title;
    this.isEdit = data.isEdit;
    this.showCancellationReason = data.showCancellationReason;
    this.editText = data.editText;
    this.cancellationReasons = data.cancellationReasons;
  }

  cancelAppointment() {
    let cancellationItem: CancellationAppointmentItem;
      cancellationItem = {
        reasonId: null,
        reasonText: null,
        appointment: this.appointment.appointmentItem,
        objectId: this.appointment.appointmentItem.objectId
      };
       if (this.showCancellationReason) {
      cancellationItem.reasonId = this.bookingForm.get('cancellationReason').value.rxId;
      cancellationItem.reasonText = this.bookingForm.get('cancellationReason').value.reasonText;
    }
    this.error$.next(null);
    this.loading$.next(true);
    this.bookingService.cancelAppointment(cancellationItem).subscribe((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    }, error => {
      this.error$.next(error);
    }).add(() => {
      this.loading$.next(false);
    });
  }
}
