import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {AddressObject} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-transfer-summary',
  templateUrl: './transfer-summary.component.html',
  styleUrls: ['./transfer-summary.component.scss']
})
export class TransferSummaryComponent implements OnInit {

  @Output() completeWizard = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();
  @Input() selectedPets: VbPatient[];
  @Input() address: AddressObject;
  @Input() contacts: VbContact[];

  constructor() {
  }

  ngOnInit(): void {
  }

  back() {
    this.cancel.emit(true);
  }

  next() {
    this.completeWizard.emit(true);
  }
}
