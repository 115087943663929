import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../services/session-service.service';
import {AddressFormItem} from '../../components/address-lookup/address-lookup.component';
import {ClientTransferService} from '../client-transfer.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Angulartics2} from 'angulartics2';
import {AuthService} from '../../auth/auth.service';
import {BookingService} from '../../services/booking.service';
import {VbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {VbClient} from '@appyvet/vetbooker-definitions/dist/client';
import {AddressRequirements} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {AddressObject} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-client-transfer',
  templateUrl: './client-transfer.component.html',
  styleUrls: ['./client-transfer.component.scss']
})
export class ClientTransferComponent implements OnInit, OnDestroy {

  transferClinic: ClinicDetails;
  addressRequirements: AddressRequirements;
  address: AddressObject;
  private transferMatchSub: Subscription;
  CLINIC_STEP = 0;
  ADDRESS_STEP = 1;
  PETS_STEP = 2;
  CONTACTS_STEP = 3;
  FINISH_STEP = 4;
  COMPLETED_STEP = 5;
  step = this.CLINIC_STEP;
  loading: boolean;
  clinicCode: string;
  startClinic: string;
  contacts: VbContact[];
  selectedPets: VbPatient[];
  transferError: string;
  private transferSub: Subscription;
  private clientData: VbClient;
  private existingContacts: VbContact[];
  fromPets: VbPatient[];
  toPets: VbPatient[];
  hasUpcomingAppointments: boolean;
  private apptsSub: Subscription;
  selectedAddress: AddressObject;

  constructor(private sessionService: SessionService, private clientTransferService: ClientTransferService,
              private snackbar: MatSnackBar, private angulartics2: Angulartics2, private authService: AuthService,
              private bookingService: BookingService) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if(clientPatientDetails) {
        this.addressRequirements = clientPatientDetails.editSettings.addressRequirements;
        this.clinicCode = clientPatientDetails.clinicCode;
        this.startClinic = clientPatientDetails.themeSettings.customName;
        this.clientData = clientPatientDetails.clientData.client;
        this.existingContacts = clientPatientDetails.contacts;
        this.fromPets = clientPatientDetails.clientData.patients.map(existing => {
          existing.extraInfo = null;
          return existing as VbPatient;
        });
        this.address = {
          address2: clientPatientDetails.clientData.client.address2,
          county: clientPatientDetails.clientData.client.state,
          postcode: clientPatientDetails.clientData.client.postcode,
          town: clientPatientDetails.clientData.client.address3,
          address1: clientPatientDetails.clientData.client.address1
        };
        this.contacts = clientPatientDetails.contacts;
      }
    });
    this.apptsSub = this.bookingService.upcomingAppointments$.subscribe(appts => {
      if (appts && appts.length > 0) {
        this.hasUpcomingAppointments = true;
      }
    });
  }

  clinicSelected(clinic: ClinicDetails) {
    this.transferClinic = clinic;
    this.step = this.ADDRESS_STEP;
    this.angulartics2.eventTrack.next({
      action: 'User Transfer Select Clinic', properties: {
        category: 'User Transfer',
        label: this.transferClinic.practiceName
      }
    });
  }

  cancel() {
    this.step = this.CLINIC_STEP;
    this.angulartics2.eventTrack.next({
      action: 'User Transfer Cancelled', properties: {
        category: 'User Transfer',
        label: this.startClinic + ' to ' + this.transferClinic.practiceName
      }
    });
    this.transferClinic = null;
  }

  saveAddress(addressFormItem: AddressFormItem) {
    if (!addressFormItem.firstLoad) {
      this.selectedAddress = addressFormItem.address;
      this.clientData.address1 = addressFormItem.address.address1;
      this.clientData.address2 = addressFormItem.address.address2;
      this.clientData.address3 = addressFormItem.address.address3 || addressFormItem.address.town;
      this.clientData.state = addressFormItem.address.county;
      this.clientData.postcode = addressFormItem.address.postcode;
      this.angulartics2.eventTrack.next({
        action: 'User Transfer Confirmed Address', properties: {
          category: 'User Transfer',
          label: this.startClinic + ' to ' + this.transferClinic.practiceName
        }
      });
      this.loading = true;
      this.clientTransferService.checkForMatch(this.transferClinic.clinicCode);
      this.transferMatchSub = this.clientTransferService.transferCheckResult$.subscribe(result => {
        if (result) {
          this.loading = false;
          if (result.code === 101) {
            this.selectedPets = this.fromPets;
            this.contacts = this.existingContacts;
            this.step = this.FINISH_STEP;
          } else {
            this.toPets = result.patients;
            this.step = this.PETS_STEP;
          }
        }
      }, error => {
        if (error) {
          this.loading = false;
          this.snackbar.open('There has been an error, please try again or contact customer services', null,
            {duration: 5000});
        }
      });
    }
  }

  contactsConfirmed(contacts: VbContact[]) {
    this.contacts = contacts;
    this.step = this.FINISH_STEP;
    this.angulartics2.eventTrack.next({
      action: 'User Transfer Confirmed Contacts', properties: {
        category: 'User Transfer',
        label: this.startClinic + ' to ' + this.transferClinic.practiceName
      }
    });
  }

  petsConfirmed(selectedPets: VbPatient[]) {
    this.step = this.FINISH_STEP;
    this.selectedPets = selectedPets;
    this.angulartics2.eventTrack.next({
      action: 'User Transfer Patients Selected', properties: {
        category: 'User Transfer',
        label: this.startClinic + ' to ' + this.transferClinic.practiceName
      }
    });
  }

  completeWizard() {
    this.loading = true;
    this.angulartics2.eventTrack.next({
      action: 'User Transfer Start Completion', properties: {
        category: 'User Transfer',
        label: this.startClinic + ' to ' + this.transferClinic.practiceName
      }
    });
    this.clientTransferService.completeTransfer(this.transferClinic.clinicCode, this.clientData, this.contacts,
      this.selectedPets);
    this.transferSub = this.clientTransferService.transferCompleteResult$.subscribe(result => {
      if (result) {
        this.angulartics2.eventTrack.next({
          action: 'User Transfer Completed', properties: {
            category: 'User Transfer',
            label: this.startClinic + ' to ' + this.transferClinic.practiceName
          }
        });
        setTimeout(() => {
          this.authService.logout(false);
        }, 15000);
      }
    }, error => {
      if (error) {
        this.loading = false;
        this.snackbar.open('There has been an error, please try again or contact customer services', null,
          {duration: 5000});
      }
      this.angulartics2.eventTrack.next({
        action: 'User Transfer Completed Error', properties: {
          category: 'User Transfer',
          label: this.startClinic + ' to ' + this.transferClinic.practiceName
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.transferMatchSub?.unsubscribe();
    this.transferSub?.unsubscribe();
    this.apptsSub?.unsubscribe();
  }
}
