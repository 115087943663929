import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';

@Component({
  selector: 'app-resource-selector',
  template: `
    <div class="card-option-container" fxFill fxLayout="row wrap"
         fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start center">
      <div (click)="setSelectedResource(resource);" *ngFor="let resource of resources"
           [class.selected-card]="resource.objectId === selectedResource?.objectId" class="selection-card" fxFlex="45%"
           fxFlex.xs="100%"
           fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <mat-icon *ngIf="resource.objectId === selectedResource?.objectId" style="margin:5px">check_circle
          </mat-icon>
          <div *ngIf="resource.objectId !== selectedResource?.objectId" class="empty-circle"></div>
        </div>
        <p [ngStyle]="{'color':resource.objectId === selectedResource?.objectId ? '#FFFFFF' : ''}"
           class="card-header-text">{{resource.name}}</p>
      </div>
    </div>
  `,
  styleUrls: ['./resource-selector.component.scss', '../patient-selector/patient-selector.scss']
})
export class ResourceSelectorComponent implements OnInit {
  @Input() resources: Resource[];
  @Input() selectedResource: Resource;
  @Output() selectedResourceChange = new EventEmitter<Resource>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setSelectedResource(selectedResource: Resource) {
    this.selectedResourceChange.emit(selectedResource)
  }
}
