import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {differenceInCalendarDays} from 'date-fns';
import {Vaccination, VbPatientReminder} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-vaccinations',
  template: `
    <mat-card class="detail-card" fxLayout="column" fxLayoutAlign="start start">
      <!--      <ng-container *ngIf="isOverdue || isDue">-->
      <!--        <div fxLayout="row" fxLayoutAlign="center center" class="full-width-form-item" style="margin-bottom: 20px">-->
      <!--          <button mat-stroked-button color="warn"><span>Vaccination overdue, book </span><span fxHide.xs>now</span>-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </ng-container>-->
      <ng-container *ngIf="vaccinations?.length>0 ; else emptyVaccinations">
        <div fxLayout="column" fxLayoutGap="10px">
          <div *ngFor="let vaccination of vaccinations" fxFlexFill fxLayout="row" fxLayout.lt-md="column"
               fxLayoutAlign.lt-md="center center">
            <p class="vaccine-header item-title">{{vaccination.date | dfnsFormat : 'do MMM yyyy' }}</p>
            <p class="title-content">{{vaccination.vacType}}</p></div>
        </div>
      </ng-container>
      <ng-template #emptyVaccinations><p class="title-content">No vaccinations found for {{name}}</p>
      </ng-template>
    </mat-card>
  `,
  styleUrls: ['../patient-detail/patient-detail.component.scss']
})
export class VaccinationsComponent implements OnInit, OnChanges {
  @Input() vaccinations: Vaccination[];
  @Input() reminders: VbPatientReminder[];
  @Input() name: string;
  isDue: boolean;
  isOverdue: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.updateView();
  }

  updateView() {
    if (!this.reminders || this.reminders.length === 0) {
      this.isOverdue = false;
      this.isDue = false;
    }
    this.reminders.forEach(reminder => {
      if (reminder.isVaccine && !this.isOverdue) {
        const daysUntilDue = differenceInCalendarDays(reminder.date, new Date());
        this.isDue = daysUntilDue >= 0 && daysUntilDue <= 14;
        this.isOverdue = daysUntilDue < 0;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateView();
  }
}
