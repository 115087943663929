import {Component, OnInit} from '@angular/core';
import {SignupService} from '../signup.service';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Angulartics2} from 'angulartics2';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  verificationForm: FormGroup;
  accountValidationMessages = this.signupService.accountValidationMessages;
  resentEmail = false;
  isGroomRoom: boolean;
  clinicDetails$ = new BehaviorSubject<LoginClinicDetails>(null);
  currentUser$ = new BehaviorSubject<CreatedUserResponse>(null);
  loading$: BehaviorSubject<boolean>;
  private clinicName: string;

  constructor(private signupService: SignupService, private activatedRoute: ActivatedRoute,
              private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.loading$ = this.signupService.loading$;
    this.currentUser$ = this.signupService.foundUser$;
    this.verificationForm = new FormGroup({
      emailCode: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8),
      ]),
    },);
    this.activatedRoute.data
      .subscribe((data: { clinicDetails: LoginClinicDetails }) => {
        this.clinicName = data.clinicDetails.clinicName;
        this.clinicDetails$.next(data.clinicDetails);
      });
  }

  requestNewEmailCode() {
    this.resentEmail = true;
    this.signupService.resendEmailVerification();
    this.angulartics2.eventTrack.next({
      action: 'Verify Email Resend Code',
      properties: {
        category: 'Email Verification',
        label: this.clinicName
      }
    });
  }

  verifyEmail() {
    this.signupService.verifyEmailWithCode(this.verificationForm.get('emailCode').value);
    this.angulartics2.eventTrack.next({
      action: 'Verify Email Enter Code',
      properties: {
        category: 'Email Verification',
        label: this.clinicName
      }
    });
  }
}
