import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {PatientService} from "./patient.service";
import {first, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AddPatientGuard implements CanActivate {

  constructor(private patientService: PatientService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.patientService.canAddPatient$.pipe(first(), map(result => {
      if (result) {
        return true;
      } else {
        this.router.navigateByUrl('/pets');
      }
    }))
  }

}
