<app-groom-welfare-message></app-groom-welfare-message>
<div fxLayout="column" class="padding-10">
  <ng-container *ngIf="showMarketingConsent">
    <h2 class="big-header">Marketing Settings</h2>
    <app-combined-marketing class=" landing-card"></app-combined-marketing>
  </ng-container>
  <ng-container *ngIf="!disableQuickBook && canBook">
    <div fxLayout="column">
      <h2 class="big-header">Quick Book</h2>
      <app-next-available-card class=" landing-card"></app-next-available-card>
    </div>
  </ng-container>
  <div class=" landing-card">
    <h2 class="big-header">My Details</h2>
    <app-client-summary-card></app-client-summary-card>
  </div>
  <ng-container *ngIf="canBook">
    <div>
      <h2 class="big-header">Upcoming appointment</h2>
      <mat-card class="no-margin no-padding landing-card">
        <div class="primary-background upcoming-toolbar" fxLayout="row"
             fxLayoutAlign="space-between center">
          <ng-container *ngIf="upcomingAppointment; else emptyHeaderBlock">
            <p class="date-header-text">{{upcomingAppointment.date | dfnsFormat:
              'MMM'}}</p>
            <p
              class="date-header-text">{{upcomingAppointment.date | dfnsFormat: 'EEE do'}}</p>
            <p
              class="date-header-text">{{upcomingAppointment.date | dfnsFormat: 'HH:mm'}}</p>
          </ng-container>
          <ng-template #emptyHeaderBlock>
            <div fxLayout="row" fxLayoutAlign.gt-xs="space-between center"
                 class="full-width-form-item padding-20">
              <p class="date-header-text">No upcoming appointments</p>
              <button mat-raised-button [routerLink]="'/booking'" color="accent"
                      [style.background-color]="isGroomRoom?'#FFFFFF' : null"
                      [style.color]="isGroomRoom?'rgba(var(--palette-accent-500))' : null"
                      style="width: 120px">
                Book
              </button>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="upcomingAppointment">
          <mat-card-content class="padding-20">
            <div fxLayout="row">
              <p class="item-title" style="color: rgba(0,0,0,0.67)"><b>Who</b>
              </p>
              <p class="content-text">{{upcomingAppointment.patientDetails}}</p>
            </div>
            <div fxLayout="row">
              <p class="item-title" style="color: rgba(0,0,0,0.67)"><b>Where</b>
              </p>
              <p class="content-text">{{upcomingAppointment.resourceName}}</p>
            </div>
            <!--          <div fxLayout="row">-->
            <!--            <p class="item-title"><b>Appointment</b></p>-->
            <!--            <p-->
            <!--              class="content-text">{{upcomingAppointment}}</p>-->
            <!--          </div>-->
          </mat-card-content>
        </ng-container>
      </mat-card>
      <div fxLayout="row" fxLayoutAlign="end center"
           class="full-width-xs landing-card">
        <button mat-button [routerLink]="'/bookingHistory'" class="view-button"
                (click)="viewAppointmentsClicked()">
          View Appointments >
        </button>
      </div>
    </div>
  </ng-container>
  <div fxLayout="column">
    <h2 class="big-header">My Pets</h2>
    <app-pets-carousel class=" landing-card"></app-pets-carousel>
    <div fxLayout="row" fxLayoutAlign="end center"
         class="full-width-xs landing-card">
      <button mat-button [routerLink]="'/pets'" class="view-button"
              (click)="viewAllPetsSelected()">
        View All Pets >
      </button>
    </div>
  </div>
  <ng-container *ngIf="!hideReminders">
    <div fxLayout="column">
      <h2 class="big-header">Upcoming Patient Reminders</h2>
      <app-patient-reminder-slots
        class=" landing-card"></app-patient-reminder-slots>
    </div>
  </ng-container>
</div>

