<h2 *ngIf="title" class="accent-text-color" mat-dialog-title>{{title}}</h2>
<div mat-dialog-content style="overflow:hidden ">
  <p class="mat-body-2">{{message}}</p>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
     fxLayoutGap="20px" mat-dialog-actions>
  <button [mat-dialog-close]="false" mat-stroked-button>{{cancelText}}</button>
  <button [mat-dialog-close]="true" mat-raised-button
          color="primary">{{confirmText}}</button>
</div>
