import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RegistrationRoutingModule} from './registration-routing.module';
import {RegistrationResolver} from './registration-resolver';
import {RegistrationComponent} from './registration/registration.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {ReactiveFormsModule} from '@angular/forms';
import {ClientDetailsModule} from '../client-details/client-details.module';
import {PatientsModule} from '../patients/patients.module';
import {ManageRegistrationPatientsComponent} from './manage-registration-patients/manage-registration-patients.component';
import {AdditionalRegistrationInfoComponent} from './additional-registration-info/additional-registration-info.component';
import {RegistrationContactEntriesComponent} from './registration-contact-entries/registration-contact-entries.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {RegistrationSummaryComponent} from './registration-summary/registration-summary.component';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [RegistrationComponent, ManageRegistrationPatientsComponent, AdditionalRegistrationInfoComponent,
                 RegistrationContactEntriesComponent, RegistrationSummaryComponent],
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    FlexModule,
    MatStepperModule,
    ReactiveFormsModule,
    ClientDetailsModule,
    PatientsModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    CommonComponentsModule,
    MatProgressSpinnerModule,
    ExtendedModule,
  ], providers: [RegistrationResolver]
})
export class RegistrationModule {
}
