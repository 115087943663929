<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign.gt-xs="center center">
  <div fxLayout="column" style="max-width:500px; min-width:300px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="content-text">House Number/Name</p>
      <p>{{address.address1}}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="content-text">Address Line 2</p>
      <p>{{address.address2}}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="content-text">Town</p>
      <p>{{address.address3 ||address.town}}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="content-text">County</p>
      <p>{{address.county}}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p class="content-text">Postcode</p>
      <p>{{address.postcode}}</p>
    </div>
    <ng-container *ngFor="let contact of contacts;">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="content-text">{{contact.displayName}}</p>
        <p>{{contact.number}}</p>
      </div>
    </ng-container>
    <ng-container *ngFor="let pet of selectedPets; index as index">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="content-text">Pet {{index + 1}}</p>
        <p>{{pet.name}}</p>
      </div>
    </ng-container>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center"
       class="full-width-form-item">
    <button mat-stroked-button (click)="back()">Back</button>
    <button mat-raised-button color="primary" (click)="next()">Next</button>
  </div>
</div>
