import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MarketingQuestion, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';

@Component({
  selector: 'app-client-marketing',
  templateUrl: './client-marketing.component.html',
  styleUrls: ['./client-marketing.component.scss']
})
export class ClientMarketingComponent implements OnInit {
  @Input() embedded: boolean;
  @Input() preferences: MarketingResponse[];
  @Input() questions: MarketingQuestion[];
  @Input() marketingQuestionsIntro: string;
  @Input() loading: boolean;
  @Output() preferencesSaved = new EventEmitter<MarketingResponse[]>();
  @Output() preferencesChange = new EventEmitter<MarketingResponse[]>();
  isVets4Pets = environment.VETS_4_PETS;

  constructor() {
  }

  ngOnInit(): void {
    if (this.preferences?.length === 0) {
      this.preferences = this.questions.map(question => {
        return Object.assign({allowed: false}, question);
      });
    }
  }

  updateMarketingPreferences() {
    this.preferencesSaved.emit(this.preferences);
  }

  preferenceChanged(selectedPreference: MarketingResponse) {
    this.preferences = this.preferences.map(preference => {
      preference.allowed = preference.questionId === selectedPreference.questionId;
      return preference;
    });
    this.preferencesChange.emit(this.preferences);
  }
}
