<div class="padding-10" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px">
  <h1 class="section-header">History for {{patient.name}}</h1>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <button (click)="skip(-10)" [disabled]="page === 1" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="skip(10)" [disabled]="history?.length <10" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div *ngFor="let visit of history" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">{{getDate(visit.date)}}</h3>
    <mat-card class="detail-card" fxLayout="column">
      <mat-card-content fxLayout="column">
        <p class="visit-title">Visit at {{visit.location}} with {{visit.vet}}</p>
        <ng-container *ngIf="visit.history">
          <div fxLayout="column" fxLayoutAlign="space-between start">
            <p class="section-text">Notes</p>
            <p [innerHTML]="visit.history"></p>
            <mat-divider inset="true" style="margin-bottom: 10px" vertical="false"></mat-divider>
          </div>
        </ng-container>
        <ng-container *ngFor="let procedure of visit.procedures">
          <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" style="width:100%">
            <div fxLayout="row" fxLayoutAlign="space-between start" style="width:100%">
              <p class="section-text">{{procedure.name}}</p>
              <p *ngIf="!procedure.items || procedure.items.length===0">
                {{getPrice(procedure.totalPrice)}}</p>
            </div>
            <div *ngIf="procedure.items && procedure.items.length>0" fxLayout="column" style="width:100%">
              <ng-container *ngFor="let item of procedure.items">
                <div fxLayout="row" fxLayout.xs="column"
                     fxLayoutGap.gt-xs="20px">
                  <div fxFlex="grow" fxLayout="row"
                       fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <p>{{item.itemNumber ? item.itemNumber : procedure.itemNumber}}
                      x {{item.itemName}} <span *ngIf="item.injectable">by Injection</span>
                    </p>
                    <p fxHide.gt-xs>{{getPrice(item.price)}}</p>
                  </div>
                  <div class="margin-bottom-xs full-width-xs button-margin"
                       fxLayout="row" fxLayoutAlign="end start"
                       fxLayoutGap="10px">
                    <button (click)="showLabelDialog(item)"
                            *ngIf="item.itemLabel && item.itemLabel.length>0"
                            color="primary" mat-stroked-button>Label
                    </button>
                    <button
                      *ngIf="item.type===1 && !item.injectable && historyPrescriptionsActive && item.itemNumber>0"
                      [queryParams]="{patientNumber:patient.patientNumber, itemName:item.itemName}"
                      [routerLink]="'/order'" color="accent" mat-raised-button>
                      Reorder
                    </button>
                  </div>
                  <div fxHide.xs fxLayout="row" fxLayoutAlign="end center"
                       fxShow style="min-width:100px">
                    <p>{{getPrice(item.price)}}</p></div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <button (click)="skip(-10)" [disabled]="page === 1" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="skip(10)" [disabled]="history?.length <10" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
