import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoginService} from '../services/login-service.service';
import {AuthService} from './auth.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class ClinicCodeGuard implements CanActivate {
  constructor(private authService: AuthService, private loginService: LoginService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const storedClinicCode = this.authService.getClinicCode();
    if (storedClinicCode) {
      this.router.navigate(['/login'], {queryParams: {clinicCode: storedClinicCode}});
      return true;
    } else {
      return true;
    }
  }
}
