import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SigninComponent} from './signin/signin.component';
import {SignupComponent} from './signup/signup/signup.component';
import {EmailLinkVerificationComponent} from './email-link-verification/email-link-verification.component';
import {LoginResolver} from './login-resolver';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {GuestGuard} from '../auth/guest.guard';
import {EmailLinkSignupComponent} from './signup/password/email-link-signup.component';


const routes: Routes = [
  {
    path: 'emailLink', component: EmailLinkVerificationComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'emailRegistrationLink', component: EmailLinkSignupComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'resetPassword', component: ResetPasswordComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'login', component: LoginComponent, children: [
      {
        path: '',
        component: SignupComponent,
        outlet: 'signup',
        pathMatch: 'full',
        resolve: {clinicDetails: LoginResolver}
      },
      {
        path: '',
        component: SigninComponent,
        outlet: 'signin',
        pathMatch: 'full',
        resolve: {clinicDetails: LoginResolver}
      },
    ],
    canActivate: [GuestGuard], resolve: {clinicDetails: LoginResolver}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
