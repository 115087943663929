<ng-container *ngIf="isGroomRoom || isVets4Pets; else clinicCodeEntryBlock">
  <div class="padding-10-not-bottom" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="center center" class="margin-gt-xs">
      <div [style.background]="getBackgroundStyle()" class="selector-logo"
           fxLayoutAlign="center center"></div>
      <ng-container *ngIf="isVets4Pets; else groomBlock">
        <h1 class="big-header" style="color: white">Search for a vet
          practice</h1>
        <p class="content-text" style="color: white">To help us find a vet
          practice
          for you please
          search
          via your address or device location.</p>
        <app-groom-selector class="full-width-form-item"></app-groom-selector>
      </ng-container>
      <ng-template #groomBlock>
        <h1 class="landing-title">Find your local grooming salon</h1>
        <p class="content-text" style="color: white">To help us find your
          nearest Groom Room salon or to check appointment availability before
          registering, please search using your address or device location</p>
        <app-groom-selector class="full-width-form-item"></app-groom-selector>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #clinicCodeEntryBlock>
  <div fxLayoutAlign="center center">
    <mat-card class="centered-card">
      <mat-card-content>
        <h2 class="title-header">Please enter clinic code</h2>
        <p class="content-text">In order to identify your clinic, VetBooker
          needs you to enter your clinic code. Your
          practice will be able to provide this to you. Alternatively, following
          a direct link from their website or an
          email may prevent the need to enter this code.</p>
        <div fxLayout="row" fxLayout.xs="column"
             fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline">
            <mat-label>Clinic Code</mat-label>
            <input #clinicCodeInput matInput>
          </mat-form-field>
          <ng-container *ngIf="loading$|async; else buttonBlock">
            <mat-progress-spinner color="primary" diameter="40"
                                  mode="indeterminate"></mat-progress-spinner>
          </ng-container>
          <ng-template #buttonBlock>
            <button (click)="onNext()" color="primary" mat-raised-button>Next
            </button>
          </ng-template>
        </div>
        <p class="accent-text-color content-text">{{error$|async}}</p>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
