<div fxLayout="column" style="min-width: 200px">
  <ng-container *ngIf="html; else messageBlock">
    <div [innerHTML]="html"></div>
  </ng-container>
  <ng-template #messageBlock>
    <h2 *ngIf="title" class="accent-text-color" mat-dialog-title>{{title}}</h2>
    <div fxLayout="row" fxLayoutAlign="center center" mat-dialog-content
         style="overflow:hidden ">
      <p class="content-text">{{message}}</p>
    </div>
  </ng-template>
  <div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
    <button [mat-dialog-close]="true" color="primary"
            mat-button>{{confirmationText}}</button>
  </div>
</div>
