<ng-container *ngIf="!(error$ | async); else errorBlock">
  <ng-container *ngIf="screenWidth$ | async as screenWidth">
    <mat-sidenav-container *ngIf="loaded$ | async as loaded; else elseBlock"
                           [ngClass]="{'login-background':!isLoggedIn &&
                           !whiteBackground}" class="mat-typography" fxFlexFill>
      <mat-sidenav #sidenav [mode]="screenWidth > 1260 ? 'side' : 'over'"
                   [opened]="!hideNavigation && isLoggedIn && (termsAgreed$ |
                 async) &&screenWidth > 1260" role="navigation">
        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center"
             [ngClass]="{'background-color':!whiteBackground}">
          <p class="content-text"
             style="padding-left:20px; margin:0">{{clientPatientDetails?.themeSettings?.customName || defaultName}}</p>
        </div>
        <mat-nav-list>
          <!--Here to avoid default list selection of first item-->
          <a mat-list-item routerLink="/" style="height: 0;"></a>
          <div *ngFor="let item of userRoutes">
            <a (click)="sidenav.mode === 'over' ? sidenav.close() : null"
               [routerLink]="[item.route]" mat-list-item
               routerLinkActive="list-item-active">
              <mat-icon color="primary" matListIcon
                        style="margin-right: 20px"> {{ item.icon }} </mat-icon>
              <h4 style="margin:0">{{item.title}}</h4>
            </a>
            <ng-container *ngIf="item.route === 'pets'">
              <ng-container
                *ngIf="item.children as children; else loadingBlock">
                <a (click)="sidenav.mode === 'over' ? sidenav.close() : null;
                 itemClicked(child)" *ngFor="let child of children"
                   [routerLink]="[child.route]" mat-list-item
                   routerLinkActive="list-item-active">
                  <mat-icon color="primary" matListIcon
                            style="margin-right: 20px"> {{ child.icon }} </mat-icon>
                  <p class="no-margin">{{child.title}}</p>
                </a></ng-container>
              <ng-template #loadingBlock>
                <div fxLayout="row" fxLayoutAlign="start center"
                     fxLayoutGap="20px" style="margin-left:20px">
                  <mat-progress-spinner color="primary" diameter="30"
                                        mode="indeterminate"></mat-progress-spinner>
                  <p>Retrieving pet details</p>
                </div>
              </ng-template>
              <a (click)="sidenav.mode === 'over' ? sidenav.close() : null"
                 *ngIf="item.title.toLowerCase().indexOf('pet')!==-1 && canAddPet"
                 [routerLink]="'/pets/new'" mat-list-item
                 routerLinkActive="list-item-active">
                <mat-icon color="primary" matListIcon
                          style="margin-right: 20px">
                  add
                </mat-icon>
                <p class="no-margin">Add pet</p>
              </a></ng-container>
          </div>
          <ng-container *ngIf="showSupport">
            <a (click)="sidenav.mode === 'over' ? sidenav.close() : null"
               href="mailto:support@vetbooker.com" target="_blank" mat-list-item
               routerLinkActive="list-item-active">
              <mat-icon color="primary" matListIcon style="margin-right: 20px">
                support_agent
              </mat-icon>
              <h4 style="margin:0">Technical support</h4>
            </a></ng-container>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <div fxLayout="column" fxFlexFill>
          <div *ngIf="isLoggedIn" class="toolbar"
               [ngClass]="{'sticky':!hideNavigation && (termsAgreed$ | async), 'background-color':!whiteBackground}">
            <div fxLayout="row" fxLayoutAlign="center center">
              <button (click)="sidenav.toggle()" *ngIf="screenWidth<=1260"
                      mat-icon-button>
                <mat-icon>menu</mat-icon>
              </button>
              <ng-container *ngIf="screenWidth>=900">
                <app-breadcrumbs></app-breadcrumbs>
              </ng-container>
              <img [src]="menuLogo" height="48px"/>
              <div fxLayout="row" fxFlex fxLayoutAlign="end center">
                <button (click)="logout()" mat-button>
                  <ng-container *ngIf="whiteToolbar; else whiteBlock">
                    <mat-icon color="primary">exit_to_app</mat-icon>
                  </ng-container>
                  <ng-template #whiteBlock>
                    <mat-icon style="color:white">exit_to_app</mat-icon>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
          <div [ngClass]="{'main-padding':!hideNavigation && isLoggedIn}"
               style="height:100%">
            <ng-container *ngIf="routeIsLoading$ | async; else contentBlock">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                <mat-card class="centered-card">
                  <div fxFlexFill fxLayout="column"
                       fxLayoutAlign="center center">
                    <mat-progress-spinner color="primary"
                                          mode="indeterminate"></mat-progress-spinner>
                    <p class="mat-body-2">Getting data from server</p>
                  </div>
                </mat-card>
              </div>
            </ng-container>
            <ng-template #contentBlock>
              <router-outlet></router-outlet>
            </ng-template>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <ng-template #elseBlock>
      <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-spinner color="primary"
                              mode="indeterminate"></mat-progress-spinner>
        <p class="mat-body-2">Getting data from server</p>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template #errorBlock>
  <app-error fxFlexFill></app-error>
</ng-template>

