import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductTypeSelectorComponent} from './product-type-selector/product-type-selector.component';
import {FlexModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {ProductOrderComponent} from "./product-order/product-order.component";
import {MatCardModule} from "@angular/material/card";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {CommonComponentsModule} from "../components/common-components/common-components.module";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [ProductTypeSelectorComponent, ProductOrderComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonComponentsModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ]
})
export class ProductOrderModule {
}
