import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welfare-guidance',
  templateUrl: './welfare-guidance.component.html',
  styleUrls: ['./welfare-guidance.component.scss']
})
export class WelfareGuidanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
