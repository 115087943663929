<h2 class="title primary-text-color" mat-dialog-title>New to {{brandName}}?</h2>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center"
         fxLayout.xs="column">
      <ng-container *ngIf="canRegister; else disabledRegistrationBlock">
        <p class="mat-body-1 primary-text-color"
           [ngStyle.gt-xs]="'font-size:20px'">Thank you for choosing to
          register with us. {{isGroomRoom ?
            'Please take a few moments to tell us about your dog(s)' :
            'Tell us about you and your pets'}} to finish the registration
          process.</p>
        <div>
          <button [mat-dialog-close]="true"
                  [queryParams]="{clinicCode: this.clinicCode}" color="primary"
                  routerLink="/register" mat-raised-button>
            Register
          </button>
        </div>
      </ng-container>
      <ng-template #disabledRegistrationBlock>
        <p class="mat-body-1 primary-text-color"
           [ngStyle.gt-xs]="'font-size:20px'"> Unfortunately we are not taking
          new registrations at this time. Please try
          again at a later date.</p>
      </ng-template>
    </div>
    <p class="mat-body-1 primary-text-color" [ngStyle.gt-xs]="'font-size:24px'"
       [ngStyle.xs]="'font-size:18px'"><b>Already
      {{isGroomRoom ? 'registered with us?' : 'a client?'}}</b></p>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center"
         fxLayout.xs="column">
      <ng-container *ngIf="!isGroomRoom; else groomBlock">
        <p class="mat-body-1 primary-text-color"
           [ngStyle.gt-xs]="'font-size:20px'">We were
          unable to match
          those
          details with any
          existing client record on {{clinicName}}'s
          system. If you have been to the clinic before and are an existing
          client, please enter different details and try
          again.<span *ngIf="canRegister"> Alternatively to register as a new
            client, click the Register button to proceed.</span></p>
      </ng-container>
      <ng-template #groomBlock>
        <p class="mat-body-1 primary-text-color"
           [ngStyle.gt-xs]="'font-size:20px'">Whoops! Unfortunately we were
          unable to match those details with any existing customer record in
          our {{clinicName}}'s salon system. If you have been to this Groom Room
          before, please try again and
          check that all your details are correct and up to date. Alternatively,
          to register a new account, please
          click the register button to proceed.</p></ng-template>
      <div style="margin-bottom:20px">
        <button [mat-dialog-close]="true" color="primary" mat-stroked-button>Try
          again
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>

