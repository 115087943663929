<div fxLayout="column">
  <ng-container *ngFor="let pet of pets; let i = index">
    <div fxLayout="row" fxLayoutAlign="space-between center"
         fxLayoutGap.gt-xs="20px">
      <div fxFlex.gt-xs="80" fxLayout="row" fxLayout.xs="column"
           fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start">
        <p class="name-text">{{ pet.patient?.name || 'pet ' + (i + 1) }}</p>
        <p
          class="content-text">{{pet.patient?.breedItem?.name || 'No breed selected'}}</p>
        <p class="content-text" [ngStyle]="{'color' : pet.form.valid ?
          'green' :
          'rgb(var(--palette-warn-500))'}">{{pet.form.valid ?
          'Completed' : '! Incomplete'}}</p>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxFlex.gt-xs="20">
        <button (click)="editPet(i)" class="grey-icon" mat-icon-button
                type="button">
          <mat-icon>edit</mat-icon>
        </button>
        <button (click)="removePet(pet, i)" class="grey-icon" mat-icon-button
                type="button">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <div fxLayout="row" fxLayoutAlign="end center" style="margin-bottom:10px">
    <button (click)="addPet()" color="accent" mat-raised-button type="button"
            style="width:140px">
      <mat-icon>add</mat-icon>
      Add Pet
    </button>
  </div>
</div>
