import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuestGuard} from "../auth/guest.guard";
import {RegistrationComponent} from "./registration/registration.component";
import {RegistrationResolver} from "./registration-resolver";


const routes: Routes = [{
  path: "register",
  component: RegistrationComponent,
  canActivate: [GuestGuard],
  resolve: {registrationSettings: RegistrationResolver}
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule {
}
