import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ErrorDialogComponent} from '../../../components/shared/error-dialog/error-dialog.component';
import {ClientService} from '../../client.service';
import {environment} from '../../../../environments/environment';
import {BaseVbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
  addContactForm: FormGroup;
  contactValidationMessages = this.clientService.CONTACT_VALIDATION_MESSAGES;
  @Input() reminderConsentEnabled: boolean;
  @Input() reminderConsentText: string;
  @Input() mobileRegexp: string;
  @Input() phoneRegexp: string;
  @Output() addingCompleted = new EventEmitter<boolean>();
  isGroomRoom = environment.GROOM;
  loading: boolean;

  constructor(private clientService: ClientService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.addContactForm = new FormGroup({
      number: new FormControl('', [
        Validators.required, this.clientService.getNumberValidator(3)
      ]),
      name: new FormControl('', [
        Validators.required,
      ]),
      type: new FormControl('3', [
        Validators.required,
      ])
    });
    if (this.reminderConsentEnabled) {
      this.addContactForm.addControl('reminder', new FormControl(false));
    }
  }

  addNewContact(): void {
    this.loading = true;
    const newContact: BaseVbContact = {
      type: this.addContactForm.get('type').value,
      typeCode: parseInt(this.addContactForm.get('type').value, 10),
      displayName: this.addContactForm.get('name').value,
      number: this.addContactForm.get('number').value,
      optIn: this.addContactForm.get('reminder')?.value || false
    };
    this.clientService.addContact$(newContact).subscribe(result => {
      if (result) {
        this.loading = false;
        this.addingCompleted.emit(true);
      }
    }, error => {
      this.loading = false;
      const errorDialogConfig = new MatDialogConfig();
      errorDialogConfig.data = {
        title: 'Error deleting contact',
        error
      };
      this.dialog.open(ErrorDialogComponent, errorDialogConfig);
    });
  }

  cancelAddingContact(): void {
    this.addingCompleted.emit(true);
  }

  contactTypeChanged(value: string) {
    this.addContactForm.get('number')
      .setValidators([Validators.required, this.clientService.getNumberValidator(parseInt(value, 10))]);
    this.addContactForm.get('number').updateValueAndValidity();
  }
}
