import {Component, Input, OnInit} from '@angular/core';

export interface RegistrationSummaryItem {
  name: string;
  value: string;
}

export interface RegistrationSummaryCategories {
  name: string;
  items: RegistrationSummaryItem[];
}

@Component({
  selector: 'app-registration-summary',
  templateUrl: './registration-summary.component.html',
  styleUrls: ['./registration-summary.component.scss']
})
export class RegistrationSummaryComponent implements OnInit {

  @Input() registrationSummaryCategories: RegistrationSummaryCategories[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
