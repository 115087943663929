import {Injectable} from '@angular/core';
import {SessionService} from '../services/session-service.service';
import * as Parse from 'parse';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Angulartics2} from 'angulartics2';
import {ClientName} from '../components/name-entry/name-entry.component';
import {CookieService} from 'ngx-cookie-service';
import {ValidatorFn, Validators} from '@angular/forms';
import {VetBookerError} from '../interfaces/vberror';
import {VbClient} from '@appyvet/vetbooker-definitions/definitions/interfaces/client';
import {AddressObject} from '@appyvet/vetbooker-definitions/dist/register_user';
import {UpdateAddressClient, UpdateNameClient} from '@appyvet/vetbooker-definitions/dist/client';
import {BaseVbContact, VbContact} from '@appyvet/vetbooker-definitions/dist/contacts_model';
import {ParseErrorHandler} from '../parse-error-handler';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  CONTACT_VALIDATION_MESSAGES = {
    number: [
      {type: 'required', message: 'You must enter contact information'},
      {type: 'pattern', message: 'Oops, this doesn\'t look like a valid number'},
      {type: 'email', message: 'Oops, this doesn\'t look like a valid email address'},
    ],
    name: [
      {type: 'required', message: 'You must enter a name for this contact'},
    ],
  };
  contacts$ = new BehaviorSubject<VbContact[]>(null);
  client$ = new BehaviorSubject<VbClient>(null);
  private client: VbClient;
  private contacts: VbContact[];
  private clinicCode: string;
  clinicName: string;
  marketingAllowed: boolean;
  private mobileRegexp: string;
  private phoneRegexp: string;
  contactEmail: string;

  constructor(private sessionService: SessionService, private angulartics2: Angulartics2,
              private cookieService: CookieService) {
    sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        if (clientPatientDetails.marketingSettings) {
          this.marketingAllowed = clientPatientDetails.marketingSettings.disableMarketing;
        } else {
          this.marketingAllowed = false;
        }
        this.clinicName = clientPatientDetails.themeSettings?.customName || 'VetBooker';
        this.client = clientPatientDetails.clientData.client;
        this.clinicCode = clientPatientDetails.clinicCode;
        this.contacts = clientPatientDetails.contacts;
        this.mobileRegexp = clientPatientDetails.country.mobileRegex;
        this.phoneRegexp = clientPatientDetails.country.phoneRegex;
        this.contactEmail = clientPatientDetails.userDetails.contactEmail;
        this.contacts$.next(this.contacts);
        this.client$.next(this.client);
      }
    });
  }

  getNumberValidator(type: number): ValidatorFn {
    if (type === 1) {
      return Validators.email;
    } else if (type === 0) {
      return Validators.pattern(this.phoneRegexp);
    } else if (type === 3) {
      return Validators.pattern(this.mobileRegexp);
    }
  }

  updateAddress$(newAddress: AddressObject): Observable<boolean> {
    const saveClientRequest = new Subject<boolean>();
    const originalClient = Object.assign({}, this.client);
    const updateClient: UpdateAddressClient = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      address3: newAddress.town,
      state: newAddress.county,
      postcode: newAddress.postcode,
      previousData: originalClient,
    };
    Parse.Cloud.run('saveClientAddress', updateClient, {sessionToken: this.cookieService.get('sessionToken')})
      .then(() => {
        this.client.address1 = newAddress.address1;
        this.client.address2 = newAddress.address2;
        this.client.address3 = newAddress.town;
        this.client.postcode = newAddress.postcode.toUpperCase();
        this.client.state = newAddress.county;
        this.client$.next(this.client);
        saveClientRequest.next(true);
        this.angulartics2.eventTrack.next({
          action: 'Edit Address', properties: {
            category: 'Client Details',
            label: this.clinicName
          }
        });
      }, error => saveClientRequest.error(error));
    return saveClientRequest;
  }

  updateName$(clientName: ClientName): Observable<boolean> {
    const saveClientRequest = new Subject<boolean>();
    const originalClient = Object.assign({}, this.client);
    const updateClient: UpdateNameClient = {
      title: clientName.title,
      firstName: clientName.firstName,
      surname: clientName.surname,
      previousData: originalClient,
    };
    Parse.Cloud.run('saveClientName', updateClient, {sessionToken: this.cookieService.get('sessionToken')}).then(() => {
      this.client.title = clientName.title;
      this.client.firstName = clientName.firstName;
      this.client.surname = clientName.surname;
      this.client$.next(this.client);
      saveClientRequest.next(true);
      this.angulartics2.eventTrack.next({
        action: 'Edit Name', properties: {
          category: 'Client Details',
          label: this.clinicName
        }
      });
    }, error => saveClientRequest.error(error));
    return saveClientRequest;
  }


  addContact(newContact: VbContact) {
    this.contacts.push(newContact);
    this.contacts$.next(this.contacts);
  }

  updateContacts(updatedContact: VbContact, isPreferred: boolean) {
    this.contacts.forEach((contact, index) => {
      if (updatedContact.isPreferred) {
        this.contacts[index].isPreferred = false;
      }
      if (contact.id === updatedContact.id) {
        if (isPreferred) {
          updatedContact.isPreferred = true;
        }
        updatedContact.allowEmail = updatedContact.optIn;
        updatedContact.allowAMM = updatedContact.optIn;
        this.contacts[index] = updatedContact;
      }
    });
    this.contacts$.next(this.contacts);
  }

  removeContact(deletedContact: VbContact) {
    this.contacts.forEach((contact, index) => {
      if (contact.id === deletedContact.id) {
        this.contacts.splice(index, 1);
      }
    });
    this.contacts$.next(this.contacts);
  }

  async setPreferredContact(contact: VbContact) {
    try {
      await Parse.Cloud.run('setPreferredContactEntry', {
        contactEntry: contact
      }, {sessionToken: this.cookieService.get('sessionToken')});
      contact.isPreferred = true;
      this.updateContacts(contact, true);
    } catch (e) {
      throw ParseErrorHandler.handleParseError(e);
    }
  }

  updateContact$(contact: VbContact): Observable<boolean> {
    const updateContactRequest$ = new Subject<boolean>();
    Parse.Cloud.run('saveContactEntry', {
      contactEntry: contact
    }, {sessionToken: this.cookieService.get('sessionToken')}).then(() => {
      this.updateContacts(contact, false);
      this.angulartics2.eventTrack.next({
        action: 'Edit Contact', properties: {
          category: 'Client Details',
          label: this.clinicName
        }
      });
      updateContactRequest$.next(true);
    }, error => {
      updateContactRequest$.error(error);
    });
    return updateContactRequest$;
  }

  addContact$(newContact: BaseVbContact): Observable<boolean> {
    const addContactRequest$ = new Subject<boolean>();
    Parse.Cloud.run('addContactEntry', newContact, {sessionToken: this.cookieService.get('sessionToken')})
      .then(contact => {
        this.addContact(contact);
        addContactRequest$.next(true);
        let contactType = 'Home';
        if (contact.typeCode === 1) {
          contactType = 'Email';
        }
        if (contact.typeCode === 3) {
          contactType = 'Mobile';
        }
        this.angulartics2.eventTrack.next({
          action: 'Add ' + contactType + ' Contact', properties: {
            category: 'Client Details',
            label: this.clinicName
          }
        });
      }, error => {
        addContactRequest$.error(JSON.parse(error.error) as VetBookerError);
      });
    return addContactRequest$;
  }

  deleteContact$(contact: VbContact): Observable<boolean> {
    const deleteContactRequest$ = new Subject<boolean>();
    Parse.Cloud.run('deleteContactEntry', {
      contactEntry: contact,
    }, {sessionToken: this.cookieService.get('sessionToken')}).then(() => {
      this.removeContact(contact);
      deleteContactRequest$.next(true);
    }, error => {
      deleteContactRequest$.error(error);
    });
    return deleteContactRequest$;
  }

}
