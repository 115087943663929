<div style="margin:10px 0">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4 style="margin:0">Does my dog need a welfare groom?</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>There may be a number of reasons why your dog still needs to be
        professionally groomed during the lockdown. If your dog falls
        into one or more of the six welfare reasons outlined below, they
        will be eligible to book one of these services in our
        salons: </p>
      <ul>
        <li>To prevent or remove matting and/or compaction of the coat
          for long haired dog breeds, which if left would have health
          and welfare implications.
        </li>
        <li>To remove matted areas in order to remove risk of pain and
          discomfort to your dog.
        </li>
        <li>A professional groom has been recommended by your vet.
        </li>
        <li>Where the length or growth direction of your dog’s nails are
          causing pain, discomfort, risk of infection, impacting how
          your dog stands or there is a risk of the nails piercing the
          pads. Customer confidence in clipping nails at home.
        </li>
        <li>If your dog’s hair is overgrown, resulting in a higher risk
          of localised infection e.g. eyes, ears or hygiene areas.
        </li>
        <li>You are unable to groom your dog at home due to their size
          or behaviour, you lack the appropriate facilities at home or
          have personal circumstance that would limit your capability to
          groom at home safely.
        </li>
      </ul>
      <p>If you have any concerns or need to discuss your dog's unique
        requirements, please contact the salon directly and we'll be
        happy to help.</p>
      <p>For help and advice on grooming your dog at home, please
        visit us at <a class="accent-text-color"
                       href="https://www.petsathome.com/shop/en/pets/advice/doggroomingadvice"
                       target="_blank">petsathome.com</a>. </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
