<div fxLayout="column" fxLayoutGap="20px">
  <ng-container *ngIf="isVets4Pets">
    <h3 class="card-item-title">How we use your personal information</h3>
    <p class="content-text"> Full details of how we use your personal
      information is available in our Privacy Policy, which you can find on
      our website or within the app.</p>
  </ng-container>
  <h3 class="card-item-title">Marketing Preferences</h3>
  <p *ngIf="marketingQuestionsIntro"
     class="content-text">{{marketingQuestionsIntro}}</p>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <ng-container *ngIf="isVets4Pets; else standardBlock">
      <mat-radio-group>
        <div fxLayout="column" fxLayoutGap="20px">
          <ng-container *ngFor="let preference of preferences">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-radio-button (change)="preferenceChanged(preference)"
                                [value]="preference"
                                [checked]="preference.allowed"></mat-radio-button>
              <p class="content-text no-margin">{{preference.questionText}}</p>
            </div>
          </ng-container>
        </div>
      </mat-radio-group>
    </ng-container>
    <ng-template #standardBlock>
      <ng-container *ngFor="let preference of preferences">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-checkbox [(ngModel)]="preference.allowed"
                        [checked]="preference.allowed"></mat-checkbox>
          <p class="content-text no-margin">{{preference.questionText}}</p>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <div fxLayoutAlign="center center" *ngIf="!embedded">
    <ng-container *ngIf="loading; else buttonBlock">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-progress-spinner *ngIf="loading" color="accent" diameter="40"
                              mode="indeterminate"></mat-progress-spinner>
        <p>Saving</p></div>
    </ng-container>
    <ng-template #buttonBlock>
      <button (click)="updateMarketingPreferences()" color="primary"
              mat-stroked-button>Save
      </button>
    </ng-template>
  </div>
</div>
