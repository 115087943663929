<div *ngIf="slot; else loading" fxLayout="row" fxLayout.xs="column"
     fxLayoutAlign="space-between center" fxLayoutAlign.xs="start start">
  <ng-container *ngIf="slot.photo">
    <div class="full-width-xs" fxLayoutAlign.xs="start start"
         style="padding-bottom: 10px">
      <div [ngStyle]="getPhoto()" class="circular background-image"></div>
    </div>
  </ng-container>
  <div fxLayout="row" fxLayout.xs="column"
       fxLayoutAlign="space-around space-between" class="full-width-form-item">
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign.xs="start center"
           fxLayoutGap.xs="10px" style="margin-bottom: 20px">
        <p class="mat-body-2 quick-book-title item-title label-margin">Date</p>
        <p class="mat-body-1 label-margin">{{slot.clinicDateTime |
          dfnsParse: "yyyy-MM-dd'T'HH:mm:ss.SSS": today |
          dfnsFormat:"EEEE do MMM 'at' HH:mm"}}</p>
      </div>
      <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign.xs="start center"
           fxLayoutGap.xs="10px" class="bottom-margin-gt-xs">
        <p class="mat-body-2 quick-book-title item-title label-margin ">
          Where </p>
        <p class="mat-body-1 label-margin">{{resource?.name}}</p>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign.xs="start start"
         fxLayoutAlign.gt-xs="space-between start">
      <div *ngIf="patients; else blocker" fxLayout="column" fxLayout.xs="row"
           fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px">
        <p class="mat-body-2 quick-book-title item-title label-margin ">Pet</p>
        <mat-form-field *ngIf="patients.length>1; else singlePatient"
                        floatLabel="never" class="hide-label-margin">
          <mat-label>Select a pet</mat-label>
          <mat-select [(value)]="slot.selectedPatient">
            <mat-option *ngFor="let patient of patients" [value]="patient">
              {{patient.patientName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template #singlePatient>
          <p class="mat-body-1 label-margin">{{patients[0].patientName}}</p>
        </ng-template>
      </div>
      <ng-template #blocker>
        <div class="blocker"></div>
      </ng-template>
      <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign.xs="start center"
           fxLayoutGap.xs="10px" style="margin-bottom: 20px">
        <p class="mat-body-2 quick-book-title item-title label-margin ">For</p>
        <p class="mat-body-1 label-margin">{{apptType.displayName}}</p>
      </div>
      <ng-container *ngIf="!patients">
        <div class="full-width-xs" fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="loading$|async; else button2Block">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-progress-spinner color="primary" diameter="40"
                                    mode="indeterminate"></mat-progress-spinner>
              Booking appointment
            </div>
          </ng-container>
          <ng-template #button2Block>
            <button (click)="bookFirstAvailable(slot.selectedPatient)"
                    color="primary" class="full-width-xs button-margin"
                    mat-raised-button>{{buttonText || 'Book'}}
            </button>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="patients">
    <div class="full-width-xs" fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngIf="loading$|async; else buttonBlock">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-progress-spinner color="primary" diameter="40"
                                mode="indeterminate"></mat-progress-spinner>
          Booking appointment
        </div>
      </ng-container>
      <ng-template #buttonBlock>
        <button (click)="bookFirstAvailable(slot.selectedPatient)"
                color="primary" class="full-width-xs"
                mat-raised-button>{{buttonText || 'Book'}}
        </button>
      </ng-template>
    </div>
  </ng-container>
</div>
<ng-template #loading>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner color="primary" diameter="40"
                          mode="indeterminate"></mat-progress-spinner>
    Looking for availability
  </div>
</ng-template>
