import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {format} from 'date-fns';
import * as Parse from 'parse';
import {ParseErrorHandler} from '../parse-error-handler';
import {VbSlotResponse} from '@appyvet/vetbooker-definitions/dist/bookings';
import {PositionItem} from '@appyvet/vetbooker-definitions/dist/map';
import {ClinicDetails, ClinicSearchParams} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {GroomAppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {AnonymousAvailabilityRequest} from '@appyvet/vetbooker-definitions/dist/groom_room';
import {LocalityResponse} from '@appyvet/vetbooker-definitions/dist/v4p';
import { GetMonthSlotsResult } from '@appyvet/vetbooker-definitions/definitions/interfaces/bookings';

@Injectable({
  providedIn: 'root'
})
export class MapSearchService {
  clinics$ = new BehaviorSubject<ClinicDetails[]>(null);
  groomTypes$ = new BehaviorSubject<GroomAppointmentType[]>([]);
  loading$ = new BehaviorSubject<boolean>(false);
  availabilityLoading$ = new BehaviorSubject<boolean>(false);
  location$ = new BehaviorSubject<PositionItem>(null);
  serverUrl: string;
  apiKey = environment.apiKey;
  localities: LocalityResponse;
  private skip = 0;
  private currentLat: number;
  private currentLng: number;
  currentClinics: ClinicDetails[] = [];
  private groomTypes: GroomAppointmentType[];
  private currentAvailability: VbSlotResponse[];

  constructor(private http: HttpClient) {
    if (environment.production) {
      const baseUrl = window.location.hostname;
      this.serverUrl = window.location.protocol + '//' + baseUrl + ':' + window.location.port + '/api/';
    } else {
      this.serverUrl = 'http://localhost:' + (environment.GROOM ? '1338' : '1339') + '/api/';
    }
    this.getGroomTypes();
  }

  getClinics(currentClinicCode: string) {
    this.http.get(this.serverUrl + 'getAllClinics', {headers: {Authorization: this.apiKey}}).toPromise()
      .then((results: ClinicDetails[]) => {
        if (currentClinicCode) {
          results.forEach((result, index) => {
            if (result.clinicCode === currentClinicCode) {
              results.splice(index, 1);
            }
          });
        }
        this.clinics$.next(results);
      }, error => {
        this.clinics$.error(error.error);
      });
  }


  getLocationForPostcode(postcode: string) {
    this.http.post(this.serverUrl + 'getLocationFromPostcode', {postcode},
      {headers: {Authorization: this.apiKey}}).subscribe((result: PositionItem) => {
      this.location$.next(result);
    }, e => {
      this.location$.error(e.error);
    });
  }

  placesSearch(text: string): Observable<LocalityResponse> {
    return this.http.get<LocalityResponse>(
      'https://api.woosmap.com/localities/autocomplete/?input=' +
      text + '&components=country:gb&key=woos-85314341-5e66-3ddf-bb9a-43b1ce46dbdc',
      {
        observe: 'response'
      }).pipe(
      map(res => {
        this.localities = res.body;
        return res.body;
      })
    );
  }

  getLocation(currentClinicCode?: string) {
    navigator.geolocation.getCurrentPosition(resp => {
        this.setLocation({lng: resp.coords.longitude, lat: resp.coords.latitude});
        this.searchClinicsForLocation(currentClinicCode);
      },
      err => {
        console.log(err);
      });
  }

  setLocation(currentPosition: PositionItem) {
    this.currentLat = currentPosition.lat;
    this.currentLng = currentPosition.lng;
    this.location$.next({lat: currentPosition.lat, lng: currentPosition.lng});
    this.currentClinics = [];
    this.skip = 0;
  }

  getMoreClinics() {
    this.skip += 5;
    this.searchClinicsForLocation();
  }

  getGroomTypes() {
    this.http.get(this.serverUrl + 'getGroomTypes', {headers: {Authorization: this.apiKey}})
      .subscribe((result: GroomAppointmentType[]) => {
        this.groomTypes = result;
        this.groomTypes$.next(this.groomTypes);
      }, e => {
        this.loading$.next(false);
        this.location$.error(e.error);
      });
  }

  searchClinicsForLocation(currentClinicCode?: string) {
    this.loading$.next(true);
    const params: ClinicSearchParams = {
      radius: 100,
      latitude: this.currentLat.toString(),
      longitude: this.currentLng.toString(),
      limit: 5,
      skip: this.skip,
      currentClinicCode
    };
    this.http.post(this.serverUrl + 'getClinicsWithinRadius', params,
      {headers: {Authorization: this.apiKey}}).subscribe((result: ClinicDetails[]) => {
      this.currentClinics = this.currentClinics.concat(result);
      this.loading$.next(false);
      this.clinics$.next(this.currentClinics);
    }, e => {
      this.loading$.next(false);
      this.location$.error(e.error);
    });
  }

  getGroomAvailability(clinic: ClinicDetails, apptType: GroomAppointmentType,
                       date: Date): Observable<VbSlotResponse[]> {
    this.availabilityLoading$.next(true);
    const results = new Subject<VbSlotResponse[]>();
    const params: AnonymousAvailabilityRequest = {
      clinicCode: clinic.clinicCode,
      type: apptType.objectId, typeName: apptType.displayName, date: format(date, 'yyyy-MM-dd')
    };
    Parse.Cloud.run('getAnonymousAvailability', params,)
      .then(result => {
        const slotsResult = result as GetMonthSlotsResult;
        results.next(slotsResult.days);
        this.availabilityLoading$.next(false);
      }, error => {
        this.availabilityLoading$.next(false);
        const parsedError = ParseErrorHandler.handleParseError(error);
        results.error(parsedError.message);
      });
    return results.asObservable();
  }
}
