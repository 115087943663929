import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-editing-dialog',
  templateUrl: './image-editing-dialog.component.html',
  styleUrls: ['./image-editing-dialog.component.scss']
})
export class ImageEditingDialogComponent implements OnInit {
  file: File;
  croppedImage: string;
  base64: string;
  roundCrop: boolean;

  constructor(public dialogRef: MatDialogRef<ImageEditingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.file = data.file;
    this.base64 = data.base64;
    this.roundCrop = data.roundCrop;
  }

  onCrop(image: ImageCroppedEvent) {
    this.croppedImage = image.base64;
  }

  ngOnInit(): void {
  }

}
