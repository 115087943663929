<mat-card>
  <mat-toolbar color="primary" mat-card-image style="height:130px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="card-icon">
        <mat-icon inline="true">assignment</mat-icon>
      </div>
      <h2>Appointment History</h2>
    </div>
  </mat-toolbar>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="center start">
      <p>{{upcomingAppointments}} Upcoming appointments</p>
      <p>{{pastAppointments}} Previous appointments</p>
    </div>
  </mat-card-content>
</mat-card>
