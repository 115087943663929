<div class="login-background" fxFlexFill fxLayout="column"
     fxLayoutAlign="center center">
  <mat-card class="centered-card">
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <h2>Uh oh, something went wrong</h2>
        <p class="content-text">There has been an error received, please try
          again or contact support.</p>
        <ng-container *ngIf="error"><p class="content-text">The error received
          was:</p>
          <p class="content-text accent-text-color">Code :
            {{errorCode}}, Error : {{error}}</p></ng-container>
        <ng-container *ngIf="!hideLogout">
          <p>If this persists
            please use the
            logout button below to return to the login page, although this is
            often because there has been a problem
            communicating with the {{isGroomRoom ? "salon's" : "clinic's"}}
            server</p>
          <button (click)="logOut()" color="primary" mat-raised-button>Log out
          </button>
        </ng-container>
      </div>
    </mat-card-content>
    <p></p>
  </mat-card>
</div>
