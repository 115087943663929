<div fxLayout="column">
  <p class="content-text">Need to move salon? Use our quick and easy
    transfer tool below.</p>
  <ng-container *ngIf="loading; else contentBlock">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
      <mat-progress-spinner diameter="40" color="primary"
                            mode="indeterminate"></mat-progress-spinner>
      <p class="content-text">Contacting server</p>
    </div>
  </ng-container>
  <ng-template #contentBlock>
    <ng-container *ngIf="step === CLINIC_STEP">
      <ng-container *ngIf="hasUpcomingAppointments; else clinicSelectBlock">
        <p class="content-text">Unfortunately we can only begin the salon
          transfer process when you have no upcoming appointments at your
          current salon. Please cancel these appointments and then refresh this
          page.</p>
      </ng-container>
      <ng-template #clinicSelectBlock>
        <p class="content-text">First, find the salon you want to transfer
          to.</p>
        <app-groom-selector [currentClinicCode]="clinicCode"
                            [returnClinic]="true" [noMap]="true"
                            (clinicSelected)="clinicSelected($event)"></app-groom-selector>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="step === ADDRESS_STEP">
      <div fxLayout="column">
        <p class="content-text">Before we move across your details, please
          confirm
          your address below.</p>
        <app-address-lookup [showAddressForm]="true" (addressCancel)="cancel()"
                            (addressSave)="saveAddress($event)" countryCode="gb"
                            [address]="address" [isNotClientDetailsPage]="true"
                            [requirements]="addressRequirements"></app-address-lookup>
      </div>
    </ng-container>
    <!--    <ng-container *ngIf="step === CONTACTS_STEP">-->
    <!--      <div fxLayout="column">-->
    <!--        <p class="content-text">Please confirm your contact details below. Any-->
    <!--          changes you make here will only be-->
    <!--          reflected at the new location. Please note that at least one mobile-->
    <!--          number is-->
    <!--          required for registration at the new location.</p>-->
    <!--        <app-transfer-contacts-->
    <!--          (confirmContacts)="contactsConfirmed($event)"></app-transfer-contacts>-->
    <!--      </div>-->
    <!--    </ng-container>-->
    <ng-container *ngIf="step === PETS_STEP">
      <div fxLayout="column">
        <p class="content-text">To help us take care of everything for you,
          please let us know which pets you’d like
          to move with you by ticking the boxes below.</p>
        <app-transfer-pets [fromPets]="fromPets" [toPets]="toPets"
                           (cancel)="cancel()"
                           [transferFromClinicName]="startClinic"
                           [transferToClinicName]="transferClinic.practiceName"
                           (confirmPets)="petsConfirmed($event)"></app-transfer-pets>
      </div>
    </ng-container>
    <ng-container *ngIf="step === FINISH_STEP">
      <div fxLayout="column">
        <p class="content-text">Your online booking account will now be moved to
          {{transferClinic.practiceName}}. It can take up to 30 seconds to
          complete, however please note that only your history for appointments
          booked online are included in the salon change.</p>
        <app-transfer-summary (cancel)="cancel()" [address]="selectedAddress"
                              [contacts]="contacts"
                              [selectedPets]="selectedPets"
                              (completeWizard)="completeWizard()"></app-transfer-summary>
      </div>
    </ng-container>
    <ng-container *ngIf="step === COMPLETED_STEP">
      <div fxLayout="column">
        <ng-container *ngIf="transferError; else successBlock">
          <p class="content-text accent-text-color">{{transferError}}</p>
        </ng-container>
        <ng-template #successBlock>
          <p class="content-text">Your transfer has been successful. In 15
            seconds, you will be logged out. Please refresh your browser and
            log back in again to access your account at the new location. If
            your browser does not automatically log you out, feel free to hit
            the logout button on the menu.</p></ng-template>
      </div>
    </ng-container>
  </ng-template>
</div>
