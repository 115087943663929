export const SMS_VERIFICATION = 'smsVerification';
export const EMAIL_VERIFICATION = 'emailVerification';
export const MATCHING = 'matching';
export const COGNITO_VERIFICATION = 'cognitoVerification';
export const ADDITIONAL_MATCH = 'additionalMatch';
export const APP_POPUP = 'app_popup';
export const EXIT_ACTION = 0;
export const TRANSFER_ACTION = 1;
export const LOGIN_ACTION = 2;
export const AVAILABILITY_COOKIE = 'grob_availabilityItem';
