import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatchErrorMatcher} from '../../components/password-matching-validator';
import {RegistrationResource} from '@appyvet/vetbooker-definitions/dist/resource';

export interface AdditionalFormItem {
  form: FormGroup;
  firstLoad?: boolean;
}

export const NOTES = 'Notes';
export const CLINIC = 'Preferred clinic';
export const HOW_FOUND = 'How you found us';
export const PASSWORD = 'Password';

@Component({
  selector: 'app-additional-registration-info',
  templateUrl: './additional-registration-info.component.html',
  styleUrls: ['./additional-registration-info.component.scss']
})

export class AdditionalRegistrationInfoComponent implements OnInit {
  additionalInfoForm: FormGroup;
  @Input() clinics: RegistrationResource[];
  @Input() passedPassword: string;
  @Input() canSave: boolean;
  @Input() additionalTermsUrl: string;
  @Input() showNotes: boolean;
  @Input() showHowFound: boolean;
  @Input() requireHowFound: boolean;
  @Output() onSave = new EventEmitter<AdditionalFormItem>();
  @Output() onCancel = new EventEmitter<boolean>();
  matcher = new PasswordMatchErrorMatcher();
  additionalValidationMessages = {
    password: [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 8 characters long'},
      {type: 'mismatch', message: 'Passwords must match'},
    ],
    confirmPassword: [
      {type: 'required', message: 'Confirmed Password is required'},
      {type: 'minlength', message: 'Password must be at least 8 characters long'},
    ],
    notes: [
      {type: 'maxLength', message: 'Maximum length for notes is 1500 characters'},
    ],
    howFound: [
      {type: 'required', message: 'How you found us is required'},
    ],
  };
  NOTES = NOTES;
  CLINIC = CLINIC;
  HOW_FOUND = HOW_FOUND;
  PASSWORD = PASSWORD;

  constructor() {
  }

  ngOnInit(): void {
    this.additionalInfoForm = new FormGroup({
        'Preferred clinic': new FormControl('', this.clinics?.length > 1 ? [Validators.required] : null),
        Password: new FormControl(this.passedPassword, [Validators.required, Validators.minLength(8)]),
        confirmPassword: new FormControl(this.passedPassword, [Validators.required, Validators.minLength(8)]),
        Notes: new FormControl('', [Validators.maxLength(1500)]),
        'How you found us': new FormControl('', [Validators.maxLength(50)]),
        additionalTerms: new FormControl(false, this.additionalTermsUrl ? [Validators.requiredTrue] : null),

      },
      {validators: this.checkPasswords});
    this.onSave.emit({form: this.additionalInfoForm, firstLoad: true});
    if (this.requireHowFound) {
      this.additionalInfoForm.get('additionalTerms').setValidators([Validators.maxLength(50), Validators.required]);
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get(PASSWORD).value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true};
  }

  onNext() {
    this.onSave.emit({form: this.additionalInfoForm});
  }

  onBack() {
    this.onCancel.emit(true);
  }
}
