<div fxLayout="column" style="min-width: 200px">
  <h2 class="accent-text-color" mat-dialog-title>{{typeName}} Closed</h2>
  <div fxLayout="row" fxLayoutAlign="center center" mat-dialog-content
       style="overflow:hidden ">
    <p class="mat-body content-text">This {{individualTypeName}} has now
      closed.<br><br>
      Please use the {{individualTypeName}}
      transfer tool to register with an
      alternative {{individualTypeName}} and we will arrange for your
      customer record to be shared with them.</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
    <button [mat-dialog-close]="true" color="primary" mat-raised-button>Got it
    </button>
  </div>

</div>

