<div fxLayout="column">
  <ng-container *ngFor="let category of registrationSummaryCategories">
    <p class="content-text"><b>{{category.name}}</b></p>
    <div fxLayout="column">
      <ng-container *ngFor="let item of category.items">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p class="content-text">{{item.name}}</p>
          <p class="content-text">{{item.value || ''}}</p></div>
      </ng-container>
    </div>
  </ng-container>
</div>
