import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session-service.service';
import {TermsDialogComponent} from '../../shared/terms-dialog/terms-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  termsAgreed: boolean;

  constructor(private sessionService: SessionService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  agreeToTerms() {
    this.sessionService.agreeNewTerms();
  }

  showTerms() {
    this.dialog.open(TermsDialogComponent);
  }
}
