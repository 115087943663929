// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PARSE_APP_ID: 'DAh6Ns6VtLyrU1WoqEzPYl41RtJAUQoQ1KXcnJOm',
  serverURL: 'http://localhost:1338/parse',
  GROOM: true,
  VETS_4_PETS: false,
  apiKey: "Basic R1JPQjo2TEF1eVYxN09iekJPc0RpSWNmUmxFRkNITEJ4UUFFSHo3VVVRMjFD",
  CLINIC_CODE: null,
  BACKEND_URL:null,
  sentryApiCode:null
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
