import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-app-store-dialog',
  templateUrl: './app-store-dialog.component.html',
  styleUrls: ['./app-store-dialog.component.scss']
})
export class AppStoreDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AppStoreDialogComponent>,
               @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit(): void {
  }

}
