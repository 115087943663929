import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MarketingContact, MarketingResponse} from '@appyvet/vetbooker-definitions/dist/marketing';

@Component({
  selector: 'app-client-marketing-channels',
  templateUrl: './client-marketing-channels.component.html',
  styleUrls: ['./client-marketing-channels.component.scss']
})
export class ClientMarketingChannelsComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() embedded: boolean;
  @Input() channels: MarketingContact[];
  @Input() preferences: MarketingResponse[];
  @Output() channelsSave = new EventEmitter<MarketingContact[]>();
  @Output() channelsChange = new EventEmitter<MarketingContact[]>();
  @Input() communicationChannelIntro: string;
  disabled = false;

  constructor() {
  }

  ngOnInit(): void {
    this.communicationChannelIntro = this.communicationChannelIntro ||
      'We\'d like to be able to contact you about our products and services. ' +
      'Please select which methods you are happy for us to use below:';
  }

  updateMarketingChannels() {
    this.channelsSave.emit(this.channels);
  }

  onChannelChanged() {
    this.channelsChange.emit(this.channels);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Vets 4 pets have hardcoded question 1 to be do not contact. In this scenario, must disable contact channels
    if (environment.VETS_4_PETS) {
      this.disabled = false;
      changes.preferences.currentValue.forEach(preference => {
        if (preference.questionId === '2' && preference.allowed) {
          this.disabled = true;
        }
      });
    }
  }
}
