<h2 class="accent-text-color" mat-dialog-title>Edit Photo</h2>
<div [ngStyle.gt-xs]="{'min-width':'500px'}" mat-dialog-content
     style="overflow:hidden">
  <image-cropper (imageCropped)="onCrop($event)" [imageFile]="file"
                 style="max-height: 500px" [imageBase64]="base64"
                 [maintainAspectRatio]="true" [resizeToWidth]="250" format="png"
                 onlyScaleDown="true" [roundCropper]=roundCrop></image-cropper>
  <h2 class="accent-text-color" mat-dialog-title>Result:</h2>
  <div [style.background-image]="'url(' + croppedImage  + ')'"
       class="pet-photo-large background-image"
       [ngClass]="{ 'pet-photo-round' : roundCrop}"
       style="margin-bottom:20px"></div>
</div>
<div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button [mat-dialog-close]="croppedImage" color="primary" mat-button>Save
  </button>
</div>
