import {Component, OnInit} from '@angular/core';
import {SignupService} from '../signup.service';
import {BehaviorSubject} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';
import {
  ADDITIONAL_MATCH,
  COGNITO_VERIFICATION,
  EMAIL_VERIFICATION,
  MATCHING,
  SMS_VERIFICATION
} from '../../../../constants';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class SignupComponent implements OnInit {
  currentPage$: BehaviorSubject<string>;
  MATCHING = MATCHING;
  SMS_VERIFICATION = SMS_VERIFICATION;
  EMAIL_VERIFICATION = EMAIL_VERIFICATION;
  COGNITO_VERIFICATION = COGNITO_VERIFICATION;
  ADDITIONAL_MATCH = ADDITIONAL_MATCH;


  constructor(private signupService: SignupService) {
  }

  ngOnInit(): void {
    this.currentPage$ = this.signupService.currentPage$;
  }

}
