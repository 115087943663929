import {Component, Input, OnInit} from '@angular/core';
import {differenceInMonths, differenceInWeeks, differenceInYears, isBefore, parseISO, subYears} from 'date-fns';
import {PatientService} from '../patient.service';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
    selector: 'app-patient-card',
    templateUrl: './patient-card.component.html',
    styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit {
  @Input() patient: VbExtendedPatient;
  age = '';
    hideReminders: boolean;

    constructor(private patientService: PatientService) {
        this.hideReminders = patientService.hideReminders;
    }

  hasOverdueReminders(patient: VbExtendedPatient): number {
    return patient.extraInfo?.reminders.filter(reminder =>
      isBefore(reminder.date, new Date())
    ).length;
  }

    getAge() {
        const dateNow = new Date();
        const patientDate = parseISO(this.patient.dateOfBirth);
        const ageYears = differenceInYears(dateNow, patientDate);
        if (ageYears > 0) {
            this.age += ageYears + ' year';
            if (ageYears > 1) {
                this.age += 's';
            }
        }
        const ageMonths = differenceInMonths(subYears(dateNow, ageYears), patientDate);
        // Is a puppy, so display in weeks
        if (ageYears < 1 && ageMonths < 6) {
            const ageWeeks = differenceInWeeks(subYears(dateNow, ageYears), patientDate);
            this.age += ageWeeks + ' week';
            if (ageWeeks > 1) {
                this.age += 's';
            }
        } else {
            if (ageMonths > 0) {
                if (ageYears > 0) {
                    this.age += ', ';
                }
                this.age += ageMonths + ' month';
                if (ageMonths > 1) {
                    this.age += 's';
                }
            }
        }
        this.age += ' old';
    }

    ngOnInit(): void {
        this.getAge();
    }

}
