import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  emailForm: FormGroup;
  emailValidationMessages = {
    email: [
      {type: 'required', message: 'Email is required'},
      {type: 'email', message: 'Oops, we don\'t recognise that as a valid email address'},
    ],
  };
  @Input() email: string;
  @Input() loading: boolean;
  @Output() emailSave = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    this.emailForm = new FormGroup({email: new FormControl(this.email, [Validators.email, Validators.required])});
  }

  saveEmail() {
    this.emailSave.emit(this.emailForm.get('email').value);
  }
}
