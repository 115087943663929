import {Pipe, PipeTransform} from '@angular/core';
import {isBefore} from 'date-fns';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';

@Pipe({
  name: 'isBeforeEmergencyDate'
})
export class EmergencyDatePipePipe implements PipeTransform {

  constructor() {
  }

  transform(clinic: ClinicDetails): boolean {
    return !clinic.emergencyEndDate || isBefore(new Date(clinic.emergencyEndDate), new Date());
  }
}
