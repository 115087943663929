<mat-card>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column"
           fxLayout.gt-xs="space-between center">
        <div fxLayout="column" fxFlex.gt-xs="50%">
          <div fxLayout="row">
            <p class="title item-start">Name</p>
            <p class="content-text">{{name}}</p>
          </div>
          <div fxLayout="row">
            <p class="title item-start">Email</p>
            <p class="content-text">{{email || 'None on record'}}</p>
          </div>
          <div fxLayout="row">
            <p class="title item-start">Mobile</p>
            <p class="content-text">{{mobile || 'None on record'}}</p>
          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start center">
            <p class="title item-start">Address</p>
            <div fxLayout="column">
              <p class="content-text">{{address1}}</p>
              <ng-container *ngIf="address2">
                <p class="content-text">{{address2}}</p></ng-container>
              <ng-container *ngIf="address3">
                <p class="content-text">{{address3}}</p></ng-container>
              <ng-container *ngIf="state">
                <p class="content-text">{{state}}</p></ng-container>
              <p class="content-text">{{postcode}}</p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" [fxLayoutAlign]="marketingAllowed ?
      'space-between center' : 'end center'" class="full-width-form-item"
           fxLayout.xs="column" fxLayoutGap.xs="10px">
        <button mat-raised-button color="primary" [routerLink]="'/details'"
                (click)="amendClicked()" class="full-width-xs">
          Amend
        </button>
        <ng-container *ngIf="marketingAllowed">
          <button mat-stroked-button color="primary" class="full-width-xs"
                  [routerLink]="'/settings'">
            Marketing Preferences
          </button>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
