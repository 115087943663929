import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-groom-booking-type-guide',
  templateUrl: './groom-booking-type-guide.component.html',
  styleUrls: ['./groom-booking-type-guide.component.scss']
})
export class GroomBookingTypeGuideComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
