<div class="calendar-body" flex fxLayout="column" fxLayoutAlign="center center">
  <div class="calendar-header" fxLayout="row"
       fxLayoutAlign="space-between center">
    <button (click)="previous()" [disabled]="isFirstMonth" aria-label="previous"
            mat-button style="min-width:42px" type="button">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <span class="month-name">{{month | dfnsFormat: "MMMM yyyy"}} </span>
    <button (click)="next()" [disabled]="isLastMonth" aria-label="next"
            mat-button style="min-width:42px" type="button">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <table class="calendar">
    <thead>
    <tr class="days">
      <th>Sun</th>
      <th>Mon</th>
      <th>Tue</th>
      <th>Wed</th>
      <th>Thu</th>
      <th>Fri</th>
      <th>Sat</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="loading; else calendarBlock">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td style="text-align: center; vertical-align: middle">
          <mat-progress-spinner [diameter]="40" color="primary"
                                mode="indeterminate"></mat-progress-spinner>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </ng-container>
    <ng-template #calendarBlock>
      <tr *ngFor="let week of weeks" class="week">
        <td (click)="select(day)" *ngFor="let day of week.days"
            [ngClass]="{'different-month': !day.isCurrentMonth || day.isDisallowed, 'selected-date' : (day.date | dfnsDifferenceInCalendarDays: selectedDate) === 0}"
            class="day">{{day.number}}
          <ng-container
            *ngIf="!isRequestType && day.availability && !day.isDisallowed">
            <mat-icon [ngStyle]="{color:getAvailabilityColor(day.availability)}"
                      style="position: relative; top: -0.5em; font-size: 80%; width:10px; height:0">
              fiber_manual_record
            </mat-icon>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    </tbody>
  </table>
  <ng-container *ngIf="!isRequestType">
    <div fxLayout="row" fxLayout.xs="row wrap"
         fxLayoutAlign.gt-xs="space-between center" fxLayoutGap="5px">
      <p>Availability:</p>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{color:getAvailabilityColor(5)}"
                  style="font-size: 80%;width:18px">fiber_manual_record
        </mat-icon>
        <p>Good</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{color:getAvailabilityColor(4)}"
                  style="font-size: 80%;width:18px">fiber_manual_record
        </mat-icon>
        <p>Limited</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [ngStyle]="{color:getAvailabilityColor(0)}"
                  style="font-size: 80%;width:18px">fiber_manual_record
        </mat-icon>
        <p>None</p>
      </div>
    </div>
  </ng-container>
</div>
