<div fxLayout="column">
  <div class="padding-10" fxLayout="column">
    <h1 class="section-header">My Pets</h1></div>
  <ng-container *ngIf="patients$ | async as patients; else loadingBlock">
    <ng-container *ngIf="patients.length === 0; else petsBlock">
      <h2 class="padding-10">We do not have any pets on file for you, please add
        one below</h2>
    </ng-container>
    <ng-template #petsBlock>
      <h2 style="padding-left: 10px">Select any pet to see more information, or
        you can add a new pet below</h2>
      <div fxLayout="column" style="margin-left: 10px">
        <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center"
             fxLayoutGap="20px grid" fxLayoutAlign.xs="center center">
          <app-patient-card *ngFor="let patient of patients"
                            style="cursor:pointer"
                            [patient]="patient"></app-patient-card>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingBlock>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-progress-spinner color="primary" diameter="60"
                            mode="indeterminate"></mat-progress-spinner>
      <p class="content-text">Retrieving pet details</p>
    </div>
  </ng-template>
  <ng-container *ngIf="patientService.canAddPatient$ | async">
    <app-add-patient-page></app-add-patient-page>
  </ng-container>
</div>
