<mat-card>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="20px">
      <app-client-marketing embedded="true" [questions]="marketingQuestions"
                            (preferencesChange)="updatePreferences($event)"
                            [preferences]="marketingPreferences"></app-client-marketing>
      <app-client-marketing-channels [channels]="marketingChannels"
                                     (channelsChange)="updateChannels($event)"
                                     [preferences]="marketingPreferences"
                                     embedded="true"></app-client-marketing-channels>
      <div fxLayout="row" fxLayoutAlign="center center"
           class="full-width-form-item">
        <ng-container *ngIf="loading$ | async; else buttonBlock">
          <mat-progress-spinner color="primary" diameter="30"
                                mode="indeterminate"></mat-progress-spinner>
        </ng-container>
        <ng-template #buttonBlock>
          <button mat-stroked-button color="primary" (click)="onSave()"
                  [disabled]="!canSave">Save
          </button>
        </ng-template>
      </div>
    </div>
  </mat-card-content>
</mat-card>
