<div class="padded-container content-text" fxFill fxLayout="column"
     fxLayoutAlign="start center">
  <h1 class="big-header">Clinic account</h1>
  <p class="content-text">We'll use the following details to look for an
    existing account at the clinic. If none can be
    found you can create a new account</p>
  <form [formGroup]="matchingForm" class="full-width-form-item">
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>Mobile</mat-label>
      <input formControlName="mobile" matInput placeholder="Mobile" required
             type="tel" autocomplete="tel">
      <mat-error *ngFor="let validation of accountValidationMessages.mobile">
        <mat-error
          *ngIf="matchingForm.get('mobile').hasError(validation.type) && (matchingForm.get('mobile').dirty || matchingForm.get('mobile').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>Postcode</mat-label>
      <input formControlName="postcode" matInput placeholder="Postcode" required
             autocomplete="postal-code">
      <mat-error *ngFor="let validation of accountValidationMessages.postcode">
        <mat-error
          *ngIf="matchingForm.get('postcode').hasError(validation.type) && (matchingForm.get('postcode').dirty || matchingForm.get('postcode').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <div style="margin-bottom:20px" fxLayout="row" fxLayoutGap="10px"
         fxLayoutAlign="start center">
      <mat-checkbox (change)="consent = !consent"
                    [checked]="consent"></mat-checkbox>
      <p class="no-margin">I agree to the
        <span class="terms-link" (click)="showTerms()">terms and
            conditions</span></p>
    </div>
    <div><p class="content-text accent-text-color">{{error$ | async}}</p></div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <ng-container *ngIf="loading$ | async; else loginBtn">
        <mat-progress-spinner color="primary" diameter="30"
                              mode="indeterminate"></mat-progress-spinner>
        <p>Loading</p>
      </ng-container>
      <ng-template #loginBtn>
        <div fxLayout="row" fxLayoutAlign="space-between center"
             class="full-width-form-item">
          <button mat-stroked-button (click)="back()">Back</button>
          <button (click)="match()" color="primary" mat-raised-button
                  [disabled]="!matchingForm.valid || !consent">Next
          </button>
        </div>
      </ng-template>
    </div>

  </form>
</div>
