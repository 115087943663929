import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ClinicSelectorComponent} from './clinic-selector/clinic-selector.component';
import {GuestGuard} from '../auth/guest.guard';
import {ClinicCodeGuard} from '../auth/clinic-code.guard';


const routes: Routes = [{path: 'selector', component: ClinicSelectorComponent, canActivate: [GuestGuard, ClinicCodeGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClinicSelectorRoutingModule {
}
