<div class="padded-container" fxFill fxLayout="column" fxLayoutAlign="start center">
  <mat-card>
    <mat-card-content>
      <h1 class="big-header">Configure Password</h1>
      <p class="content-text">You've been sent this link because an online portal account is being set up for you
        by your clinic. All you need to do is set a password here to complete registration.</p>
      <ng-container *ngIf="loading$|async; else passwordFormBlock">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <mat-progress-spinner color="primary" diameter="40" mode="indeterminate"></mat-progress-spinner>
          <p class="content-text">Resetting password, please wait</p>
        </div>
      </ng-container>
      <ng-template #passwordFormBlock>
        <p class="content-text">Enter a new password below</p>
        <form (ngSubmit)="resetPassword()" [formGroup]="passwordForm">
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput placeholder="Password" required type="password">
            <mat-error *ngFor="let validation of account_validation_messages.password">
              <mat-error
                *ngIf="passwordForm.get('password').hasError(validation.type) && (passwordForm.get('password').dirty || passwordForm.get('password').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Confirm Password</mat-label>
            <input [errorStateMatcher]="matcher" formControlName="confirmPassword" matInput
                   placeholder="Confirm Password"
                   type="password">
            <mat-error
              *ngIf="passwordForm.hasError('mismatch') && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)"
              class="error-message">Passwords must match
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="end end">
            <button [disabled]="!passwordForm.valid" color="primary" mat-raised-button>Finish Registration
            </button>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
