import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {format, parseISO, subMonths, subYears} from 'date-fns';
import {PetFormItem} from '../add-patient-page/add-patient-page.component';
import {BreedItem, SpeciesItem, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {RequireMatch} from '../../components/common-components/requireAutocompleteMatch';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit, OnDestroy {
  addPetForm: FormGroup;
  @Input() isNewPet: boolean;
  @Input() editPatient: VbExtendedPatient;
  @Output() savePatient = new EventEmitter<PetFormItem>();
  @Output() formChanged = new EventEmitter<PetFormItem>();
  @Output() cancel = new EventEmitter<boolean>();
  @Input() petId: string;
  isGroomRoom = environment.GROOM;
  selectedSpecies: SpeciesItem;
  knownBirthdate = true;
  selectedSex: string;
  neutered: boolean;
  filteredBreeds: Observable<BreedItem[]>;
  filteredColors: Observable<string[]>;
  patientValidationMessages = {
    name: [
      {type: 'required', message: 'Pet name is required'},
      {type: 'pattern', message: 'Pet\'s name can only contain alphanumeric characters'}
    ],
    dateOfBirth: [
      {type: 'matDatepickerMax', message: 'Date of birth must be before today'},
      {type: 'pattern', message: 'Date of birth must be in the format \'DD/MM/YYYY\''}
    ],
    breed: [
      {type: 'required', message: 'Breed is required'},
      {type: 'pattern', message: 'Breed type can only contain alphanumeric characters'},
      {type: 'incorrect', message: 'Breed must be selected from the options available'},
    ],
    color: [
      {type: 'required', message: 'Color is required'},
      {type: 'pattern', message: 'Color can only contain alphanumeric characters'}
    ],
    microchip: [
      {type: 'pattern', message: 'Microchip number cannot contain special characters'},
    ],
  };
  @Input() breeds: BreedItem[];
  @Input() colors: string[];
  @Input() regex: string;
  @Input() isRegistration: boolean;
  @Input() species: SpeciesItem[];
  @Input() showInsured: boolean;
  @Input() showLastVac: boolean;
  @Input() showMicrochip: boolean;
  @Input() showColors: boolean;
  private speciesBreedsList: BreedItem[];
  private formChangedSubscription: Subscription;
  private yearsSubscription: Subscription;
  private monthsSubscription: Subscription;
  neuteredDirty: boolean;
  today = new Date();

  constructor() {
  }

  ngOnInit(): void {
    const startDateOfBirth = this.editPatient ? parseISO(this.editPatient?.dateOfBirth) : new Date();
    this.addPetForm = new FormGroup({
      name: new FormControl(this.editPatient?.name, [Validators.required, Validators.pattern(this.regex)]),
      breed: new FormControl({
        value: this.editPatient?.breedItem,
        disabled: !this.selectedSpecies
      }, [Validators.required, RequireMatch]),
      color: new FormControl(this.editPatient?.color, this.showColors ? [Validators.required] : null),
      dateOfBirth: new FormControl(startDateOfBirth, [Validators.required]),
      microchip: new FormControl(this.editPatient?.microchip, [Validators.pattern(this.regex)]),
      months: new FormControl(0),
      years: new FormControl(0),
      lastVac: new FormControl(this.editPatient?.lastVaccinated),
      insurance: new FormControl(this.editPatient?.insurer),
      sex: new FormControl(this.editPatient?.sex, [Validators.required]),
      neutered: new FormControl(this.editPatient?.desexed, [Validators.required]),
      species: new FormControl(this.editPatient?.breedItem?.speciesItem, [Validators.required])
    });
    if (this.showColors) {
      this.filteredColors = this.addPetForm.get('color').valueChanges
        .pipe(
          startWith(this.editPatient ? this.editPatient.color : ''),
          map(value => this._filterColors(value))
        );
    }
    if (this.editPatient) {
      if (this.editPatient.breedItem) {
        this.selectedSpeciesChanged(this.editPatient.breedItem.speciesItem, this.editPatient.breedItem);
      } else if (this.species.length === 1) {
        this.selectedSpecies = this.species[0];
        this.selectedSpeciesChanged(this.selectedSpecies);
      }
      this.selectedSex = this.editPatient.sex;
      if (this.editPatient.desexed !== undefined) {
        this.neuteredChanged(this.editPatient.desexed);
      }
      this.addPetForm.markAllAsTouched();
    } else if (this.species.length === 1) {
      this.selectedSpecies = this.species[0];
      this.selectedSpeciesChanged(this.selectedSpecies);
    }

    this.yearsSubscription = this.addPetForm.get('years').valueChanges.subscribe(() => {
      this.addPetForm.get('dateOfBirth').setValue(this.getDateOfBirth());
    });
    this.monthsSubscription = this.addPetForm.get('months').valueChanges.subscribe(() => {
      this.addPetForm.get('dateOfBirth').setValue(this.getDateOfBirth());
    });
    if (this.formChanged) {
      // Send initial status change with updated (empty) form, but can't update patient or causes error. Ensures step
      // shows as errored when skip stepper in reg form.
      this.formChanged.emit({
        patient: this.getPatientFromForm(),
        form: this.addPetForm,
        id: this.petId,
        onlyUpdateForm: true
      });
      this.formChangedSubscription = this.addPetForm.valueChanges.subscribe(() => {
        this.formChanged.emit({patient: this.getPatientFromForm(), form: this.addPetForm, id: this.petId});
      });
    }
  }

  displayFn(breed: BreedItem): string {
    return breed && breed.name ? breed.name : '';
  }

  getDateOfBirth(): Date {
    const startDateYears = subYears(new Date(), this.addPetForm.get('years').value);
    return subMonths(startDateYears, this.addPetForm.get('months').value);
  }

  onCancel() {
    this.cancel.emit(true);
  }

  getPatientFromForm(): VbExtendedPatient {
    return {
      breedItem: this.addPetForm.get('breed').value,
      color: this.addPetForm.get('color').value,
      species: this.selectedSpecies?.name,
      dateOfBirth: this.addPetForm.get('dateOfBirth').value ? format(this.addPetForm.get('dateOfBirth').value,
        'yyyy-MM-dd') : null,
      desexed: this.neutered,
      name: this.addPetForm.get('name').value,
      patientNumber: null,
      sex: this.selectedSex,
      microchip: this.addPetForm.get('microchip').value,
      insurer: this.addPetForm.get('insurance').value,
      lastVaccinated: this.addPetForm.get('lastVac').value,
    };
  }

  save() {
    this.savePatient.emit({patient: this.getPatientFromForm(), form: this.addPetForm, id: this.petId});
  }

  selectedSpeciesChanged(species: SpeciesItem, existingBreed?: BreedItem) {
    this.selectedSpecies = species;
    // This filter is faster than a for loop oddly.
    this.speciesBreedsList = this.breeds.filter(breed => breed.speciesItem.code === species.code);
    this.filteredBreeds = this.addPetForm.get('breed').valueChanges
      .pipe(
        startWith(existingBreed ? existingBreed.name : ''),
        map(value => this._filterBreeds(value))
      );
    if (!existingBreed) {
      this.addPetForm.get('breed').reset();
    }
    this.addPetForm.get('breed').enable();
    this.addPetForm.get('species').setValue(species);
    if (existingBreed) {
      this.addPetForm.get('breed').setValue(existingBreed);
    }
  }

  private _filterBreeds(value: string): BreedItem[] {
    // When value finally selected from autocomplete, sends a breeditem not a string, so check here to avoid error.
    if (value && typeof value === 'string' && value.length > 2) {
      const filterValue = value.toLowerCase();
      // Note - this can be a big array of object so use fastest loop possible.
      let length = this.speciesBreedsList.length;
      const returnArray = [];
      while (length--) {
        if (this.speciesBreedsList[length].name && this.speciesBreedsList[length].name.toLowerCase()
          .indexOf(filterValue) !== -1) {
          returnArray.push(this.speciesBreedsList[length]);
        }
      }
      return returnArray.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  private _filterColors(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.colors.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  ngOnDestroy(): void {
    this.formChangedSubscription?.unsubscribe();
    this.monthsSubscription?.unsubscribe();
    this.yearsSubscription?.unsubscribe();
  }

  neuteredChanged(status: boolean) {
    this.neutered = status;
    this.addPetForm.get('neutered').setValue(status);
    this.neuteredDirty = true;
  }

  sexChanged(sex: string) {
    this.selectedSex = sex;
    this.addPetForm.get('sex').setValue(sex);
  }
}
