<form [formGroup]="verificationForm">
  <div class="padded-container verification-container content-text" fxFill
       fxLayout="column" fxLayoutAlign="start center">
    <h1 class="big-header">Verification</h1>
    <p class="content-text">Great news, we've found an existing customer record
      that matches those details and an online
      booking account
      has been created.</p>
    <p class="content-text">Please enter the code we have sent to you via SMS
      <span *ngIf="isGroomRoom">to complete the registration process</span>
    </p>
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>SMS verification code</mat-label>
      <input formControlName="smsCode" matInput placeholder="SMS code" required>
      <mat-error
        *ngFor="let validation of accountValidationMessages.verificationCode">
        <mat-error
          *ngIf="verificationForm.get('smsCode').hasError(validation.type) && (verificationForm.get('smsCode').dirty || verificationForm.get('smsCode').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <p class="content-text accent-text-color">{{error$ | async}}</p>
    <div class="full-width-form-item" fxLayout="row"
         fxLayoutAlign="space-between">
      <div *ngIf="!resentSms; else smsResentBlock">
        <button (click)="requestNewSmsCode()" *ngIf="isGroomRoom"
                color="primary" mat-raised-button type="button">
          Request new code
        </button>
        <button (click)="requestNewSmsCode()" *ngIf="!isGroomRoom"
                color="primary" mat-stroked-button type="button">
          Request new code
        </button>
      </div>
      <ng-template #smsResentBlock><p class="content-text">Repeat SMS request
        sent</p></ng-template>
      <ng-container *ngIf="loading$ | async; else buttonBlock">
        <mat-progress-spinner mode="indeterminate" color="primary"
                              diameter="30"></mat-progress-spinner>
      </ng-container>
      <ng-template #buttonBlock>
        <button (click)="next()" *ngIf="isGroomRoom"
                [disabled]="!verificationForm.valid" color="primary"
                mat-raised-button>Next
        </button>
        <button (click)="next()" *ngIf="!isGroomRoom"
                [disabled]="!verificationForm.valid" color="primary"
                mat-stroked-button>Next
        </button>
      </ng-template>
    </div>
  </div>
</form>
