import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductTypeItem} from '@appyvet/vetbooker-definitions/dist/prescriptions';

@Component({
  selector: 'app-product-type-selector',
  template: `
    <div class="card-option-container" fxFill fxLayout="row wrap" fxLayoutAlign="space-between center"
         fxLayoutAlign.lt-md="start center">
      <div (click)="setSelectedType(type);" *ngFor="let type of types" [class.selected-card]="type === selectedType"
           class="selection-card" fxFlex="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <mat-icon *ngIf="type === selectedType" style="margin:5px">check_circle
          </mat-icon>
          <div *ngIf="type !== selectedType" class="empty-circle"></div>
        </div>
        <p [ngStyle]="{'color':type === selectedType ? '#FFFFFF' : ''}" class="card-header-text">{{type.name}}</p>
      </div>
    </div>
  `,
  styleUrls: ['../../components/common-components/patient-selector/patient-selector.scss']
})
export class ProductTypeSelectorComponent implements OnInit {

  @Input() types: ProductTypeItem[];
  @Input() selectedType: ProductTypeItem;
  @Output() selectedTypeChange = new EventEmitter<ProductTypeItem>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setSelectedType(selectedType: ProductTypeItem) {
    this.selectedTypeChange.emit(selectedType);
  }
}
