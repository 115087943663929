<div fxLayout="column">
  <h2 class="mat-h2">Emergency
    Appointment</h2>
  <div mat-dialog-content>
    <div fxLayout="column">
      <p class="mat-body-2">Contact {{clinic.practiceName}} (open today
        {{openingHours}})</p>
      <button mat-raised-button color="accent" style="max-width: 300px">
        Call {{clinic.phoneNumber}}</button>
    </div>
    <p class="mat-body-2">or</p>
    <p class="mat-body-2">For out of Practice hours emergencies contact
      {{clinic.oohClinic}}</p>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px"
         fxLayoutAlign.gt-xs="space-between center">
      <button mat-raised-button color="accent">
        Call {{clinic.oohNumber}}</button>
      <button mat-stroked-button mat-dialog-close="false">Cancel</button>
    </div>
  </div>
</div>
