import {Component, OnDestroy, OnInit} from '@angular/core';
import {SignupService} from '../signup.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Angulartics2} from 'angulartics2';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../../components/shared/alert-dialog/alert-dialog.component';
import {ParseErrorHandler} from '../../../parse-error-handler';
import {AuthService} from '../../../auth/auth.service';
import {LoginClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {CreatedUserResponse} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-sms-verification',
  templateUrl: './sms-verification.component.html',
  styleUrls: ['./sms-verification.component.scss']
})
export class SmsVerificationComponent implements OnInit, OnDestroy {

  verificationForm: FormGroup;
  accountValidationMessages = this.signupService.accountValidationMessages;
  resentSms = false;
  isGroomRoom = environment.GROOM;
  clinicDetails$ = new BehaviorSubject<LoginClinicDetails>(null);
  currentUser$ = new BehaviorSubject<CreatedUserResponse>(null);
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new Subject<string>();
  private routeSub: Subscription;
  private clinicName: string;

  constructor(private signupService: SignupService, private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private angulartics2: Angulartics2, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser$ = this.signupService.foundUser$;
    this.verificationForm = new FormGroup({
      smsCode: new FormControl(null, [
        Validators.required,
        Validators.minLength(environment.VETS_4_PETS ? 4 : 6),
        Validators.maxLength(environment.VETS_4_PETS ? 4 : 6),
      ]),
    });
    this.routeSub = this.activatedRoute.data
      .subscribe((data: { clinicDetails: LoginClinicDetails }) => {
        this.clinicName = data.clinicDetails.clinicName;
        this.clinicDetails$.next(data.clinicDetails);
      });
  }

  requestNewSmsCode() {
    this.resentSms = true;
    this.signupService.resendSms();
    this.angulartics2.eventTrack.next({
      action: 'Verify SMS Resend Code',
      properties: {
        category: 'SMS Verification',
        label: this.clinicName
      }
    });
  }

  async next() {
    this.loading$.next(true);
    this.error$.next(null);
    try {
      const result = await this.signupService.verifySMSWithCode(this.verificationForm.get('smsCode').value);
      this.loading$.next(false);
      this.angulartics2.eventTrack.next({
        action: 'Verify SMS Enter Code',
        properties: {
          category: 'SMS Verification',
          label: this.clinicName
        }
      });
      this.authService.linkCognitoAccount(result.sessionToken, result.clinicCode);
    } catch (e) {
      this.showErrorDialog(ParseErrorHandler.handleParseError(e).message);
      this.loading$.next(false);
      this.angulartics2.eventTrack.next({
        action: 'Verify SMS Error',
        properties: {
          category: 'SMS Verification',
          label: this.clinicName
        }
      });
    }
  }

  showErrorDialog(error: string) {
    const dialogOptions: MatDialogConfig = new MatDialogConfig();
    dialogOptions.data = {
      title: 'Verification Error',
      message: error,
      confirmationText: 'Got it'
    };
    this.dialog.open(AlertDialogComponent, dialogOptions);
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }
}
