import {VetBookerError} from './interfaces/vberror';

export class ParseErrorHandler {
  public static handleParseError(error): VetBookerError {
    if (error.code) {
      return error;
    } else {
      try {
        const errorObj = JSON.parse(error.message);
        if (errorObj.msg) {
          return new VetBookerError(errorObj.msg, errorObj.code);
        } else {
          return new VetBookerError('An unknown error has occurred, please try again', 500);
        }
      } catch (err) {
        if (error.message) {
          return new VetBookerError(error.message, 500);
        } else {
          return new VetBookerError('An error has occurred, please try again', 500);
        }
      }
    }
  }
}
