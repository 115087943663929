import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}


// let fontFamily = environment.GROOM ? 'Nunito' : 'Roboto';
// let styles = `
// body,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// .h1,
// .h2,
// .h3,
// .h4 {
//   font-weight: 700;
//   line-height: 1.1;
//   box-sizing: border-box;
//   font-family: ${fontFamily}, "Helvetica Neue", sans-serif !important;
// }`;
// if(environment.GROOM){
//  styles +=`.mat-typography{
//   font-weight: 700;
//   line-height: 1.1;
//   box-sizing: border-box;
//   font-family: ${fontFamily}, "Helvetica Neue", sans-serif !important;
//  }
//  .mat-list-item{
//   font-weight: 900;
//   line-height: 1.1;
//   box-sizing: border-box;
//   font-family: ${fontFamily}, "Helvetica Neue", sans-serif !important;
//  }`
// }
// const node = document.createElement('style');
// node.innerHTML = styles;
// document.body.appendChild(node);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
