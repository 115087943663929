<div fxLayout="column" fxLayoutGap="20px">
  <p class="content-text">{{communicationChannelIntro}}</p>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
       fxLayoutAlign.xs="start start">
    <mat-checkbox *ngFor="let channel of channels" [disabled]="disabled"
                  (change)="onChannelChanged()"
                  [(ngModel)]="channel.allowed">{{channel.type}}</mat-checkbox>
  </div>
  <div fxLayoutAlign="center center" *ngIf="!embedded">
    <ng-container *ngIf="loading; else buttonBlock">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-progress-spinner *ngIf="loading" color="accent" diameter="40"
                              mode="indeterminate"></mat-progress-spinner>
        <p>Saving</p></div>
    </ng-container>
    <ng-template #buttonBlock>
      <button (click)="updateMarketingChannels()" color="primary"
              mat-stroked-button>Save
      </button>
    </ng-template>
  </div>
</div>
