import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {isBefore} from 'date-fns';
import {Subscription} from 'rxjs';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';
import {ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';

@Component({
  selector: 'app-groom-welfare-message',
  templateUrl: './groom-welfare-message.component.html',
  styleUrls: ['./groom-welfare-message.component.scss']
})
export class GroomWelfareMessageComponent implements OnInit, OnDestroy {
  isGroomRoom = environment.GROOM;
  isBeforeEndDate: boolean;
  endDate: Date;
  @Input() clinicDetails: ClinicDetails;
  private sub: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.isGroomRoom) {
      if (this.clinicDetails) {
        if (this.clinicDetails.emergencyEndDate) {
          this.endDate = new Date(this.clinicDetails.emergencyEndDate);
          this.isBeforeEndDate = isBefore(new Date(), this.endDate);
        }
      } else {
        this.sub = this.activatedRoute.data.subscribe((data: { clientPatientDetails: ClientPatientDetails }) => {
          this.endDate = data.clientPatientDetails.clinicDetails.emergencyEndDate ? new Date(
            data.clientPatientDetails.clinicDetails.emergencyEndDate) : null;
          if (this.endDate) {
            this.isBeforeEndDate = isBefore(new Date(), this.endDate);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

}
