import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SignupService} from '../signup/signup.service';
import {PasswordMatchErrorMatcher} from '../../components/password-matching-validator';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {Auth} from '@aws-amplify/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '../../components/shared/alert-dialog/alert-dialog.component';
import {Angulartics2} from 'angulartics2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})


export class ResetPasswordComponent implements OnInit {
  accountValidationMessages = this.signupService.accountValidationMessages;
  matcher = new PasswordMatchErrorMatcher();
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  resetPasswordForm: FormGroup;
  private token: string;
  isVets4Pets = environment.VETS_4_PETS;
  private passwordRegexp: RegExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$');
  private passwordLength: number = environment.VETS_4_PETS ? 10 : 8;
  email: string;
  private resendPin = 0;
  private pinAttempts = 0;
  resendLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private signupService: SignupService, private route: ActivatedRoute, private authService: AuthService,
              private snackBar: MatSnackBar, private matDialog: MatDialog, private angulartics2: Angulartics2) {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
      this.signupService.setEmail(params.get('email'));
      this.email = params.get('email');
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true};
  }

  ngOnInit(): void {
    const passwordValidators = [Validators.required];
    if (environment.VETS_4_PETS) {
      passwordValidators.push(Validators.pattern(this.passwordRegexp));
    } else {
      passwordValidators.push(Validators.minLength(this.passwordLength));
    }
    this.resetPasswordForm = new FormGroup({
        password: new FormControl(null, passwordValidators),
        confirmPassword: new FormControl(null,
          passwordValidators),
      },
      {validators: this.checkPasswords});
    if (this.isVets4Pets) {
      this.email = this.authService.username;
      if (!this.email) {
        this.resetPasswordForm.addControl('email',
          new FormControl(null, [Validators.email, Validators.required]));
      }
      this.resetPasswordForm.addControl('verificationCode',
        new FormControl(null, [Validators.minLength(6), Validators.maxLength(6), Validators.required]));
    }
  }

  async resetPassword() {
    this.error$.next(null);
    if (!this.isVets4Pets || this.pinAttempts < 3) {
      let token = this.token;
      if (this.isVets4Pets) {
        token = this.resetPasswordForm.get('verificationCode').value;
      }
      this.loading$.next(true);
      try {
        await this.signupService.resetPassword(token, this.resetPasswordForm.get('password').value,
          this.email || this.resetPasswordForm.get('email').value);
        this.loading$.next(false);
        this.angulartics2.eventTrack.next({
          action: 'Password Reset',
          properties: {
            category: 'Password Reminder'
          }
        });
      } catch (e) {
        this.pinAttempts++;
        this.snackBar.open('Password reset unsuccessful, please try again', null, {duration: 5000});
        this.error$.next(e.message);
        this.loading$.next(false);
        this.angulartics2.eventTrack.next({
          action: 'Password Reset Failed',
          properties: {
            category: 'Password Reminder',
            label: 'Attempt ' + this.pinAttempts
          }
        });
      }
    } else {
      this.matDialog.open(AlertDialogComponent,
        {data: {message: 'PIN Incorrect, please request a new PIN', confirmationText: 'Got it'}});
    }
  }

  resendVerification() {
    if (this.resendPin < 3) {
      this.resendLoading$.next(true);
      this.resendPin++;
      Auth.forgotPassword(this.email || this.resetPasswordForm.get('email').value).then(() => {
        this.resendLoading$.next(false);
        this.pinAttempts = 0;
        this.snackBar.open('New PIN successfully requested', null, {duration: 5000});
      }).catch(err => {
        this.resendLoading$.next(false);
        this.snackBar.open('Error requesting new pin', null, {duration: 10000});
      });
    } else {
      this.matDialog.open(AlertDialogComponent,
        {data: {message: 'You may only request a new PIN 3 times.', confirmationText: 'Got it'}});
    }
  }
}
