import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionService} from "../../services/session-service.service";
import {DateOptions} from "../../interfaces/date-options";
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  setDate,
  setDay,
  subMonths
} from "date-fns";

interface Day {
  date: Date;
  name: string;
  number: number;
  isCurrentMonth: boolean;
  isDisallowed: boolean;
  isToday: boolean;
}

interface Week {
  days: Day[];
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input('date-options') dateOptions: DateOptions;
  @Input() firstAvailable: Date;
  @Input() selectedDate: Date;
  @Output() setSelectedDate = new EventEmitter<Date>();


  isGroomRoom: boolean;
  lastDate: Date;
  month: Date;
  textColor: string;
  accentColor = 'rgb(255, 64, 129)';
  weeks: Week[] = [];
  isFirstMonth: boolean = true;
  private today: Date;

  constructor(private sessionService: SessionService) {
  }

  createCalendar() {
    this.textColor = '#FFFFFF';
    this.today = new Date();
    this.firstAvailable = addDays(this.today, this.dateOptions.minDaysBeforeBooking);
    this.selectedDate = this.selectedDate || addDays(this.firstAvailable, 1);
    if (this.dateOptions.maxDays > 0) {
      this.lastDate = addDays(this.today, this.dateOptions.maxDays);
    }
    this.month = new Date(this.selectedDate.valueOf());
    const start = setDay(setDate(this.month, 1), 0);
    this._buildMonth(start, this.month);
    this.isFirstMonth = isSameMonth(this.selectedDate, this.today);

  }

  select(day: Day) {
    let dayDisallowed = false;
    if (this.dateOptions.satDisallowed && day.date.getDay() === 6) {
      dayDisallowed = true;
    }
    if (this.dateOptions.sunDisallowed && day.date.getDay() === 0) {
      dayDisallowed = true;
    }
    if (this.dateOptions.maxDays > 0) {
      if (isAfter(day.date, this.lastDate)) {
        dayDisallowed = true;
      }
    }
    if (isBefore(day.date, this.firstAvailable)) {
      dayDisallowed = true;
    }
    if (!dayDisallowed) {
      this.selectedDate = day.date;
      this.setSelectedDate.emit(this.selectedDate);
    }
  };

  next() {
    const nextMonth = setDate(addMonths(this.month, 1), 1);
    const next = setDay(nextMonth, 0);
    this.month = nextMonth;
    this._buildMonth(next, this.month);
    this.isFirstMonth = isSameMonth(this.month, this.today);
  };

  previous() {
    const prevMonth = setDate(subMonths(this.month, 1), 1);
    const previous = setDay(prevMonth, 0);
    this.month = prevMonth;
    this._buildMonth(previous, this.month);
    this.isFirstMonth = isSameMonth(this.month, this.today);

  };

  _buildMonth(start: Date, month: Date) {
    this.weeks = [];
    let done = false;
    let date = new Date(start.valueOf());
    let monthIndex = date.getMonth(), count = 0;
    while (!done) {
      this.weeks.push({days: this._buildWeek(date, month)});
      date = addWeeks(date, 1);
      done = count++ > 2 && monthIndex !== date.getMonth();
      monthIndex = date.getMonth();
    }
  }

  _buildWeek(date: Date, originalDate: Date) {
    const days: Day[] = [];
    for (let i = 0; i < 7; i++) {
      let dayDisallowed = false;
      if (this.dateOptions.satDisallowed && date.getDay() === 6) {
        dayDisallowed = true;
      }
      if (this.dateOptions.sunDisallowed && date.getDay() === 0) {
        dayDisallowed = true;
      }
      if (this.dateOptions.maxDays > 0) {
        if (isAfter(date, this.lastDate)) {
          dayDisallowed = true;
        }
      }
      if (isBefore(date, this.firstAvailable)) {
        dayDisallowed = true;
      }
      days.push({
        name: format(date, "d"),
        number: date.getDate(),
        isCurrentMonth: isSameMonth(date, originalDate),
        isDisallowed: dayDisallowed,
        isToday: isSameDay(date, this.today),
        date: date
      });
      date = addDays(date, 1);
    }
    return days;
  }

  ngOnInit() {
    this.sessionService.clientPatientDetails$.subscribe((clientPatientDetails => {
      this.accentColor = clientPatientDetails.isGroomRoom ? '#E6007E' : 'rgb(255, 64, 129)';
      this.isGroomRoom = clientPatientDetails.isGroomRoom;
    }));
    if (this.dateOptions) {
      this.createCalendar()
    }
  }

}
