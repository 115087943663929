<div class="padded-container content-text" fxFill fxLayout="column" fxLayoutAlign="start center">
  <h1 class="big-header">Verification</h1>
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <ng-container *ngIf="clinicDetails$ | async as clinicDetails">
      <p *ngIf="!currentUser.repeatVerification; else repeatBlock"
         class="content-text"> Great news, we've found an
        existing customer record
        that matches those
        details and an online booking account has been created.</p>
      <ng-template #repeatBlock><p class="content-text full-width-form-item">Your login details were correct but your
        details have not yet been verified.</p></ng-template>
      <form [formGroup]="verificationForm">
        <p class="content-text">You have<span
          *ngIf="currentUser.smsVerification"> also</span> been sent a
          verification email to the email address provided. </p>
        <div *ngIf="!clinicDetails.isGroomRoom">
          <p class="content-text">Alternatively you may enter the code from the
            email below. Note - if you have
            requested multiple
            verification codes, only the most recent will work</p>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Email verification code</mat-label>
            <input formControlName="emailCode" matInput placeholder="Email code"
                   required>
            <mat-error
              *ngFor="let validation of accountValidationMessages.verificationCode">
              <mat-error
                *ngIf="verificationForm.get('emailCode').hasError(validation.type) && (verificationForm.get('emailCode').dirty || verificationForm.get('emailCode').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="center center"
               style="margin-bottom: 10px">
            <button (click)="verifyEmail()" color="primary" mat-stroked-button>
              Verify Email
            </button>
          </div>
        </div>
        <p class="content-text">If you have you not received your
          verification email within 15 minutes, please check your spam/junk
          folder. Otherwise, use the button below to resend the email. </p>
        <button (click)="requestNewEmailCode()"
                *ngIf="!resentEmail; else emailResentBlock"
                class="full-width-form-item" color="primary" mat-raised-button>
          Request a new Email code
        </button>
        <ng-template #emailResentBlock><p class="content-text">Repeat Email
          verification request sent</p></ng-template>
      </form>
    </ng-container>
  </ng-container>
</div>
