import {Component, Input, OnInit} from '@angular/core';
import {VbPatient, VbPatientReminder} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-reminders',
  template: `
    <mat-card class="detail-card" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <ng-container *ngIf="reminders?.length>0; else emptyReminders">
        <div *ngFor="let reminder of reminders" class="detail-row" fxFlex="45%"
             fxFlex.lt-md="100%" fxLayout="row" fxLayoutAlign="space-between center">
          <p class="title-row">{{reminder.name}}</p>
          <p class="title-content">{{reminder.date | dfnsFormat:'dd/MM/yyyy'}}</p>
        </div>
      </ng-container>
      <ng-template #emptyReminders>
        <p class="title-content">No reminders found for {{patient.name}}</p>
      </ng-template>
    </mat-card>
  `,
  styleUrls: ['../patient-detail/patient-detail.component.scss']
})
export class RemindersComponent implements OnInit {
  @Input() reminders: VbPatientReminder[];
  @Input() patient: VbPatient;

  constructor() {
  }

  ngOnInit(): void {
  }

}
