<ng-container *ngIf="isGroomRoom && isBeforeEndDate">
  <div class="groom-banner">
    <!--    <ng-container *ngIf="!clinicDetails"><h2 style="font-size:-->
    <!--    22px">Welfare grooms</h2></ng-container> -->
    <h2 style="font-size:22px">Welfare grooms</h2>
    <p>In line with government guidelines, up until {{endDate |
      dfnsFormat: 'dd/MM/yyyy'}} this salon is only offering
      grooming services where the dog meets at least one of six welfare reasons
      outlined at booking. For help and
      advice on grooming your dog at home, please visit <a style="color: white"
                                                           href="https://www.petsathome.com/shop/en/pets/advice/doggroomingadvice"
                                                           target="_blank">petsathome.com.</a>
    </p></div>
</ng-container>
