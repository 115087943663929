<div fxLayout="column" style="min-width: 200px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2" mat-dialog-title>Download our app</h2>
    <button mat-icon-button color="primary" mat-dialog-close="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" mat-dialog-content style="overflow:hidden ">
    <p class="mat-body-1">Download our free V4P app to get access to help you manage your pets health, including
      access to appointment bookings, vaccination history, and offers and advice tailored to your pet</p>
  </div>
  <div fxLayout="column" mat-dialog-actions fxLayout="center center">
    <div fxLayout="row wrap" fxLayoutAlign="center center"><a
      href='https://play.google.com/store/apps/details?id=com.vets4pets&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
      alt='Get it on Google Play'
      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' height="60"/></a>
      <a href="https://apps.apple.com/gb/app/vets4pets/id1370327566?mt=8"
         style="padding:5px;display:inline-block;overflow:hidden;"><img
        src="https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-02-21&kind=iossoftware&bubble=ios_apps"
        height="40px"></a>
    </div>
  </div>
</div>
