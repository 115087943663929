<div fxLayout="column" fxLayoutAlign="center center">
  <mat-card class="centered-card">
    <mat-card-content>
      <h2 class="big-header">Verification</h2>
      <ng-template #loadingBlock>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <mat-progress-spinner color="primary" diameter="40"
                                mode="indeterminate"></mat-progress-spinner>
          <p class="content-text">Verifying details, please wait</p>
        </div>
      </ng-template>
      <ng-container *ngIf="error$ |async as error; else loadingBlock">
        <div><p class="content-text">Unfortunately there
          was an error verifying your account, please try again or contact
          support</p>
          <p class="content-text">{{error.message}}</p></div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
