import {Component, OnDestroy, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SessionService} from '../../services/session-service.service';
import {AppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {FirstAvailableSlot, PurePatient} from '@appyvet/vetbooker-definitions/dist/appointments';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-next-available-card',
  templateUrl: './next-available-card.component.html',
  styleUrls: ['./next-available-card.component.scss']
})
export class NextAvailableCardComponent implements OnInit, OnDestroy {
  slots$ = new BehaviorSubject<FirstAvailableSlot[]>([]);
  loading$ = new BehaviorSubject<boolean>(true);
  clientNumber$ = new BehaviorSubject<string>(null);
  patients$ = new BehaviorSubject<PurePatient[]>([]);
  selectedPatient: VbExtendedPatient;
  resource$ = new BehaviorSubject<Resource>(null);
  apptType$ = new BehaviorSubject<AppointmentType>(null);
  private firstApptsSubscription: Subscription;
  private sessionSubscription: Subscription;

  constructor(private bookingService: BookingService, private sessionService: SessionService) {
  }

  ngOnInit() {
    this.sessionSubscription = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.clientNumber$.next(clientPatientDetails.clientData.client.clientNumber);
        this.patients$.next(clientPatientDetails.clientData.patients.map(
          patient => this.bookingService.convertExtendPatientToPurePatient(patient)));
        this.firstApptsSubscription = this.bookingService.getFirstAvailableAppointment$(
          {isVaccine: false, numberOfResults: 1})
          .subscribe(firstAppts => {
            if (firstAppts) {
              this.loading$.next(false);
              this.slots$.next(firstAppts.slots);
              this.resource$.next(
                clientPatientDetails.resources.find(resource => resource.objectId === firstAppts.resource));
              this.apptType$.next(
                clientPatientDetails.appointmentTypes.find(apptType => apptType.objectId === firstAppts.apptType));
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.firstApptsSubscription?.unsubscribe();
    this.sessionSubscription?.unsubscribe();
  }

}
