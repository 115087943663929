import {Component, OnInit} from '@angular/core';
import {PatientService} from '../patient.service';
import {BehaviorSubject} from 'rxjs';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {

  patients$ = new BehaviorSubject<VbExtendedPatient[]>(null);
  canAddPet: boolean;

  constructor(public patientService: PatientService) {
  }

  ngOnInit(): void {
    this.patientService.patients$.subscribe(patients => {
      if (patients) {
        this.patients$.next(patients);
      }
    });
  }

}
