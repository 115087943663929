<div flex fxLayout="column" fxLayoutAlign="center center">
  <mat-toolbar class="card-title padding-10" color="primary" fxFlexFill
               fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="previous()" [disabled]="isFirstMonth" aria-label="previous" mat-button style="min-width:42px"
            type="button">
      <mat-icon [ngClass]="{'text-color' : !isGroomRoom, 'groom-room-text' : isGroomRoom}">arrow_back</mat-icon>
    </button>
    <h3 [ngClass]="{'text-color' : !isGroomRoom, 'groom-room-text' : isGroomRoom}" class="monthName">
      {{month | dfnsFormat: "MMMM yyyy"}} </h3>
    <button (click)="next()" aria-label="next" mat-button style="min-width:42px" type="button">
      <mat-icon [ngClass]="{'text-color' : !isGroomRoom, 'groom-room-text' : isGroomRoom}">arrow_forward</mat-icon>
    </button>
  </mat-toolbar>
  <table class="calendar">
    <thead>
    <tr class="days">
      <th>Sun</th>
      <th>Mon</th>
      <th>Tue</th>
      <th>Wed</th>
      <th>Thu</th>
      <th>Fri</th>
      <th>Sat</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let week of weeks" class="week">
      <td (click)="select(day)" *ngFor="let day of week.days"
          [ngClass]="{'different-month': !day.isCurrentMonth || day.isDisallowed} "
          [ngStyle]="{'color':(day.date | dfnsDifferenceInCalendarDays: selectedDate) === 0 ? 'white' : null, 'background-color' :(day.date | dfnsDifferenceInCalendarDays: selectedDate) === 0 ? accentColor : null}"
          class="day">{{day.number}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
