import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-transfer-pets',
  templateUrl: './transfer-pets.component.html',
  styleUrls: ['./transfer-pets.component.scss']
})
export class TransferPetsComponent implements OnInit {
  @Input() transferToClinicName: string;
  @Input() transferFromClinicName: string;
  @Input() fromPets: VbPatient[];
  @Input() toPets: VbPatient[];
  @Output() confirmPets = new EventEmitter<VbPatient[]>();
  @Output() cancel = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    this.fromPets?.forEach(pet => {
      pet.isSelected = true;
    });
  }

  back() {
    this.cancel.emit(true);
  }

  next() {
    const selectedPets: VbPatient[] = [];
    this.fromPets.forEach(pet => {
      if (pet.isSelected) {
        selectedPets.push(pet);
      }
    });
    this.toPets.forEach(pet => {
      if (pet.isSelected) {
        selectedPets.push(pet);
      }
    });
    this.confirmPets.emit(selectedPets);
  }
}
