import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {SessionService} from "../services/session-service.service";
import * as Parse from "parse";
import {CookieService} from "ngx-cookie-service";
import {
  MedicationItem,
  PrescriptionRequestParams,
  ProductTypeItem
} from '@appyvet/vetbooker-definitions/dist/prescriptions';
import {VbPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {Prescriptions} from '@appyvet/vetbooker-definitions/dist/client_patient_details';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  selectedPatient$ = new BehaviorSubject<VbPatient>(null);
  selectedMedication$ = new BehaviorSubject<MedicationItem>(null);
  showType: boolean = true;
  prescriptionSettings$ = new BehaviorSubject<Prescriptions>(null);
  productTypes: ProductTypeItem[] = [{
    name: 'Food',
    label: 'Item size (e.g. 5Kg bag or 400g tin)'
  }, {
    name: 'Medication',
    label: 'Strength and/or size (e.g. 400mg or 100ml)'
  }, {name: 'Flea/Worm/Tick Treatment', label: 'Strength or pack size (e.g. 10-25Kg)'}];
  resources$ = new BehaviorSubject<Resource[]>([]);

  constructor(private sessionService: SessionService, private cookieService: CookieService) {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.prescriptionSettings$.next(clientPatientDetails.prescriptions);
      this.resources$.next(clientPatientDetails.resources.filter(resource => resource.prescriptions));
    });
  }

  setSelectedPatient(patient: VbPatient) {
    this.selectedPatient$.next(patient);
  }

  setActiveMedicationRequest(patient: VbPatient, medication: MedicationItem, showType: boolean) {
    this.selectedPatient$.next(patient);
    this.selectedMedication$.next(medication);
    this.showType = showType;
  }

  clearActiveMedicationRequest() {
    this.selectedPatient$.next(null);
    this.selectedMedication$.next(null);
    this.showType = false;
  }

  orderProduct$(resource: Resource, itemNumber: number, strength: string, itemName: string, notes: string, reason: string): Observable<boolean> {
    const orderProductRequest$ = new Subject<boolean>();
    const request: PrescriptionRequestParams = {
      resourceId: resource.objectId,
      patientNumber: this.selectedPatient$.getValue().patientNumber,
      patientName: this.selectedPatient$.getValue().name,
      number: itemNumber.toString(),
      strength: strength,
      itemName: itemName,
      notes: notes,
      reason: reason
    };
    Parse.Cloud.run('addPrescriptionRequest', request, {sessionToken: this.cookieService.get("sessionToken")}).then(() => {
      this.clearActiveMedicationRequest();
      orderProductRequest$.next(true);
    }, error => {
      orderProductRequest$.error(error);
    });
    return orderProductRequest$;
  }
}
