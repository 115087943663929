<div class="padding-10" fxLayout="column" fxLayoutGap="20px"
     fxLayoutGap.xs="10px" style="max-width: 1140px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="full-width-xs" fxLayout="row" fxLayout.xs="column"
         fxLayoutAlign="start center" fxLayoutAlign.xs="center center"
         fxLayoutGap="20px">
      <h1 class="section-header header-width">{{patient.name}}</h1>
      <ng-container *ngIf="patient.photo || patient.extraInfo?.photoBase64">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <ng-container
            *ngIf="!(imageLoading$ | async); else imageLoadingBlock0">
            <ng-container *ngIf="patient.extraInfo?.photoBase64; else urlBlock">
              <div fxLayout="column">
                <div [style.background-image]="patient.extraInfo.photoBase64 |
            safeResourceUrl" class="pet-photo-large background-image"></div>
              </div>
            </ng-container>
            <ng-template #urlBlock>
              <div [ngStyle]="{'background' : patient.photo?.url() | backImg}"
                   class="pet-photo-large background-image pet-photo-round"></div>
            </ng-template>
            <div fxLayout="column">
              <button (click)="editPhoto()" color="primary" mat-stroked-button>
                Edit
              </button>
              <button (click)="deletePhoto()" color="warn" mat-stroked-button>
                {{isVets4Pets ? 'Replace' : 'Delete'}}
              </button>
            </div>
          </ng-container>
          <ng-template #imageLoadingBlock0>
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            <p>Uploading image</p>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!patient.photo && !patient.extraInfo?.photoBase64 &&
    allowPatientImages">
      <ng-container *ngIf="!(imageLoading$ | async); else imageLoadingBlock">
        <div *ngxInputFile="options" class="dropZoneColorDrop drop-zone"
             fxLayoutAlign="center center" ngxDragAndDrop>
          <span fxHide.lt-md>Drop a photo here, or press to select one</span>
          <span fxHide.gt-md fxHide.md>Press to add a photo</span>
        </div>
      </ng-container>
      <ng-template #imageLoadingBlock>
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p>Uploading image</p>
      </ng-template>
    </ng-container>
  </div>
  <ng-container *ngIf="canBook || customPrescriptions">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Quick Actions</h3>
      <mat-card class="detail-card" fxLayout="column">
        <ng-container *ngIf="!disableQuickBook && canBook">
          <app-quick-book-card [apptType]="apptType" [resource]="resource"
                               [buttonText]="'Book First Available'"
                               [slot]="slot"></app-quick-book-card>
          <div class="divider"></div>
        </ng-container>
        <div fxLayout="row" fxLayout.xs="column"
             [fxLayoutAlign]="isGroomRoom ? 'end center' : 'space-between center'"
             fxLayoutGap="20px">
          <ng-container *ngIf="canBook">
            <button [queryParams]="{patientNumber:patient.patientNumber}"
                    (click)="bookApptLink()" [routerLink]="'/booking'"
                    color="primary" mat-raised-button>
              {{isGroomRoom ? 'Book' : 'Custom'}} appointment
            </button>
          </ng-container>
          <ng-container *ngIf="customPrescriptions">
            <button [queryParams]="{patientNumber:patient.patientNumber}"
                    (click)="orderProductLink()" [routerLink]="'/order'"
                    color="primary" mat-raised-button>
              Order product
            </button>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </ng-container>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Pet Details</h3>
    <mat-card class="detail-card">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div class="detail-row" fxFlex.lt-md="100%" fxLayout="row"
             fxLayoutAlign.lt-md="space-between center"
             fxLayoutAlign="start center" fxLayoutGap.gt-md="20px">
          <p class="patient-detail-title">Breed:</p>
          <p class="title-content">{{patient.breedItem.name}}</p>
        </div>
        <div class="detail-row" fxFlex.lt-md="100%" fxLayout="row"
             fxLayoutAlign.lt-md="space-between center"
             fxLayoutAlign="start center" fxLayoutGap.gt-md="20px">
          <p class="patient-detail-title">Date of Birth:</p>
          <p class="title-content">{{getDateOfBirth()}}</p>
        </div>
        <ng-container *ngIf="showColor">
          <div class="detail-row" fxFlex.lt-md="100%" fxLayout="row"
               fxLayoutAlign.lt-md="space-between center"
               fxLayoutAlign="start center" fxLayoutGap.gt-md="20px">
            <p class="patient-detail-title">Color:</p>
            <p class="title-content">{{patient.color}}</p>
          </div>
        </ng-container>
        <div class="detail-row" fxFlex.lt-md="100%" fxLayout="row"
             fxLayoutAlign.lt-md="space-between center"
             fxLayoutAlign="start center" fxLayoutGap.gt-md="20px">
          <p class="patient-detail-title">Sex:</p>
          <p
            class="title-content">{{patient.sex}} {{patient.desexed ? 'Neutered' : 'Entire'}}</p>
        </div>
        <div *ngIf="patientService.canEditPatient$ | async"
             class="margin-top-xs full-width-xs" fxLayoutAlign.xs="end center">
          <button [queryParams]="{patientNumber:patient.patientNumber}"
                  (click)="editPet()" [routerLink]="'edit'" color="primary"
                  mat-stroked-button>Edit
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <ng-container *ngIf="isVets4Pets">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Pet Bio</h3>
      <app-pet-bio [bio]="patient.extraInfo?.petBio"
                   (saveBio)="updateBio($event)" [loading]="bioLoading$ | async"
                   class="full-width-form-item"></app-pet-bio>
    </div>
  </ng-container>
  <ng-container *ngIf="isVets4Pets">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Flea & Worm</h3>
      <app-flea-worm-tx [parasiteHistory]="patient.extraInfo?.parasiteHistory"
                        class="full-width-form-item"></app-flea-worm-tx>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideReminders">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Reminders</h3>
      <ng-container *ngIf="patient.extraInfo; else remindersLoadingBlock">
        <app-reminders [patient]="patient"
                       [reminders]="patient.extraInfo.reminders"
                       class="full-width-form-item"></app-reminders>
      </ng-container>
      <ng-template #remindersLoadingBlock>
        <mat-progress-spinner color="primary" mode="indeterminate"
                              diameter="30"></mat-progress-spinner>
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideInsurance">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Insurance</h3>
      <ng-container *ngIf="patient.extraInfo; else insuranceLoadingBlock">
        <app-insurance-policies
          [insurancePolicies]="patient.extraInfo.insurancePolicies"
          [patient]="patient"
          class="full-width-form-item"></app-insurance-policies>
      </ng-container>
      <ng-template #insuranceLoadingBlock>
        <mat-progress-spinner color="primary" mode="indeterminate"
                              diameter="30"></mat-progress-spinner>
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideCarePlans">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
         fxLayoutGap.lt-xl="20px">
      <h3 class="title-header">Care Plans</h3>
      <ng-container *ngIf="patient.extraInfo; else careplanLoadingBlock">
        <app-care-plans [carePlans]="patient.extraInfo.carePlans"
                        [patient]="patient"
                        class="full-width-form-item"></app-care-plans>
      </ng-container>
      <ng-template #careplanLoadingBlock>
        <mat-progress-spinner color="primary" mode="indeterminate"
                              diameter="30"></mat-progress-spinner>
      </ng-template>
    </div>
  </ng-container>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
       fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Visits</h3>
    <mat-card class="detail-card" fxLayout="row" fxLayout.lt-md="column"
              fxLayoutAlign="space-between center">
      <div class="detail-row" fxLayout="row" fxLayoutAlign="start center"
           fxLayoutAlign.xs="space-between center">
        <p class="item-title item-start" style="min-width:130px">Last Visit:</p>
        <ng-container *ngIf="patient.extraInfo; else visitLoadingBlock">
          <p class="title-content">{{getLastVisitDate()}}</p></ng-container>
        <ng-template #visitLoadingBlock>
          <mat-progress-spinner color="primary" mode="indeterminate"
                                diameter="30"></mat-progress-spinner>
        </ng-template>
      </div>
      <button *ngIf="canViewHistory && patient.extraInfo?.lastVisitDate"
              [routerLink]="'history'" class="margin-top-xs" color="primary"
              mat-stroked-button>
        View Visit History
      </button>
    </mat-card>
  </div>
  <div *ngIf="vaccineHistoryEnabled" fxLayout="row" fxLayout.xs="column"
       fxLayoutAlign="start center" fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Vaccination History</h3>
    <ng-container *ngIf="patient.extraInfo; else vaccineLoadingBlock">
      <app-vaccinations [vaccinations]="patient.extraInfo.vaccinations"
                        [name]="patient.name"
                        [reminders]="patient.extraInfo.reminders"
                        class="full-width-form-item"></app-vaccinations>
    </ng-container>
    <ng-template #vaccineLoadingBlock>
      <mat-progress-spinner color="primary" mode="indeterminate"
                            diameter="30"></mat-progress-spinner>
    </ng-template>
  </div>

  <div *ngIf="canDeactivatePatient" fxLayout="row" fxLayout.xs="column"
       fxLayoutAlign="start center" fxLayoutGap.lt-xl="20px">
    <h3 class="title-header">Remove Pet</h3>

    <mat-card class="detail-card" fxLayout="row" fxLayout.lt-md="column"
              fxLayoutAlign="space-between center">
      <ng-container *ngIf="deactivateLoading$ | async; else contentBlock">
        <mat-progress-spinner color="primary" mode="indeterminate"
                              diameter="30"></mat-progress-spinner>
      </ng-container>
      <ng-template #contentBlock>
        <div class="detail-row" fxLayout="column" fxLayoutAlign="start center">
          If you would like to remove {{patient.name}} from your account, please
          click the button below. Unfortunately, this action cannot be undone.
          <button mat-raised-button color="warn" style="margin-top: 20px"
                  (click)=removePet()>Remove pet
          </button>
        </div>
      </ng-template>
    </mat-card>
  </div>

</div>
