<div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
  <h2 class="title primary-text-color" mat-dialog-title>Correct {{typeName}}
    ?</h2>
  <button mat-icon-button color="primary" mat-dialog-close="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content style="overflow:hidden ">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px">
    <p class="mat-body-1 primary-text-color">It looks like you have chosen to
      log in to an account which is not associated with your currently
      selected {{typeName.toLowerCase()}}. Do you wish to {{isVets4Pets ?
        'register as a new client with that surgery' :
        'transfer your registered salon'}} or log in to your registered
      {{isVets4Pets ? 'surgery' : 'salon'}}?</p>
    <div style="margin-bottom:20px" fxLayout="row" fxLayout.xs="column"
         fxLayoutAlign="space-between center" fxLayoutGap.xs="10px">
      <ng-container *ngIf="isVets4Pets; else transferBtn">
        <button [mat-dialog-close]="EXIT" color="accent" mat-stroked-button
                class="full-width-xs">
          Go Back to Register
        </button>
      </ng-container>
      <ng-template #transferBtn>
        <button [mat-dialog-close]="TRANSFER" color="accent" mat-stroked-button
                class="full-width-xs">
          Transfer Salon
        </button>
      </ng-template>
      <button [mat-dialog-close]="LOGIN" color="primary" mat-raised-button
              class="full-width-xs">
        Continue
      </button>
    </div>
  </div>
</div>
