import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Pipe({
  name: 'backImg'
})
export class BackgroundUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeStyle {
    if (value) {
      return this.sanitizer.sanitize(SecurityContext.STYLE, 'url(' + value + ')');
    }
  }
}
