<div class="padding-10" fxFill fxLayout="row"
     fxLayoutAlign="space-between center" style="min-height: 65px!important"
     [ngClass]="isVets4Pets ?
     'primary-text-color' : ' primary-background'">
  <h1 class="mat-h1 no-margin">{{title}}</h1>
  <button [mat-dialog-close]="true" aria-label="close" mat-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="padding-10">
  <div *ngIf="!(loading$ | async)" fxLayout="column">
    <p *ngIf="!isEdit" class="mat-body-1">This is to confirm that you want to
      cancel your appointment for
      {{appointment.patientDetails}} on {{appointment.dateString}} at
      {{appointment.resourceName}} {{isGroomRoom ? 'Salon' : ''}}. </p>
    <p *ngIf="isEdit" class="mat-body-1">{{editText}}</p>
    <form [formGroup]="bookingForm" novalidate>
      <mat-form-field *ngIf="showCancellationReason" fxFill>
        <mat-label>Reason for Cancellation</mat-label>
        <mat-select [formControlName]="'cancellationReason'" required>
          <mat-option *ngFor="let reason of cancellationReasons"
                      [value]="reason">
            {{ reason.displayName || reason.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div *ngIf="error$ | async" class="accent-color"><p>{{error$ | async}}</p>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center"
         fxLayoutGap.xs="10px">
      <button [mat-dialog-close]="false" class="full-width-xs"
              aria-label="no cancel"
              mat-stroked-button>{{isGroomRoom ? 'Go Back' : 'Keep appointment'}}
      </button>
      <button (click)="cancelAppointment()" [disabled]="!bookingForm.valid"
              class="full-width-xs" aria-label="yes cancel" color="primary"
              mat-raised-button>Cancel
        appointment
      </button>
    </div>
  </div>
  <div *ngIf="loading$ | async" fxLayout="column" fxLayoutAlign="center center"
       style="min-height:200px">
    <div fxLayout="row" fxLayoutAlign="space-around">
      <mat-progress-spinner color="primary"
                            mode="indeterminate"></mat-progress-spinner>
    </div>
    <p *ngIf="isEdit" class="mat-body-2">Amending Appointment</p>
    <p *ngIf="!isEdit" class="mat-body-2">Cancel Appointment</p>
  </div>
</div>
