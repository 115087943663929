import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PASSWORD} from '../../registration/additional-registration-info/additional-registration-info.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordMatchErrorMatcher} from '../../components/password-matching-validator';
import {environment} from '../../../environments/environment';
import {UpdatePasswordParams} from '@appyvet/vetbooker-definitions/dist/register_user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  matcher = new PasswordMatchErrorMatcher();
  private passwordLength = environment.VETS_4_PETS ? 10 : 8;
  passwordValidationMessages = {
    password: [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least ' + this.passwordLength + ' characters long'},
      {type: 'mismatch', message: 'Passwords must match'}, {
        type: 'pattern',
        message: environment.VETS_4_PETS ? 'Min 10 characters with at least 1 capital letter and 1 number' : ''
      },
    ],
    confirmPassword: [
      {type: 'required', message: 'Confirmed Password is required'},
      {type: 'minlength', message: 'Password must be at least ' + this.passwordLength + ' characters long'}, {
        type: 'pattern',
        message: environment.VETS_4_PETS ? 'Min 10 characters with at least 1 capital letter and 1 number' : ''
      },
    ],
  };
  private passwordRegexp: RegExp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$');
  PASSWORD = PASSWORD;
  passwordForm: FormGroup;
  @Input() loading: boolean;
  @Output() passwordSaved = new EventEmitter<UpdatePasswordParams>();

  constructor() {
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get(PASSWORD).value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true};
  }

  ngOnInit(): void {
    const passwordValidators = [Validators.required];
    if (environment.VETS_4_PETS) {
      passwordValidators.push(Validators.pattern(this.passwordRegexp));
    } else {
      passwordValidators.push(Validators.minLength(this.passwordLength));
    }
    this.passwordForm = new FormGroup({
        Password: new FormControl('', passwordValidators),
        confirmPassword: new FormControl('', passwordValidators),
        oldPassword: new FormControl('', [Validators.required, Validators.minLength(this.passwordLength)])
      },
      {validators: this.checkPasswords});
  }

  savePassword() {
    this.passwordSaved.emit(
      {oldPassword: this.passwordForm.get('oldPassword').value, newPassword: this.passwordForm.get('Password').value});
  }
}
