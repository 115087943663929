import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../../../patients/patient.service';
import {Subscription} from 'rxjs';
import {Angulartics2} from 'angulartics2';
import {VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';

@Component({
  selector: 'app-pets-carousel',
  templateUrl: './pets-carousel.component.html',
  styleUrls: ['./pets-carousel.component.scss']
})
export class PetsCarouselComponent implements OnInit, OnDestroy {
  patients: VbExtendedPatient[];
  private patientsSub: Subscription;
  private clinicName: string;

  constructor(private patientService: PatientService, private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.clinicName = this.patientService.clinicName;
    this.patientsSub = this.patientService.patients$.subscribe(patients => {
      this.patients = patients;
    });
  }

  ngOnDestroy(): void {
    this.patientsSub?.unsubscribe();
  }

  petSelected() {
    this.angulartics2.eventTrack.next({
      action: 'Pet Selected',
      properties: {
        category: 'Landing',
        label: this.clinicName
      }
    });
  }
}
