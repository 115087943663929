<app-groom-welfare-message></app-groom-welfare-message>
<div class="padding-10" fxLayout="column" fxLayoutAlign="start start">
  <div class="container" fxLayout="column" fxLayoutAlign="start start">
    <h2 class="big-header"><span
      *ngIf="!(isEdit$ | async)">Book new </span><span *ngIf="isEdit$ | async">Amend </span>appointment
    </h2>
    <div *ngIf="(editing$ | async) || currentAppointment">
      <p *ngIf="isGroomRoom" class="content-text">You can book an appointment up
        to 8 weeks in advance. To book further
        ahead, please contact
        your salon directly.</p>
      <div *ngIf="!isReferral && !isGroomRoom" fxLayout="column"
           fxLayoutAlign="start start">
        <p *ngIf="bookerText && bookerText.length>0 && !isReferral"
           class="content-text padding-20">
          {{bookerText}}
        </p>
        <p *ngIf="!isReferral" class="content-text padding-20"><b
          class="warning-label">Please
          note</b> {{bookerWarning}} </p>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <div class="container" fxLayout="column" fxLayoutAlign="start start">
    <ng-container *ngIf="patients$ | async as patients;">
      <ng-container *ngIf="patients.length >0; else noPatientsBlock">
        <h3 [ngPlural]="(selectedPatients$|async).length"
            class="section-header">
          Select
          <ng-template ngPluralCase="=0">Pet</ng-template>
          <ng-template ngPluralCase="=1">Pet</ng-template>
          <ng-template ngPluralCase="other">Pets</ng-template>
        </h3>
        <app-patient-selector (patientSelected)="selectPatient($event, true)"
                              [patients]="patients"
                              fxFill></app-patient-selector>
      </ng-container>
      <ng-template #noPatientsBlock>
        <p class="content-text">You have no pets currently registered. Please
          add one in order to book an appointment</p>
        <button mat-raised-button color="primary" [routerLink]="'/pets/new'">
          Add Pet
        </button>
      </ng-template>
    </ng-container>
  </div>
  <div *ngIf="selectedPatients.length>0" class="container" fxLayout="column"
       fxLayoutAlign="start start">
    <h3 class="section-header">Select {{isGroomRoom ? 'Groom' : 'Appointment'}}
      Type</h3>
    <ng-container *ngIf="isGroomRoom">
      <div (click)="showBookerTypeHelper()" fxLayout="column"
           fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start"><h4
          class="groom-room-text">Which groom is right
          for my dog</h4>
          <mat-icon class="groom-room-text clickable" style="margin-left:10px;">
            help
          </mat-icon>
        </div>
        <p class="no-margin">The type of groom your pet needs can depend upon
          their coat type. <span class="groom-room-text clickable"
                                 fxHide.gt-xs="">Touch here</span><span
            class="clickable groom-room-text" fxHide.xs>Click here</span> to see
          our grooming guide.</p>
      </div>
      <app-welfare-guidance
        *ngIf="isBeforeEmergencyEndDate"></app-welfare-guidance>
    </ng-container>
    <div *ngFor="let selectedPatientItem of (selectedPatients$ | async)" fxFill
         fxLayout="column">
      <ng-container *ngIf="selectedPatientItem.patient">
        <h2>{{selectedPatientItem.patient.patientName}}</h2></ng-container>
      <ng-container *ngIf="isGroomRoom &&
      (selectedPatientItem.patient.ageInWeeks <12 ||
      (selectedPatientItem.patient.ageInWeeks >=12 &&
      selectedPatientItem.patient.ageInWeeks<27))">
        <div class="padding-20" fxLayout="column" fxLayoutAlign="center start">
          <div *ngIf="selectedPatientItem.patient.ageInWeeks <12"
               class="padding-left" fxLayout="row"
               fxLayoutAlign="center center">
            <mat-icon color="accent" style="margin-right:10px">error_outline
            </mat-icon>
            <div fxLayout="column">
              <p class="groom-room-text no-margin">
                {{selectedPatientItem.patient.patientName}} is too young for a
                groom right now, however you can book in for a Puppy Groom
                from {{getPatientMinAge(selectedPatientItem.patient)}}. The
                first date you can book an adult groom will
                be {{getFirstAdultBookableDate(selectedPatientItem.patient)}}</p>
              <p *ngIf="getAdultDogs().length >0"
                 class="groom-room-text no-margin">If you
                wish to bring {{getAdultDogsNames()}} to us before this date,
                please remove {{selectedPatientItem.patient.patientName}} from
                the selection.</p>
            </div>
          </div>
          <div
            *ngIf="selectedPatientItem.patient.ageInWeeks >=12 && selectedPatientItem.patient.ageInWeeks<27"
            class="padding-left" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon color="accent" style="margin-right:10px">error_outline
            </mat-icon>
            <div fxLayout="column">
              <p class="groom-room-text no-margin">Our Puppy Groom service is
                suitable for puppies aged 3-6 months
                old. {{selectedPatientItem.patient.patientName}}
                can be booked for a Puppy Groom until
                {{getPatientMaxAge(selectedPatientItem.patient)}}
                after which an adult groom will need to be booked</p>
              <p *ngIf="getAdultDogs().length >0"
                 class="groom-room-text no-margin"> If you wish to
                bring {{selectedPatientItem.patient.patientName}} with your
                other dogs
                after {{getPatientMaxAge(selectedPatientItem.patient)}} please
                select an adult groom.</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="card-option-container" fxFill fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutAlign.lt-md="start center">
        <ng-container *ngIf="getAppointmentTypes(selectedPatientItem) as
        apptTypes">
          <div *ngFor="let appointmentType of apptTypes" [class.selected-card]="
            selectedPatientItem.selectedAppointmentType?.objectId ===
            appointmentType.objectId" class="selection-card" fxFlex="45%"
               fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex fxLayout="row" style="height:60px"
                 fxLayoutAlign="start center"
                 (click)="setSelectedAppointmentType(selectedPatientItem,appointmentType, true);">
              <div style="min-width: 30px; padding:0 10px">
                <mat-icon *ngIf="selectedPatientItem.selectedAppointmentType?.objectId ===
                appointmentType.objectId" style="margin:5px">check_circle
                </mat-icon>
                <div *ngIf="selectedPatientItem.selectedAppointmentType?.objectId !==
                appointmentType.objectId" class="empty-circle"></div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center"
                   style="width:100%"><p
                [ngStyle]="{'color':selectedPatientItem.selectedAppointmentType?.objectId === appointmentType.objectId ? '#FFFFFF' : ''}"
                class="card-header-text">{{appointmentType.displayName}}</p>
              </div>
            </div>
            <ng-container *ngIf="appointmentType.description">
              <mat-icon (click)="showAppointmentDescription(appointmentType)"
                        [ngClass]="selectedPatientItem.selectedAppointmentType?.objectId
                         ===appointmentType.objectId ? 'selected-card' :
                         'accent-text-color'" class="clickable"
                        style="margin-right: 10px">info
              </mat-icon>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="resources.length>1" class="container" fxLayout="column"
       fxLayoutAlign="start start">
    <h3 class="section-header">Select Where</h3>
    <app-resource-selector
      (selectedResourceChange)="setSelectedResource($event, true)"
      [resources]="resources" [selectedResource]="selectedResource"
      fxFlexFill></app-resource-selector>
  </div>
  <ng-container *ngIf="error$|async as error">
    <p class="error-text content-text">{{error}}</p>
  </ng-container>
  <ng-container *ngIf="(loading$ | async) || (clipboardSearchResults$ | async) as
    clipboardSearchResult">
    <div class="container" fxLayout="column" fxLayoutAlign="start start">
      <h3 class="section-header">Select Time</h3>
      <div class="card-option-container" fxFill fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutAlign.lt-md="center center">
        <div class="calendar-card" fxFill fxLayout="row" fxLayout.xs="column"
             fxLayoutGap="20px">
          <div class="full-width-form-item" fxLayout="column">
            <ng-container *ngIf="loading$ | async; else calendarBlock">
              <div fxLayout="row" fxLayoutAlign="center center"
                   fxLayoutGap="20px" style="min-height: 200px;width:100%">
                <mat-progress-spinner color="primary" diameter="40"
                                      mode="indeterminate"></mat-progress-spinner>
                <span class="mat-body-2">Searching for available appointment times</span>
              </div>
            </ng-container>
            <ng-template #calendarBlock>
              <app-new-calendar (monthChange)="onMonthChange($event)"
                                (setSelectedDate)="setBookerDay($event, true)"
                                [dateOptions]="dateOptions$ | async"
                                [isRequestType]="isRequestType$ | async"
                                [searchResults]="clipboardSearchResult"
                                [selectedDate]="selectedDate$ | async"></app-new-calendar>
            </ng-template>
          </div>
          <div *ngIf="!(loading$ | async)" fxLayout="column" [fxLayoutAlign]="isGroomRoom ? 'space-around center' :
               'center center'" style="width:100%">
            <div *ngIf="price" class="padding-10">
              <p class="accent-text-color content-text">
                Estimated cost for the groom is £{{price}}. Please note this
                price is an indicative price based on the
                information you have provided. The final price will be confirmed
                by the salon at the time of the
                appointment. </p>
            </div>
            <ng-container *ngIf="clipboardSlots$ | async as clipboardSlots;">
              <div *ngFor="let clipboardSlot of clipboardSlots"
                   fxLayout="column" fxLayoutAlign="start center"
                   style="width:100%">
                <div *ngIf="clipboardSlot.clipboardItems.length>0"
                     class="list-group-item" fxLayout="row"
                     fxLayoutAlign="center center"
                     style="margin: 10px 0 0 10px; width:100%">
                  <div *ngIf="clipboardSlot.photo"
                       [ngStyle]="{'background' : clipboardSlot.photo | backImg}"
                       class="small-circular background-image"></div>
                  <h2 style="margin:0 20px"
                      *ngIf="clipboardSlot.clipboardName">{{clipboardSlot.clipboardName}}</h2>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                  <div *ngFor="let item of clipboardSlot.clipboardItems">
                    <button mat-stroked-button color="accent"
                            style="margin:10px" (click)="setSelectedTime(item)"
                            fxLayout="column"
                            fxLayoutAlign="center center">{{item.time}}</button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="clipboardSlots.length === 0">
                <p class="content-text padding-10">{{isGroomRoom ? 'Whoops! '
                  : ''}}You’ve selected a date with no available appointments.
                  Please choose a new date to continue with your booking.</p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
