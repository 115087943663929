import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {SignupService} from "../signup.service";
import {PasswordMatchErrorMatcher} from "../../../components/password-matching-validator";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-email-link-signup',
  templateUrl: './email-link-signup.component.html',
  styleUrls: ['./email-link-signup.component.scss']
})
export class EmailLinkSignupComponent implements OnInit {
  account_validation_messages = this.signupService.accountValidationMessages;
  matcher = new PasswordMatchErrorMatcher();
  loading$ = new BehaviorSubject<boolean>(false);
  passwordForm = new FormGroup({
      password: new FormControl(null, [Validators.minLength(8), Validators.required]),
      confirmPassword: new FormControl(null, [Validators.minLength(8), Validators.required]),
    },
    {validators: this.checkPasswords});
  private token: string;

  constructor(private signupService: SignupService, private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
      this.signupService.setEmail(params.get('email'));
    });
    this.signupService.loading$.subscribe((loading => {
      this.loading$.next(loading);
    }))
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {mismatch: true}
  }

  ngOnInit(): void {
  }

  resetPassword() {
    this.signupService.registerByEmailLink(this.token, this.passwordForm.get('password').value);
  }
}
