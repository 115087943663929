<div class="padded-container content-text" fxFill fxLayout="column"
     fxLayoutAlign="start center">
  <h1 class="big-header">Email Verification</h1>
  <p class="content-text">You are almost ready to carry on with the
    registration process, simply enter the code we have sent to your email
    to continue</p>
  <form [formGroup]="verificationForm">
    <mat-form-field appearance="outline" class="full-width-form-item">
      <mat-label>Email verification code</mat-label>
      <input formControlName="emailCode" matInput placeholder="Email code"
             required>
      <mat-error
        *ngFor="let validation of accountValidationMessages.verificationCode">
        <mat-error
          *ngIf="verificationForm.get('emailCode').hasError(validation.type) && (verificationForm.get('emailCode').dirty || verificationForm.get('emailCode').touched)"
          class="error-message">{{validation.message}}</mat-error>
      </mat-error>
    </mat-form-field>
    <div><p class="content-text accent-text-color">{{error$ | async}}</p></div>
    <div fxLayout="row" fxLayoutAlign="center center"
         style="margin-bottom: 20px">
      <ng-container *ngIf="loading$ | async; else buttonBlock">
        <mat-progress-spinner color="primary" mode="indeterminate"
                              diameter="30"></mat-progress-spinner>
      </ng-container>
      <ng-template #buttonBlock>
        <button (click)="verifyEmail()" color="primary" mat-raised-button
                [disabled]="!verificationForm.valid">Verify
          Email
        </button>
      </ng-template>
    </div>
    <p class="content-text">Not received a verification code via email?
      Please check your spam/junk folder, otherwise use the button below to
      resend a new code</p>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button (click)="requestNewEmailCode()"
              *ngIf="!resentEmail; else emailResentBlock" color="primary"
              mat-stroked-button>
        Request a new Email code
      </button>
    </div>
    <ng-template #emailResentBlock><p class="content-text">Repeat Email
      verification request sent</p></ng-template>
  </form>
</div>
