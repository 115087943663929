<div class="padded-container content-text" fxFill fxLayout="column"
     fxLayoutAlign="center center">
  <mat-card class="centered-card">
    <mat-card-content>
      <h1 class="big-header">Reset password</h1>
      <ng-container *ngIf="loading$|async; else passwordFormBlock">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <mat-progress-spinner color="primary" diameter="40"
                                mode="indeterminate"></mat-progress-spinner>
          <p class="content-text">Resetting password, please wait</p>
        </div>
      </ng-container>
      <ng-template #passwordFormBlock>
        <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
          <p class="content-text">Enter a new password below</p>
          <ng-container *ngIf="isVets4Pets && !email">
            <mat-form-field appearance="outline" class="full-width-form-item">
              <input formControlName="email" id="email" matInput
                     placeholder="Email" type="email" required>
              <mat-error
                *ngFor="let validation of accountValidationMessages.email">
                <mat-error *ngIf="resetPasswordForm.get('email') &&
                  resetPasswordForm.get('email').hasError(validation.type) && (resetPasswordForm.get('email').dirty || resetPasswordForm.get('email').touched)"
                           class="error-message">{{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
          </ng-container>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput placeholder="Password"
                   required type="password">
            <mat-error
              *ngFor="let validation of accountValidationMessages.password">
              <mat-error
                *ngIf="resetPasswordForm.get('password').hasError(validation.type) && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)"
                class="error-message">{{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width-form-item">
            <mat-label>Confirm Password</mat-label>
            <input [errorStateMatcher]="matcher" required
                   formControlName="confirmPassword" matInput
                   placeholder="Confirm Password" type="password">
            <mat-error
              *ngIf="resetPasswordForm.hasError('mismatch') && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)"
              class="error-message">Passwords must match
            </mat-error>
          </mat-form-field>
          <ng-container *ngIf="isVets4Pets">
            <mat-form-field appearance="outline" class="full-width-form-item">
              <mat-label>Verification Code</mat-label>
              <input formControlName="verificationCode" matInput required
                     placeholder="Reset Password code">
              <mat-error
                *ngFor="let validation of accountValidationMessages.verificationCode">
                <mat-error
                  *ngIf="resetPasswordForm.get('verificationCode').hasError(validation.type) && (resetPasswordForm.get('verificationCode').dirty || resetPasswordForm.get('verificationCode').touched)"
                  class="error-message">{{validation.message}}</mat-error>
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="error$ | async as error">
            <p class="content-text accent-text-color">{{error}}</p>
          </ng-container>
          <ng-container *ngIf="isVets4Pets">
            <div fxLayout="row" fxLayoutAlign="end center" style="margin-bottom:
          10px">
              <ng-container *ngIf="resendLoading$ | async; else resendBlock">
                <div style="width:150px" fxLayout="row"
                     fxLayoutAlign="center center">
                  <mat-progress-spinner mode="indeterminate" color="accent"
                                        diameter="30"></mat-progress-spinner>
                </div>
              </ng-container>
              <ng-template #resendBlock>
                <button color="accent" mat-raised-button
                        (click)="resendVerification()" type="button"
                        style="min-width:170px">Resend PIN
                </button>
              </ng-template>
            </div>
          </ng-container>
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <button [routerLink]="'/login'" mat-stroked-button type="button">
              Cancel
            </button>
            <button [disabled]="!resetPasswordForm.valid" color="primary"
                    style="min-width:170px" mat-raised-button>Reset Password
            </button>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>

