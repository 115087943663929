export class VetBookerError extends Error {
  code: number;
  message: string;
  error: string;
  isJson: boolean;

  constructor(message: string, code: number, error?: Error, isJson?: boolean) {
    super();
    this.name = 'VetBookerError';
    this.code = code;
    this.message = message;
    this.isJson = isJson;
    if (error) {
      this.error = error.message;
    }
  }

  toString(): string {
    return '{\'code\':' + this.code + ',\'msg\':\'' + this.message + '\', passedError : ' + this.error + '}';
  }
}
