import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientService} from '../../../client-details/client.service';
import {Subscription} from 'rxjs';
import {Angulartics2} from 'angulartics2';

@Component({
  selector: 'app-client-summary-card',
  templateUrl: './client-summary-card.component.html',
  styleUrls: ['./client-summary-card.component.scss']
})
export class ClientSummaryCardComponent implements OnInit, OnDestroy {
  private clientSub: Subscription;
  private contactsSub: Subscription;
  name: string;
  email: string;
  mobile: string;
  address1: string;
  address3: string;
  address2: string;
  postcode: string;
  state: string;
  marketingAllowed: boolean;
  private clinicName: string;

  constructor(private clientService: ClientService, private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.marketingAllowed = this.clientService.marketingAllowed;
    this.clinicName = this.clientService.clinicName;
    this.clientSub = this.clientService.client$.subscribe(client => {
      if (client) {
        this.name = client.firstName + ' ' + client.surname;
        this.address1 = client.address1;
        this.address2 = client.address2;
        this.address3 = client.address3;
        this.state = client.state;
        this.postcode = client.postcode;
      }
    });
    this.contactsSub = this.clientService.contacts$.subscribe(contacts => {
      if (contacts) {
        let contactsMobile;
        contacts.forEach(contact => {
          if (contact.displayName.toLowerCase().indexOf('mobile') !== -1 || contact.displayName.toLowerCase()
            .indexOf('cell') !== -1) {
            this.mobile = contact.number;
          }
          // Also retrieve first mobile number from contacts in case doesn't have the correct default Rx name of
          // mobile or cell
          if (!contactsMobile && contact.typeCode === 3) {
            contactsMobile = contact.number;
          }
          if (contact.displayName.toLowerCase().indexOf('mail') !== -1) {
            this.email = contact.number;
          }
        });
        if (!this.mobile) {
          this.mobile = contactsMobile;
        }
        // In case email has been deleted from record, present username
        if (!this.email) {
          this.email = this.clientService.contactEmail;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.clientSub?.unsubscribe();
    this.contactsSub?.unsubscribe();
  }

  amendClicked() {
    this.angulartics2.eventTrack.next({
      action: 'Amend Client Details',
      properties: {
        category: 'Landing',
        label: this.clinicName
      }
    });
  }
}
