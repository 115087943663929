<h1 class="big-header">FAQs</h1><h2>Why aren’t my pets showing in the online
  account?</h2><p class="content-text">Your online account pulls active records
  from our
  practice servers. If one or
  more of your pets is
  missing this might be because they have been de-activated within our
  practices. Please talk directly
  to our colleagues at your practice as this may have been done
  accidentally.</p><h2>My personal details are wrong, how do I change them?</h2>
<p class="content-text">If you go to the “My Account” section of your online
  account, you will be
  able to update your address, contact email and mobile number. For all other
  updates please speak to your practice directly.</p><h2>Can I book an
  appointment for more than one pet at a time?</h2><p class="content-text">
  Unfortunately we only
  offer up the ability to book individual appointments within the app. If you
  wish to book an appointment for multiple pets, please speak to your practice
  and they will find the most appropriate appointment slot for you. </p><h2>How
  can I change the time of an appointment I have booked?</h2><p
  class="content-text">At the moment
  you can only add or delete appointments within the mobile app. However, our
  practices will be more than happy to amend appointments for you, both over the
  phone and in person. As soon as the appointment has been updated, if you
  reopen the app, the new time will be reflected within your online
  account.</p><h2>Can I register my account with a second practice?</h2><p
  class="content-text">
  Unfortunately at the moment you can only register with one practice through
  the app. This is something we are looking at and may be part of our future
  roadmap.</p><h2>I have a problem with the online account, who can I speak to
  about this?</h2><p class="content-text">If you have a technical issue, please
  email us on the following address <a href="mailto:mobileapp@vets4pets.com">mobileapp@vets4pets.com</a>
  and we will endeavour to look into your issue as soon as we can.</p><h2>I cant
  find a suitable appointment</h2><p class="content-text">Not all available
  appointment slots are
  pulled through to your online account, as some of our practices keep
  appointment slots blocked out for various reasons. If you can’t find an
  appropriate time within the app, please call our colleagues in your practice
  and they may be able to open an appointment slot for you on your requested
  day.</p><h2>What if my booked appointment is not showing?</h2><p
  class="content-text">If you
  believe you have booked an appointment and it isn’t showing on your online
  account, please contact your practice directly to discuss the
  appointment. </p><h2>How do I add another pet?</h2><p class="content-text">1.
  Open the menu<br>
  2. Select the “My Pets” option<br>
  3. Click on the add a pet button at the bottom of the screen<br>
  4. Enter your pet’s Name, Date of Birth, Gender, Species, Breed, and
  whether they are neutered. Feel free to add a photo or bio for your pet.
  5. Click “Done” to complete.<br></p><h2>How do I remove a pet from my online
  account?</h2><p class="content-text">You can send a request to the vet
  practice to remove a pet
  account from the app.<br><br>
  1. Open the menu<br>
  2. Select the “My Pets” option<br>
  3. Select the pet you wish to request to remove<br>
  4. Scroll to the bottom of the page and select “Remove this pet’s
  account?”<br>
  5. Select a reason for the request<br>
  6. Click “Submit” when complete<br><br>
  All requests will go through our practices to complete. As soon as they
  receive your request, they will run through some final checks on your pet’s
  record and deactivate the patient record. </p><h2>Why did I not receive a
  reminder for my appointment?</h2><p class="content-text">If you have not
  received a reminder for
  your appointment, please contact your vet practice who can advise. </p><h2>Why
  did I not receive a reminder for my pet’s vaccination or treatment? </h2><p
  class="content-text">
  If you have not received a reminder for your vaccination, please contact your
  vet practice who can advise.</p>
