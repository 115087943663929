import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VetBookerError} from '../../../interfaces/vberror';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})

export class ErrorDialogComponent {
  message: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.message = this.getErrorMessage(data.error);
    this.title = data.title;
  }

  getErrorMessage(error: VetBookerError): string {
    if (error.message) {
      return error.message;
    }
    return 'An unknown error has occurred. Please try again or contact support';
  }
}

