<div fxLayout="column">
  <ng-template #loadingBlock>
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-progress-spinner color="primary" diameter="50"
                            mode="indeterminate"></mat-progress-spinner>
      <p>Retrieving next available appointment</p></div>
  </ng-template>
  <div *ngIf="!(loading$|async); else loadingBlock" fxLayout="column"
       fxLayoutGap="20px" fxLayoutGap.xs="10px">
    <mat-card *ngFor="let slot of slots$ | async;" class="quick-book-card"
              fxFlex>
      <mat-card-content>
        <app-quick-book-card [apptType]="apptType$ | async"
                             [patients]="patients$ | async"
                             [resource]="resource$|async"
                             [slot]="slot"></app-quick-book-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>
