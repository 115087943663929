import {Pipe, PipeTransform, Sanitizer, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl'
})
export class SafeUrlResourcePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeStyle {
    if (value) {
      const tempImage = 'data:image/png;base64,' + value;
      return this.sanitizer.sanitize(SecurityContext.STYLE, 'url(' + tempImage + ')');
    }
  }
}
