<div fxLayout="column" fxLayoutGap="10px">
  <ng-container *ngIf="toPets && toPets.length>0">
    <p class="content-text">Pets registered at {{transferToClinicName}}</p>
    <ng-container *ngFor="let patient of toPets">
      <mat-checkbox
        [(ngModel)]="patient.isSelected">{{patient.name}}</mat-checkbox>
    </ng-container>
  </ng-container>
  <p class="content-text">Pets registered at {{transferFromClinicName}}</p>
  <ng-container *ngFor="let patient of fromPets">
    <mat-checkbox
      [(ngModel)]="patient.isSelected">{{patient.name}}</mat-checkbox>
  </ng-container>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-stroked-button (click)="back()">Back</button>
    <button mat-raised-button color="primary" (click)="next()">Next</button>
  </div>
</div>
