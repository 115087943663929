import {Component, Input, OnInit} from '@angular/core';
import {differenceInMinutes} from 'date-fns';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {CancelBookingDialogComponent} from '../../booker/cancel-booking-dialog/cancel-booking-dialog.component';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';
import {SessionService} from '../../services/session-service.service';
import {Angulartics2} from 'angulartics2';
import {ActivatedRoute, Router} from '@angular/router';
import {BookingService} from '../../services/booking.service';
import {CancellationReason} from '@appyvet/vetbooker-definitions/dist/cancellation_reason';
import {AppointmentHistoryItem} from '@appyvet/vetbooker-definitions/dist/appointments';
import {ClientPatientDetails} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {MultiAppointmentItem} from '@appyvet/vetbooker-definitions/dist/bookings';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';
import {VetBookerError} from '../../interfaces/vberror';

interface ActionItem {
  name: string;
  icon: string;
}

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss']
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointmentHistoryItem: AppointmentHistoryItem;
  isGroomRoom = environment.GROOM;
  loading$ = new BehaviorSubject(false);
  actionItems = new Array<ActionItem>();
  private minMinutesToCancel: number;
  private vetBookerCancelText: string;
  private clinicName: string;
  private phoneNumber: string;
  private cancellationReasons: CancellationReason[];

  constructor(private sessionService: SessionService, private bookingService: BookingService,
              private angulartics2: Angulartics2, public dialog: MatDialog, private router: Router,
              private route: ActivatedRoute, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.isGroomRoom = clientPatientDetails.isGroomRoom;
        this.minMinutesToCancel = clientPatientDetails.appointmentSettings.minMinutesToCancel || -1;
        this.vetBookerCancelText = clientPatientDetails.appointmentSettings.minMinutesErrorMessage;
        this.phoneNumber = clientPatientDetails.phoneNumber;
        this.clinicName = clientPatientDetails.themeSettings.customName || 'VetBooker';
        this.cancellationReasons = clientPatientDetails.cancellationReasons;
        if (!clientPatientDetails.appointmentSettings.hideAmend) {
          this.actionItems.push({name: 'Amend', icon: 'edit'});
        }
        this.actionItems.push({name: 'Cancel', icon: 'delete'});
        if (!environment.VETS_4_PETS) {
          this.actionItems.push({name: 'Directions', icon: 'directions'});
        }
      }
    });
  }

  showCancelDialog(appointment: AppointmentHistoryItem) {
    this.angulartics2.eventTrack.next({
      action: 'Cancel Appointment Started', properties: {
        category: 'Appointment History',
        label: this.clinicName
      }
    });
    const cancelDialogConfig = new MatDialogConfig();
    cancelDialogConfig.data = {
      title: environment.GROOM ? 'Need to Cancel?' : 'Cancel Appointment?',
      appointment,
      isEdit: false,
      cancellationReasons: this.cancellationReasons,
      showCancellationReason: environment.GROOM || environment.VETS_4_PETS
    };
    cancelDialogConfig.panelClass = 'no-padding-dialog';
    this.dialog.open(CancelBookingDialogComponent, cancelDialogConfig);
  }

  showCancelAlert(appointment: AppointmentHistoryItem, cancelText?: string) {
    this.angulartics2.eventTrack.next({
      action: 'Cancel Appointment Too Soon', properties: {
        category: 'Appointment History',
        label: this.clinicName
      }
    });
    const alertDialogConfig = new MatDialogConfig();
    alertDialogConfig.data = {
      title: 'Cancel Appointment?',
      confirmationText: 'Ok',
      message: cancelText
    };
    this.dialog.open(AlertDialogComponent, alertDialogConfig);
  }

  getParamsForAmend(appointmentItem: MultiAppointmentItem) {
    const clientPatientDetails: ClientPatientDetails = this.route.snapshot.data.clientPatientDetails;
    const passedPatientParams = [];
    appointmentItem.appointments.forEach(appt => {
      passedPatientParams.push({
        patientId: appt.patient.patientNumber,
        appointmentType: appt.apptTypeId ? appt.apptTypeId : clientPatientDetails.appointmentTypes.find(
          apptType => apptType.displayName === appt.apptType),
        notes: appt.notes
      });
    });
    return {
      patients: JSON.stringify(passedPatientParams),
      date: appointmentItem.clinicDateTime,
      resourceId: appointmentItem.resourceId,
      editId: appointmentItem.apptId
    };
  }

  getWarningText(appointment: AppointmentHistoryItem, isAmend: boolean): string {
    const plural = appointment.appointmentItem.multiAppt ? 's' : '';
    return 'We can\'t wait to see ' + this.bookingService.getPatientsAppointmentTextFromRequest(
      appointment.appointmentItem).patients + ' for their groom' + plural + ' but if you ' +
      (isAmend ? 'need to change your appointment time'
               : 'can no longer make it') + ', please call ' +
      appointment.resourceName + ' on ' + this.phoneNumber + ' to cancel your' +
      ' appointment. Cancellations made outside of the cancellation period may result in losing any deposit paid.';
  }

  checkinAppointment(appointment: AppointmentHistoryItem) {
    const checkingIn$ = this.bookingService.checkin(appointment.appointmentItem);
    this.loading$.next(true);
    const checkinSub = checkingIn$.subscribe(checkedIn => {
      if (checkedIn) {
        this.loading$.next(false);
        checkinSub.unsubscribe();
      }
    }, (error: VetBookerError) => {
      this.snackBar.open('Error checking in: ' + error.message, null, {duration: 5000});
      this.loading$.next(false);
      checkinSub.unsubscribe();
    });
  }

  appointmentAction(appointment: AppointmentHistoryItem, actionItem: ActionItem) {
    const isCancellable: boolean = this.minMinutesToCancel !== -1 ? differenceInMinutes(appointment.date,
      new Date()) >= this.minMinutesToCancel : true;
    const isAmendable: boolean = environment.GROOM ? differenceInMinutes(appointment.date,
      new Date()) >= 180 : true;
    if (!isAmendable && actionItem.name === 'Amend') {
      if (this.isGroomRoom) {
        this.showCancelAlert(appointment, this.getWarningText(appointment, true));
      } else {
        this.showCancelAlert(appointment);
      }
    } else if (!isCancellable && actionItem.name === 'Cancel') {
      this.angulartics2.eventTrack.next({
        action: 'Cancel Appointment Too Soon', properties: {
          category: 'Appointment History',
          label: this.clinicName
        }
      });
      if (this.isGroomRoom) {
        this.showCancelAlert(appointment, this.getWarningText(appointment, false));
      } else {
        this.showCancelAlert(appointment, this.vetBookerCancelText);
      }
    } else {
      if (actionItem.name === 'Email') {
        this.angulartics2.eventTrack.next({
          action: 'Resend Email', properties: {
            category: 'Appointment History',
            label: this.clinicName
          }
        });
        this.bookingService.resendAppointmentEmail(appointment.appointmentItem);
      } else if (actionItem.name === 'Cancel') {
        this.showCancelDialog(appointment);
      } else if (actionItem.name === 'Amend') {
        this.angulartics2.eventTrack.next({
          action: 'Amend Appointment Started', properties: {
            category: 'Appointment History',
            label: this.clinicName
          }
        });
        this.bookingService.setEditingAppointment(appointment);
        this.router.navigate(['booking', this.getParamsForAmend(appointment.appointmentItem)]);
      } else if (actionItem.name === 'Directions') {
        this.angulartics2.eventTrack.next({
          action: 'Get Directions', properties: {
            category: 'Appointment History',
            label: this.clinicName
          }
        });
        window.open(appointment.mapLink, '_blank');
      }
    }
  }
}
