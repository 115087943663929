import {Component, OnInit} from '@angular/core';
import {SessionService} from "../../services/session-service.service";
import {isAfter, isBefore, parseISO} from "date-fns";

@Component({
  selector: 'app-history-summary-card',
  templateUrl: './history-summary-card.component.html',
  styleUrls: ['./history-summary-card.component.scss', '../landing.component.scss']
})
export class HistorySummaryCardComponent implements OnInit {

  upcomingAppointments: number;
  pastAppointments: number;

  constructor(private sessionService: SessionService) {
  }

  ngOnInit() {
    this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      if (clientPatientDetails) {
        this.upcomingAppointments = clientPatientDetails.appointmentHistory.filter((apptItem) => isAfter(parseISO(apptItem.utcDate), new Date())).length;
        this.pastAppointments = clientPatientDetails.appointmentHistory.filter((apptItem) => isBefore(parseISO(apptItem.utcDate), new Date())).length;
      }
    })
  }

}
