import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PatientService} from '../patient.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Angulartics2} from 'angulartics2';
import {BreedItem, SpeciesItem, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';


export interface PetFormItem {
  patient: VbExtendedPatient;
  form: FormGroup;
  id: string;
  onlyUpdateForm?: boolean;
}

@Component({
  selector: 'app-add-patient-page',
  templateUrl: './add-patient-page.component.html',
  styleUrls: ['./add-patient-page.component.scss']
})
export class AddPatientPageComponent implements OnInit, OnDestroy {
  editPatient: VbExtendedPatient;
  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);
  alphanumericRegexp: string;
  colors: string[];
  breeds: BreedItem[];
  species: SpeciesItem[];
  private speciesSubscription: Subscription;
  private colorsSubscription: Subscription;
  private breedsSubscription: Subscription;
  showLastVac: boolean;
  showInsured: boolean;
  showColors: boolean;
  showMicrochip: boolean;
  private clinicName: string;

  constructor(private patientService: PatientService, private route: ActivatedRoute, private router: Router,
              private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.editPatient = this.patientService.getPatient(params.get('patientNumber'));
    });
    this.clinicName = this.patientService.clinicName;
    this.alphanumericRegexp = this.patientService.alphanumericRegexp;
    this.breedsSubscription = this.patientService.breeds$.subscribe(breeds => {
      this.breeds = breeds;
    });
    this.colorsSubscription = this.patientService.colors$.subscribe(colors => {
      this.colors = colors;
    });
    this.speciesSubscription = this.patientService.species$.subscribe(species => {
      this.species = species;
    });

    this.showInsured = !this.patientService.isGroomRoom && !this.editPatient && this.patientService.showLastInsured;
    this.showLastVac = !this.patientService.isGroomRoom && !this.editPatient && this.patientService.showLastVac;
    if (!environment.VETS_4_PETS && !environment.GROOM) {
      this.showColors = this.patientService.showColors;
    }
    this.showMicrochip = this.patientService.showMicrochip;
  }

  async savePatient(petForm: PetFormItem) {
    this.loading$.next(true);
    this.error$.next(null);
    try {
      if (this.editPatient) {
        this.angulartics2.eventTrack.next({
          action: 'Edit Pet',
          properties: {
            category: 'Pet Details',
            label: this.clinicName
          }
        });
        await this.patientService.editPatient({
          previousData: this.editPatient,
          updatedData: petForm.patient,
          patientNumber: this.editPatient.patientNumber
        });
      } else {
        this.angulartics2.eventTrack.next({
          action: 'Add Pet',
          properties: {
            category: 'Pet Details',
            label: this.clinicName
          }
        });
        await this.patientService.addPatient({patientData: petForm.patient});
      }
      this.loading$.next(false);
      this.router.navigateByUrl('/pets');
    } catch (e) {
      this.error$.next(e);
      this.loading$.next(false);
    }
  }

  ngOnDestroy(): void {
    this.speciesSubscription?.unsubscribe();
    this.breedsSubscription?.unsubscribe();
    this.colorsSubscription?.unsubscribe();
  }
}
