import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoginService} from '../services/login-service.service';
import {AuthService} from './auth.service';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private authService: AuthService, private loginService: LoginService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    if (route.queryParams.sessionToken) {
      this.authService.setCookiesAndGoToLanding(route.queryParams.sessionToken, null);
    }
    const logged = await this.authService.isLoggedIn();
    let url = '/landing';
    // Old VB links will include a route fragment (after a '#'). Cannot read those at express level so to support during
    // transition, capture those parts of route fragment and redirect accordingly.
    const routeFragment = route.fragment;
    const params: any = {};
    if (routeFragment) {
      // Take the route fragment, and redirect accordingly.
      const splitFragment = routeFragment.split('?');
      url = splitFragment[0];
      // Params section might be something like appointmentId=123123&test=true
      const paramsSection = splitFragment[1];
      if (paramsSection) {
        const paramsList = paramsSection.split('&');
        paramsList.forEach(param => {
          const splitParameter = param.split('=');
          const paramName = splitParameter[0];
          params[paramName] = splitParameter[1];
        });
      }
    }
    if (logged) {
      this.router.navigate([url], {queryParams: params});
      return true;
    } else {
      if (state.url.indexOf('userlogin') !== -1) {
        if (params.resetPassword) {
          this.router.navigate(['/resetPassword'], {queryParams: params});
        } else if (params.verificationCode || params.clientNumber) {
          this.router.navigate(['/emailLink'], {queryParams: params});
        } else {
          this.router.navigate(['/login'], {queryParams: params});
        }
      } else if (state.url.indexOf('emailregistration') !== -1) {
        this.router.navigate(['/emailRegistrationLink'], {queryParams: params});
      } else if (state.url.toLowerCase().indexOf('bookinghistory') !== -1) {
        this.router.navigate(['/history'], {queryParams: params});
      }
    }
    return true;
  }
}
