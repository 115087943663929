import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../patient.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {BookingService} from '../../services/booking.service';
import {SessionService} from '../../services/session-service.service';
import {format, parseISO} from 'date-fns';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {BehaviorSubject, Subject, Subscription, throwError} from 'rxjs';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ImageEditingDialogComponent} from '../../components/shared/image-editing-dialog/image-editing-dialog.component';
import {HttpClient} from '@angular/common/http';
import {RemovePetDialogComponent} from '../remove-pet-dialog/remove-pet-dialog.component';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError} from 'rxjs/operators';
import {Angulartics2} from 'angulartics2';
import {AppointmentType} from '@appyvet/vetbooker-definitions/dist/appointment_type';
import {PetDeactivationReason} from '@appyvet/vetbooker-definitions/dist/client_patient_details';
import {PatientExtraDetailsResponse, VbExtendedPatient} from '@appyvet/vetbooker-definitions/dist/patient';
import {Resource} from '@appyvet/vetbooker-definitions/dist/resource';
import {FirstAvailableSlot, PurePatient} from '@appyvet/vetbooker-definitions/dist/appointments';
import {utcToZonedTime} from 'date-fns-tz';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent implements OnInit, OnDestroy {
  patient: VbExtendedPatient;
  patientUpdate: boolean;
  canViewHistory: boolean;
  vaccineHistoryEnabled: boolean;
  resource: Resource;
  apptType: AppointmentType;
  slot: FirstAvailableSlot;
  imageLoading$ = new BehaviorSubject<boolean>(false);
  customPrescriptions: boolean;
  allowPatientImages: boolean;
  hideReminders: boolean;
  hideInsurance: boolean;
  hideCarePlans: boolean;
  canDeactivatePatient: boolean;
  isVets4Pets = environment.VETS_4_PETS;
  bioLoading$ = new Subject<boolean>();
  deactivateLoading$ = new Subject<boolean>();
  showColor = !environment.VETS_4_PETS && !environment.GROOM;
  purePatientItem: PurePatient;
  disableQuickBook: boolean;
  isGroomRoom = environment.GROOM;
  options: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Image_Png, MineTypeEnum.Image_Jpeg]
  };
  private uploadSub: Subscription;
  private clientDetailsSub: Subscription;
  private paramsSub: Subscription;
  private patientsSub: Subscription;
  private clinicName: string;
  private timezone: string;
  canBook: boolean;

  constructor(public patientService: PatientService, private route: ActivatedRoute,
              private bookingService: BookingService, private sessionService: SessionService,
              private uploader: HttpClientUploadService, private matDialog: MatDialog, private http: HttpClient,
              private snackBar: MatSnackBar, private angulartics2: Angulartics2) {
  }

  ngOnInit(): void {
    this.paramsSub = this.route.paramMap.subscribe((params: ParamMap) => {
      // Ensure only one subscription to the upload service even if navigating between patients
      this.uploadSub?.unsubscribe();
      this.patient = this.patientService.getPatient(params.get('patientNumber'));
      this.uploadSub = this.uploader.onAddToQueue$.subscribe((file: FileItem) => {
        const dialogRef = this.matDialog.open(ImageEditingDialogComponent,
          {data: {file: file.file, roundCrop: !this.isVets4Pets}});
        dialogRef.afterClosed().subscribe((base64Image: string) => {
          this.uploadImage(base64Image);
        });
      });
    });
    this.clientDetailsSub = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.clinicName = clientPatientDetails.themeSettings.customName;
      this.timezone = clientPatientDetails.timezone;
      this.disableQuickBook = clientPatientDetails.appointmentSettings.quickBookDisabled;
      this.hideReminders = clientPatientDetails.patientViewSettings.hideReminders;
      this.hideInsurance = clientPatientDetails.patientViewSettings.hideInsurance;
      this.hideCarePlans = clientPatientDetails.patientViewSettings.hideHealthPlans;
      this.allowPatientImages = clientPatientDetails.editSettings.allowPatientImages;
      this.customPrescriptions = clientPatientDetails.prescriptions.enableCustomPrescriptions;
      this.canDeactivatePatient = clientPatientDetails.canDeactivatePatient;
      this.canBook = clientPatientDetails.acceptingBookings;
      if (!this.disableQuickBook && clientPatientDetails.acceptingBookings) {
        this.bookingService.getFirstAvailableAppointment$({isVaccine: false, numberOfResults: 1})
          .subscribe(firstAppt => {
            if (firstAppt) {
              this.slot = firstAppt.slots[0];
              this.purePatientItem = this.bookingService.convertExtendPatientToPurePatient(this.patient);
              this.slot.selectedPatient = this.purePatientItem;
              this.resource = clientPatientDetails.resources.find(resource => resource.objectId === firstAppt.resource);
              this.apptType = clientPatientDetails.appointmentTypes.find(
                apptType => apptType.objectId === firstAppt.apptType);
            }
          });
      }
    });
    this.canViewHistory = this.patientService.canViewHistory;
    this.vaccineHistoryEnabled = this.patientService.vaccineHistoryEnabled;
    this.patientsSub = this.patientService.patients$.subscribe(patients => {
      if (patients) {
        this.patient = this.patientService.getPatient(this.patient.patientNumber);
      }
    });
  }

  ngOnDestroy() {
    this.patientsSub?.unsubscribe();
    this.clientDetailsSub?.unsubscribe();
    this.uploadSub?.unsubscribe();
    this.paramsSub?.unsubscribe();
  }

  getDateOfBirth() {
    if (this.patient.dateOfBirth) {
      return format(parseISO(this.patient.dateOfBirth), 'dd/MM/yyyy');
    } else {
      return 'Unknown';
    }
  }

  getLastVisitDate() {
    if (this.patient.extraInfo && this.patient.extraInfo.lastVisitDate) {
      return format(utcToZonedTime(this.patient.extraInfo.lastVisitDate, this.timezone), 'dd/MM/yyyy HH:mm');
    } else {
      return 'No previous visits found';
    }
  }

  uploadImage(image: string) {
    this.angulartics2.eventTrack.next({
      action: 'Add Photo',
      properties: {
        category: 'Pet Details',
        label: this.clinicName
      }
    });
    this.imageLoading$.next(true);
    if (image) {
      if (environment.VETS_4_PETS) {
        this.patientService.uploadV4PPhoto(image, this.patient.patientNumber).then(result => {
          this.imageLoading$.next(false);
          let extraInfo = this.patient.extraInfo;
          if (!extraInfo) {
            extraInfo = {} as PatientExtraDetailsResponse;
          }
          extraInfo.photoBase64 = result;
          this.patient.extraInfo = extraInfo;
        }, () => {
          this.imageLoading$.next(false);
        });
      } else {
        this.patientService.uploadPhoto$(image, this.patient.patientNumber).subscribe(result => {
          this.patient.photo = result;
          this.imageLoading$.next(false);
        }, error => {
          console.log(error);
          this.imageLoading$.next(false);
        });
      }
    } else {
      this.imageLoading$.next(false);
    }
  }

  removePet() {
    this.angulartics2.eventTrack.next({
      action: 'Remove Pet',
      properties: {
        category: 'Pet Details',
        label: this.clinicName
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      patient: this.patient,
      reasons: this.patientService.petDeactivationReasons
    };
    dialogConfig.panelClass = 'no-padding-dialog';
    const dialogRef = this.matDialog.open(RemovePetDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(((reason: PetDeactivationReason) => {
      if (reason) {
        this.deactivateLoading$.next(true);
        this.patientService.deactivatePet(this.patient, reason);
        this.patientService.deactivatedPatientsEvent$.pipe(catchError((e) => {
          this.deactivateLoading$.next(false);
          this.snackBar.open('Error removing your pet, please contact the clinic', null, {duration: 15000});
          return throwError(e);
        })).subscribe(() => {
          this.deactivateLoading$.next(false);
          this.snackBar.open('Successfully requested pet deactivation', null, {duration: 5000});
        });
      }
    }));
  }


  editPhoto() {
    this.angulartics2.eventTrack.next({
      action: 'Edit Photo',
      properties: {
        category: 'Pet Details',
        label: this.clinicName
      }
    });
    let dialogRef: MatDialogRef<ImageEditingDialogComponent>;
    if (this.patient.extraInfo.photoBase64) {
      dialogRef = this.matDialog.open(ImageEditingDialogComponent,
        {data: {base64: 'data:image/png;base64,' + this.patient.extraInfo.photoBase64, roundCrop: !this.isVets4Pets}});
      dialogRef.afterClosed().subscribe((base64Image: string) => {
        this.uploadImage(base64Image);
      });
    } else {
      this.http.get(this.patient.photo.url(), {responseType: 'blob'}).subscribe((result) => {
        dialogRef = this.matDialog.open(ImageEditingDialogComponent,
          {data: {file: result, roundCrop: !this.isVets4Pets}});
        dialogRef.afterClosed().subscribe((base64Image: string) => {
          this.uploadImage(base64Image);
        });
      });
    }
  }

  deletePhoto() {
    this.angulartics2.eventTrack.next({
      action: 'Remove Photo',
      properties: {
        category: 'Pet Details',
        label: this.clinicName
      }
    });
    this.patientService.deletePhoto(this.patient.patientNumber).then(() => {
      if (environment.VETS_4_PETS) {
        this.patient.extraInfo.photoBase64 = null;
      }
      this.patient.photo = null;
    }, error => {
      console.log(error);
    });
  }

  async updateBio(bio: string) {
    this.bioLoading$.next(true);
    await this.patientService.updateBio(this.patient.patientNumber, bio);
    this.patient.extraInfo.petBio = bio;
    this.bioLoading$.next(false);
  }

  editPet() {
    this.angulartics2.eventTrack.next({
      action: 'Update Pet Details',
      properties: {
        category: 'Pet Details',
        label: this.clinicName
      }
    });
  }

  bookApptLink() {
    this.angulartics2.eventTrack.next({
      action: 'Book Appointment From Patient Page',
      properties: {
        category: 'Book Appointment',
        label: this.clinicName
      }
    });
  }

  orderProductLink() {
    this.angulartics2.eventTrack.next({
      action: 'Request Prescription From Patient Page',
      properties: {
        category: 'Prescriptions',
        label: this.clinicName
      }
    });
  }
}
