import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientDetailsComponent} from './client-details/client-details.component';
import {MatCardModule} from '@angular/material/card';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {ContactEntriesComponent} from './contact-entries/contact-entries.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NameEntryComponent} from '../components/name-entry/name-entry.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CommonComponentsModule} from '../components/common-components/common-components.module';
import {AddContactComponent} from './contact-entries/add-contact/add-contact.component';
import {EditContactComponent} from './contact-entries/edit-contact/edit-contact.component';


@NgModule({
  declarations: [ClientDetailsComponent, ContactEntriesComponent, NameEntryComponent, AddContactComponent,
                 EditContactComponent],
  exports: [
    NameEntryComponent,
    ClientDetailsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    FormsModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ExtendedModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    CommonComponentsModule
  ]
})
export class ClientDetailsModule {

}
