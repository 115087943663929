import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-neutered-selector',
  template: `
    <div class="card-option-container" fxLayout="row wrap" fxLayoutAlign="start center">
      <div (click)="setNeutered(true);" [class.selected-card]="neutered && dirty" class="selection-card" fxFlex="28%"
           fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <ng-container *ngIf="neutered && dirty; else notBlock">
            <mat-icon style="margin:5px">check_circle
            </mat-icon>
          </ng-container>
          <ng-template #notBlock>
            <div class="empty-circle"></div>
          </ng-template>
        </div>
        <p [ngStyle]="{'color':neutered && dirty? '#FFFFFF' : ''}" class="card-header-text">Yes</p>
      </div>
      <div (click)="setNeutered(false);" [class.selected-card]="!neutered && dirty" class="selection-card" fxFlex="28%"
           fxFlex.xs="100%" fxLayout="row" fxLayoutAlign="start center">
        <div style="min-width: 30px; padding:0 10px">
          <ng-container *ngIf="!neutered && dirty; else notBlock">
            <mat-icon style="margin:5px">check_circle
            </mat-icon>
          </ng-container>
          <ng-template #notBlock>
            <div class="empty-circle"></div>
          </ng-template>
        </div>
        <p [ngStyle]="{'color':!neutered && dirty ? '#FFFFFF' : ''}" class="card-header-text">No</p>
      </div>
    </div>`,
  styleUrls: ['../../components/common-components/patient-selector/patient-selector.scss']
})
export class NeuteredSelectorComponent implements OnInit {

  @Input() dirty = false;
  @Input() neutered: boolean;
  @Output() neuteredChange = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setNeutered(neutered: boolean) {
    this.dirty = true;
    this.neuteredChange.emit(neutered);
  }
}
