import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session-service.service';
import {Subscription} from 'rxjs';
import {ClinicDetails} from '@appyvet/vetbooker-definitions/dist/clinic_details';

@Component({
  selector: 'app-emergency-appointment-dialog',
  templateUrl: './emergency-appointment-dialog.component.html',
  styleUrls: ['./emergency-appointment-dialog.component.scss']
})
export class EmergencyAppointmentDialogComponent implements OnInit, OnDestroy {
  clinic: ClinicDetails;
  openingHours: string;
  private sessionSub: Subscription;

  constructor(private sessionService: SessionService) {
    this.sessionSub = this.sessionService.clientPatientDetails$.subscribe(clientPatientDetails => {
      this.clinic = clientPatientDetails.clinicDetails;
    });
  }

  ngOnInit(): void {
    const dayToday = new Date().getDay();
    switch (dayToday) {
      case 0:
        this.openingHours = this.clinic.openingSun;
        break;
      case 1:
        this.openingHours = this.clinic.openingMon;
        break;
      case 2:
        this.openingHours = this.clinic.openingTue;
        break;
      case 3:
        this.openingHours = this.clinic.openingWed;
        break;
      case 4:
        this.openingHours = this.clinic.openingThu;
        break;
      case 5:
        this.openingHours = this.clinic.openingFri;
        break;
      case 6:
        this.openingHours = this.clinic.openingSat;
        break;
    }
  }

  ngOnDestroy(): void {
    this.sessionSub?.unsubscribe();
  }
}
